import {FormInstance} from "antd/lib/form/hooks/useForm";
import {useEffect} from "react";
import isNaN from 'lodash/isNaN';
import {NamePath} from "rc-field-form/es/interface";

export type KeyHandler = (_: string) => NamePath;

export const defaultKeyHandler = (rawKey: string): NamePath => rawKey.split('.').map(v => {
    const val = Number(v);
    return isNaN(val) ? v : val;
});

const useFormSyncErrors = (form: FormInstance, errors: any, enable = true, keyHandler?: KeyHandler) => {
    useEffect(() => {
        if (errors && enable) {
            form.setFields(Object.keys(errors).map(key => ({
                name: (keyHandler ?? defaultKeyHandler)(key),
                errors: errors[key],
            })));
        }
    }, [form, errors, enable, keyHandler]);
};

export default useFormSyncErrors;
