import {createAction, createSlice, Dictionary, PayloadAction} from '@reduxjs/toolkit';
import {CategoryFormData, CategoryParams, CategoryState} from './state';
import {Category} from '../../../models/category';
import {Rest} from '../../../common/models/rest';
import {PreparedCaseReducer} from '../../../common/utils';
import {isEqual, keyBy, remove} from 'lodash';
import {WorkflowCategory} from "../../../models/enums/workflow-category";

const slice = createSlice({
    name: 'category',
    initialState: {...new CategoryState()},
    reducers: {
        submitCategoryForm(state, action: PayloadAction<CategoryFormData>) {
            state.formStatus = 'loading';
        },
        categoryFormSubmitted(state, action: PayloadAction<boolean>) {
            state.formStatus = 'success';
        },

        singleCategoryAdded: new PreparedCaseReducer<CategoryState, Category, boolean>(
            (state, {payload, meta: willUpdateForm}) => {
                state.entities[payload.id] = payload;
                let idList = state.ids;
                if (!idList.includes(payload.id)) {
                    idList.unshift(payload.id);
                }
            }
        ),

        refreshListCategories(state: CategoryState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
        },

        listCategoriesLoaded(state: CategoryState, {payload}: PayloadAction<Rest<Category[]>>) {
            const entities: Dictionary<Category> = keyBy(payload.data, 'id');
            const ids = payload.data?.map(({id}) => id);
            const otherCategory = payload.data.find((c) => c.type === WorkflowCategory.OTHER_CATEGORY);

            return {
                ...state,
                meta: payload.meta,
                status: 'idle',
                entities,
                ids,
                otherCategory
            };
        },

        deleteCategory(state: CategoryState, {payload}: PayloadAction<number>) {
            if (!state.interactingIds.includes(payload)) {
                state.interactingIds.push(payload)
            }
        },

        categoryDeleted(state: CategoryState, {payload}: PayloadAction<number>) {
            delete state.entities[payload];
            state.ids = state.ids.filter(id => id !== payload);
            state.interactingIds = state.interactingIds.filter(id => id !== payload);
        },

        categoryDeleteFailed(state: CategoryState, {payload}: PayloadAction<number>) {
            state.interactingIds = state.interactingIds.filter(id => id !== payload);
        },

        categoryParamsChange: new PreparedCaseReducer<CategoryState, Partial<CategoryParams> | undefined, boolean>(
            (state, {payload, meta}) => {
                const newParams = {
                    ...state.params,
                    ...payload ?? {}
                };
                if (!isEqual(state.params, newParams)) {
                    state.params = newParams;
                }
            }
        ),
        loadingListCategories(state: CategoryState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
        },
        categoriesListLoaded(state: CategoryState, {payload}: PayloadAction<Rest<Category[]>>) {
            const entities: Dictionary<Category> = keyBy(payload.data, 'id');
            const ids = payload.data?.map(({id}) => id);
            const otherCategory = payload.data.find((c) => c.type === WorkflowCategory.OTHER_CATEGORY);

            return {
                ...state,
                status: 'idle',
                entities,
                ids,
                otherCategory
            };
        },
        softDeletingCategory(state: CategoryState, {payload}: PayloadAction<{id: number}>) {
            state.status = 'loading';
        },
        categorySoftDeleted(state: CategoryState, {payload}: PayloadAction<number>) {
            delete state.entities[payload];
            remove(state.ids, (id) => id === payload);
            state.status = 'idle';
        },
        categorySoftDeletedFail(state: CategoryState) {
            state.status = 'idle';
        },
        settingActive(state, {payload}: PayloadAction<any>) {
            state.setActiveStatus = 'loading'
        },
        setActiveDone(state, {payload}: PayloadAction<Rest<any>>) {
            state.entities[payload.data.id] = payload.data;
            state.setActiveStatus = 'idle';
        },
        setActiveFail(state) {
            state.setActiveStatus = 'idle';
        }
    }
});

export const {
    refreshListCategories,
    deleteCategory,
    categoryDeleteFailed,
    categoryDeleted,
    listCategoriesLoaded,
    singleCategoryAdded,
    submitCategoryForm,
    categoryFormSubmitted,
    categoryParamsChange,
    loadingListCategories,
    categoriesListLoaded,
    softDeletingCategory,
    categorySoftDeleted,
    categorySoftDeletedFail,
    setActiveFail,
    settingActive,
    setActiveDone
} = slice.actions;

export const categoryFormIdChanged = createAction<number>('category/formIdChanged');

const categoryReducer = slice.reducer;

export default categoryReducer;
