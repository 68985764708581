import {CRUDService} from "../../../../common/service";
import {TicketLog} from "../../../../models/ticket-log";
import axios from "../../../../common/axios";
import {Rest} from "../../../../common/models/rest";
import {BASE_URL} from "../../../../config/properties";

export class TicketLogService extends CRUDService<TicketLog> {
    getNameSpace(): string {
        return "tickets";
    }

    getLogByID(rawParams: any) {
        const id = rawParams?.ticketId;
        const params =  {...rawParams, ticketId: undefined}
        return axios.get<any, Rest<TicketLog>>(`${BASE_URL}/tickets/${id}/logs`, {params});
    }
}

const ticketLogService = new TicketLogService();

export default ticketLogService;
