import Axios from 'axios';
import {message} from 'antd';
import {APP_DEBUG, DOMAIN} from '../config/properties';
import isString from 'lodash/isString';
import auth from "../features/auth/auth";
import store from "../config/store";
import {backendJobReceived, backendValidationErrorsReceived} from "./backend-util/store";
import debounce from "lodash/debounce";

const db = debounce((mess: string) => message.warning(mess), 500);
// const dbError = debounce((mess: string) => message.error(mess), 500);
// const {loadProgressBar} = require('axios-progress-bar');

const axios = Axios.create({
    baseURL: DOMAIN
});

const JSON_HEADER = 'application/json';

// Add a request interceptor
axios.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers ?? {},
        'Content-Type': JSON_HEADER,
        Accept: JSON_HEADER,
        Authorization: 'Bearer ' + auth.getStoredToken() || '',
    };

    config.transformResponse = [(res, header) => {
        return ((!header?.accept || header.accept === JSON_HEADER) && isString(res))
            ? JSON.parse(res)
            : res;
    }];
    return config;
}, (error) => {
    if (APP_DEBUG) {
        console.log(error);
    }
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
    if (response?.data?.data?.background_job) {
        store.dispatch(backendJobReceived(response?.data.data.job_id));
    }

    return response?.data;

}, (e) => {
    const error = e.response;
    if (error?.status === 401) {
        message.error('Lỗi xác thực hoặc phiên làm việc đã hết hạn.');
        auth.goLogin()
    } else if (error?.status === 403) {
        db('Bạn không có quyền truy cập tài nguyên này, vui lòng liên hệ admin để được cấp quyền');
    } else if (error?.status === 422) {
        let errors = error?.data?.errors;
        if (errors && 'errors' in errors) {
            errors = errors.errors;
        }
        message.error(error?.data?.message);
        store.dispatch(backendValidationErrorsReceived(errors));
    } else if (error?.data) {
        message.error(error?.data?.message);
    } else {
        message.error('Đã có lỗi bất ngờ xảy ra. Vui lòng thử lại sau');
        console.error(error);
    }
    // message.success('This is a prompt message for success, and it will disappear in 10 seconds', 10)
    return Promise.reject(e);
});


// loadProgressBar({}, axios);

export default axios;
