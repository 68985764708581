import {createSelector} from "@reduxjs/toolkit";
import AppState from "../../../App.state";
import {ReportTemplate} from "../../../models/report-template";

export const selectReportTemplateState = (state: AppState) => state.reportTemplate;

export const selectListReportTemplateLoading = createSelector(
    selectReportTemplateState,
    state => state.listLoading
)

export const selectListReportTemplate = createSelector(
    selectReportTemplateState,
    state => state.listReportTemplateIds.map(id => state.listReportTemplate[id] as ReportTemplate)
);

export const selectListReportTemplateMeta = createSelector(
    selectReportTemplateState,
    state => state.meta,
)

export const selectListReportTemplateMetaTotal = createSelector(
    selectReportTemplateState,
    state => state.meta.total,
)

export const selectSaveTemplateStatus = createSelector(
    selectReportTemplateState,
    state => state.saveTemplateStatus
)

export const selectDeleteTemplateStatus = createSelector(
    selectReportTemplateState,
    state => state.deleteTemplateStatus
)