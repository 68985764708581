import {Dictionary} from '@reduxjs/toolkit';
import {StateStatus} from '../../../common/enums';

export class CustomerActivityHistoryState {
    formStatus: StateStatus = 'idle';
    entities: Dictionary<any> = {};
    childrenMapper: Dictionary<number[]> = {};
    tableData: [] = [];
    meta: PaginationType = {} as PaginationType;
    listLoaded = false;
    status: StateStatus = 'idle';
}
