import isArray from "lodash/isArray";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import {isEmpty, isNumber} from "lodash";

export default function getFirstCharOfName(fullname: string | undefined) {
    if (fullname) {
        const lastSpace = fullname.lastIndexOf(' ');
        return fullname.substring(lastSpace + 1, lastSpace + 2).toUpperCase();
    }
}

export function isAvailable(val: any) {
    if (isArray(val)) {
        return val.length > 0;
    }
    if (isString(val)) {
        return !!val;
    }
    if (isObject(val)) {
        return !isEmpty(val) && Object.values(val).filter(v => !!v).length > 0;
    }

    if (isNumber(val)) {
        return !!val;
    }

    return false;
}