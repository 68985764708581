import {combineReducers} from "redux";
import departmentReducer from "./department/reducer";
import managementReducer from "./management/reducer";

const employeeReducer = combineReducers({
    department: departmentReducer,
    management: managementReducer
});

export default employeeReducer;
