import {CRUDService} from "../../../common/service";
import {Ticket} from "../../../models/ticket";
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";
import {filterParams} from "../../../common/utils";
import {NodeModel} from "../../../models/node-model";
import {AdvanceSearchForm} from "../../../models/advance-search-form";


export class TicketListService extends CRUDService<Ticket> {
    getNameSpace(): string {
        return 'tickets';
    }

    listTicketCustom({path, ...params}: any) {
        params = params ? filterParams(params) : {};
        return axios.get<any, Rest<Ticket>>(
            `${BASE_URL}/tickets/${path}`,
            {params}
        );
    }

    countUnreadTicketList({path, ...params}: any) {
        return axios.get<any, Rest<any>>(
            `${BASE_URL}/tickets/${path}`,
            {params}
        );
    }

    listMyTicketsLite(params: any) {
        params = params ? filterParams(params) : {};
        return axios.get<any, Rest<Ticket>>(
            `${BASE_URL}/tickets/my-tickets-lite`,
            {params}
        );
    }

    ticketProcessingNode(ticketId: number) {
        return axios.get<any, Rest<NodeModel>>(
            `${BASE_URL}/tickets/${ticketId}/processing-nodes`,
        );
    }
    advancedSearchForm() {
        return axios.get<any, Rest<AdvanceSearchForm>>(`${BASE_URL}/tickets/advance-search-form`)
    }
    advancedSearchTickets({path, ...data}: any) {
        return axios.post<any>(`${BASE_URL}/tickets/${path}`, data)
    }
}

const ticketListService = new TicketListService();

export default ticketListService;
