import {RouteData} from './core/router';
import {Routes} from 'react-hook-guard';
import {lazy} from 'react';
import {RouterEnum} from './common/enums';
import SiderLayout from "./layouts/SiderLayout";
import auth from "./features/auth/auth";
import {Permission} from "./models/enums/permission";
import {useAuthorizationGuard} from "./features/auth/useAuthorizationGuard";
import {useRouteTitleGuard} from "./common/title";
import {Resources} from "./models/enums/resources";
import history from "./config/history";
import fontAwesomeIcons from './utils/fontAwesomeIcons';
import { COUNT_UNREAD_TICKETS } from './config/properties';

const redirectToApplicationPage = () => {
    history.push('/' + RouterEnum.application);
    return <div></div>;
}

const useEmployeeUpdate = () => {
    auth.setEmployee();
    return true;
}

const appRoutes: Routes<RouteData> = [
    {
        path: 'notifications',
        component: SiderLayout,
        canActivateChild: [useRouteTitleGuard],
        children: [
            {
                path: '',
                component: lazy(() => import('./features/notification/index')),
                data: {
                    title: 'Thông báo',
                    menuDisplay: false,
                },
            },
        ],
        data: {
            menuDisplay: false,
            appIconDisplay: false
        }
    },
    {
        path: RouterEnum.accountPermission,
        component: SiderLayout,
        canActivateChild: [useRouteTitleGuard],
        children: [
            {
                path: '',
                component: lazy(() => import('./features/account-permission/AccountPermission/index')),
                data: {
                    title: "Phân quyền tài khoản",
                    menuDisplay: false,
                },
            }
        ],
        data: {
            menuDisplay: false,
            appIconDisplay: false
        }
    },
    {
        path: RouterEnum.root,
        exact: true,
        redirectTo: RouterEnum.application
    },
    {
        path: '',
        canActivateChild: [useEmployeeUpdate, useRouteTitleGuard, useAuthorizationGuard],
        children:
            [
            {
                path: RouterEnum.customerActivity,
                component: SiderLayout,
                children: [
                    {
                        path: '',
                        component: lazy(() => import('./features/customer-activity/Dashboard')),
                        exact: true,
                        data: {
                            title: 'Hoạt động khách hàng',
                            menuDisplay: false,
                        },
                    },
                    {
                        path: 'customer-statistic',
                        children: [
                            {
                                path: '',
                                component: lazy(() => import('./features/customer-statistic/components/TrackMenu')),
                                children: [
                                    {
                                        path: '',
                                        redirectTo: RouterEnum.leadA,
                                        exact: true,
                                    },
                                    {
                                        path: ':type',
                                        component: lazy(() => import('./features/customer-statistic/index')),
                                        data: {
                                            title: 'Lịch sử mua hàng',
                                            menuDisplay: false,
                                        }
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'market-insight',
                        children: [
                            {
                                path: '',
                                component: lazy(() => import('./features/market-insight/TrackMenu')),
                                children: [
                                    {
                                        path: '',
                                        redirectTo: RouterEnum.jobPost,
                                        exact: true,
                                    },
                                    {
                                        path: ':type',
                                        component: lazy(() => import('./features/market-insight')),
                                        data: {
                                            title: 'Market Insight',
                                            menuDisplay: false,
                                        }
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'tracking',
                        component: lazy(() => import('./features/customer-activity/TrackerMenu')),
                        data: {
                            title: 'Theo dõi hoạt động',
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'realtime',
                                exact: true
                            },
                            // {
                            //     path: 'history',
                            //     component: lazy(() => import('./features/customer-activity/CustomerActivityHistory')),
                            //     data: {
                            //         title: 'Lịch sử hoạt động',
                            //         menuDisplay: false,
                            //         permissions: [
                            //             {
                            //                 permission: Permission.READ,
                            //                 resource: Resources.ACTIVITY_HISTORY
                            //             }
                            //         ]
                            //     },
                            // },
                            {
                                path: 'realtime',
                                component: lazy(() => import('./features/customer-activity/RealtimeOnline')),
                                canActivate: [useAuthorizationGuard],
                                data: {
                                    title: 'Khách hàng trực tuyến',
                                    menuDisplay: false,
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.ACTION_REALTIME
                                        }
                                    ]
                                },
                            },
                        ]
                    },
                ],
                data: {
                    menuDisplay: true,
                    menuData: [
                        {
                            icon: "home",
                            title: "common:homepage",
                            path: "",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.ACTIVITY_HISTORY
                                }
                            ]
                        },
                        {
                            icon: "wave-pulse",
                            title: "application:customer_activities",
                            path: "tracking",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.ACTION_REALTIME
                                }
                            ]
                        },
                        {
                            icon: fontAwesomeIcons.farChartLineUp,
                            title: "application:market_insight",
                            path: "market-insight",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.MARKET_INSIGHT,
                                }
                            ],
                        },
                        {
                            icon: fontAwesomeIcons.farCommentDollar,
                            title: "application:customer_statistics",
                            path: "customer-statistic",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.CUSTOMER_STATISTICS,
                                }
                            ],
                        }
                    ]
                }
            },
            {
                path: RouterEnum.customerProfile,
                component: SiderLayout,
                children: [
                    {
                        path: "detail/:customerId(\\d+)",
                        component: lazy(() => import('./features/customer-profile/CustomerDetail')),
                        canActivateChild: [useRouteTitleGuard],
                        children: [
                            {
                                path: '',
                                redirectTo: 'call-history',
                                exact: true
                            },
                            // {
                            //     path: "pageview-history",
                            //     component: lazy(() => import('./features/customer-profile/CustomerDetail/PageViewHistory')),
                            //     data: {
                            //         title: "Lịch sử xem trang",
                            //         permissions: [
                            //             {
                            //                 permission: Permission.READ,
                            //                 resource: Resources.PAGE_VIEW_HISTORY
                            //             }
                            //         ]
                            //     }
                            // },
                            {
                                path: "call-history",
                                component: lazy(() => import('./features/customer-profile/CustomerDetail/CallHistory')),
                                data: {
                                    title: "Lịch sử cuộc gọi",
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.CALL_HISTORY
                                        }
                                    ]
                                }
                            },
                            // {
                            //     path: "chat-history",
                            //     component: lazy(() => import('./features/customer-profile/CustomerDetail/ChatHistory')),
                            //     data: {
                            //         title: "Lịch sử chat"
                            //     }
                            // },
                            {
                                path: "note-history",
                                component: lazy(() => import('./features/customer-profile/CustomerDetail/NoteHistory')),
                                data: {
                                    title: "Lịch sử note",
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.NOTE_HISTORY
                                        }
                                    ]
                                }
                            },
                            {
                                path: "purchase-history",
                                component: lazy(() => import('./features/customer-profile/CustomerDetail/PurchaseHistory')),
                                data: {
                                    title: "Lịch sử mua hàng",
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.PURCHASE_HISTORY,
                                        }
                                    ]
                                }
                            },
                            {
                                path: "payment-history",
                                component: lazy(() => import('./features/customer-profile/CustomerDetail/PaymentHistory')),
                                data: {
                                    title: "Lịch sử thanh toán",
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.PAYMENT_HISTORY,
                                        }
                                    ]
                                }
                            },
                            {
                                path: "service-activation-history",
                                component: lazy(() => import('./features/customer-profile/CustomerDetail/ServiceActivationHistory')),
                                data: {
                                    title: "Lịch sử kích hoạt dịch vụ",
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.ACTIVITY_LOG_HISTORY,
                                        }
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        path: "",
                        component: lazy(() => import('./features/customer-profile/CustomerProfileList')),
                        data: {
                            title: "Hồ sơ khách hàng"
                        }
                    }
                ],
                data: {
                    menuDisplay: true,
                    menuData: [
                        {
                            icon: "file-user",
                            title: "customer-profile:listCustomerProfile",
                            path: "",
                        }
                    ]
                }
            },
            {
                path: RouterEnum.callManagement,
                component: SiderLayout,
                children: [
                    {
                        path: "histories",
                        component: lazy(() => import('./features/engagement/CallLog')),
                        data: {
                            title: "Danh sách cuộc gọi",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.ENGAGE_CALL,
                                }
                            ]
                        }
                    },
                    {
                        path: "",
                        redirectTo: "histories",
                        exact: true
                    }
                ],
                data: {
                    menuDisplay: true,
                    menuData: [
                        {
                            icon: "address-book",
                            title: "call:history",
                            path: "",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.ENGAGE_CALL,
                                }
                            ]
                        }
                    ]
                }
            },
            {
                path: RouterEnum.employee,
                component: SiderLayout,
                children: [
                    {
                        path: '',
                        exact: true,
                        redirectTo: 'management'
                    },
                    {
                        path: "management",
                        component: lazy(() => import('./features/employee/management')),
                        data: {
                            title: "Quản lý nhân sự",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.EMPLOYEE,
                                }
                            ]
                        }
                    },
                    {
                        path: "department",
                        component: lazy(() => import('./features/employee/department')),
                        data: {
                            title: "Phòng ban",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.ORGANIZATION_DEPARTMENT,
                                }
                            ]
                        }
                    },
                    {
                        path: "role/:roleId(\\d+)",
                        component: lazy(() => import('./features/authorization/roles/components/FormRolePermission')),
                        data: {
                            title: "Cập nhật vai trò",
                            permissions: [
                                {
                                    permission: Permission.UPDATE,
                                    resource: Resources.ROLE,
                                }
                            ]
                        }
                    },
                    {
                        path: "role/create",
                        component: lazy(() => import('./features/authorization/roles/components/FormRolePermission')),
                        data: {
                            title: "Tạo mới vai trò",
                            permissions: [
                                {
                                    permission: Permission.CREATE,
                                    resource: Resources.ROLE,
                                }
                            ]
                        }
                    },
                    {
                        path: "role",
                        component: lazy(() => import('./features/authorization/roles')),
                        data: {
                            title: "Quản lý vai trò",
                            permissions: [
                                {
                                    permission: Permission.ACCESS,
                                    resource: Resources.ROLE,
                                }
                            ]
                        },
                    },
                ],
                data: {
                    menuDisplay: true,
                    menuData: [
                        {
                            icon: "list",
                            title: "employee:management",
                            path: "management",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.EMPLOYEE,
                                }
                            ]
                        },
                        {
                            icon: "list",
                            title: "employee:department",
                            path: "department",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.ORGANIZATION_DEPARTMENT,
                                }
                            ]
                        },
                        {
                            icon: "list",
                            title: "employee:role",
                            path: "role",
                            permissions: [
                                {
                                    permission: Permission.ACCESS,
                                    resource: Resources.ROLE,
                                }
                            ]
                        }
                    ]
                }
            },
            {
                path: RouterEnum.workflow,
                component: SiderLayout,
                children: [
                    {
                        path: 'workflows',
                        children: [
                            {
                                path: '',
                                component: lazy(() => import('./features/workflow/workflows/List')),
                                exact: true,
                                data: {
                                    title: "Danh sách workflow",
                                    menuDisplay: false,
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.WORKFLOW,
                                        },
                                        {
                                            permission: Permission.ACCESS,
                                            resource: Resources.WORKFLOW,
                                        },
                                    ]
                                }
                            },
                            {
                                path: "create",
                                component: lazy(() => import('./features/workflow/workflows/FormDetail')),
                                data: {
                                    title: "Tạo mới Workflow",
                                    permissions: [
                                        {
                                            permission: Permission.CREATE,
                                            resource: Resources.WORKFLOW,
                                        }
                                    ]
                                }
                            },
                            {
                                path: ":workflowId(\\d+)",
                                component: lazy(() => import('./features/workflow/workflows/FormDetail')),
                                data: {
                                    title: "Chi tiết Workflow",
                                    permissions: [
                                        {
                                            permission: Permission.UPDATE,
                                            resource: Resources.WORKFLOW,
                                        }
                                    ]
                                }
                            },
                            {
                                path: "create",
                                component: lazy(() => import('./features/workflow/workflows/FormDetail')),
                                data: {
                                    title: "Workflow",
                                    permissions: [
                                        {
                                            permission: Permission.CREATE,
                                            resource: Resources.WORKFLOW,
                                        }
                                    ]
                                }
                            },
                            {
                                path: ":workflowId(\\d+)",
                                component: lazy(() => import('./features/workflow/workflows/FormDetail')),
                                data: {
                                    title: "Workflow",
                                    permissions: [
                                        {
                                            permission: Permission.UPDATE,
                                            resource: Resources.WORKFLOW,
                                        }
                                    ]
                                }
                            },
                            {
                                path: "detail/:workflowId(\\d+)",
                                component: lazy(() => import('./features/workflow/workflows/Detail')),
                                data: {
                                    title: "Workflow",
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.WORKFLOW,
                                        }
                                    ]
                                }
                            },
                        ],
                    },
                    {
                        path: RouterEnum.ticket,
                        component: lazy(() => import('./features/workflow/ticket/RedirectToNewTicketRoute'))
                    },
                    {
                        path: RouterEnum.tickets,
                        children: [
                            {
                                path: "create",
                                component: lazy(() => import('./features/workflow/TicketDetail/CreateTicket')),
                                data: {
                                    title: "Tạo mới ticket",
                                    permissions: [
                                        {
                                            permission: Permission.CREATE,
                                            resource: Resources.TICKET,
                                        }
                                    ]
                                }
                            },
                            {
                                path: ":ticketId(\\d+)",
                                component: lazy(() => import('./features/workflow/TicketDetail/TicketDetailMenu')),
                                children: [
                                    {
                                        path: '',
                                        redirectTo: {
                                            path: RouterEnum.ticketProcessing,
                                            keepQuery: true,
                                        },
                                        exact: true,
                                    },
                                    {
                                        path: ':id(\\d+)',
                                        redirectTo: {
                                            path: RouterEnum.ticketProcessing,
                                            keepQuery: true,
                                        },
                                        exact: true,
                                    },
                                    {
                                        path: ':type',
                                        component: lazy(() => import('./features/workflow/TicketDetail')),
                                        data: {
                                            title: '',
                                            menuDisplay: false,
                                            permissions: [
                                                {
                                                    permission: Permission.READ,
                                                    resource: Resources.TICKET,
                                                }
                                            ]
                                        }
                                    },
                                ],
                                data: {
                                    title: "Chi tiết Ticket",
                                    permissions: [
                                        {
                                            permission: Permission.UPDATE,
                                            resource: Resources.TICKET,
                                        }
                                    ]
                                }
                            },
                            {
                                path: '',
                                component: lazy(() => import('./features/workflow/ticket/TrackerMenu')),
                                data: {
                                    title: "Danh sách ticket"
                                },
                                children: [
                                    {
                                        path: '',
                                        redirectTo: RouterEnum.processTicket,
                                        exact: true,
                                    },
                                    {
                                        path: ':type',
                                        component: lazy(() => import('./features/workflow/ticket/Ticket')),
                                        data: {
                                            title: '',
                                            menuDisplay: false,
                                            permissions: [
                                                {
                                                    permission: Permission.READ,
                                                    resource: Resources.TICKET,
                                                }
                                            ]
                                        }
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: RouterEnum.categories,
                        data: {
                            title: "Danh sách hạng mục workflow",
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.WORKFLOW_CATEGORY,
                                },
                                {
                                    permission: Permission.ACCESS,
                                    resource: Resources.WORKFLOW_CATEGORY,
                                },
                            ]
                        },
                        children: [
                            {
                                path: '',
                                component: lazy(() => import('./features/workflow/category/Table')),
                                data: {
                                    menuDisplay: true,
                                    permissions: [
                                        {
                                            permission: Permission.READ,
                                            resource: Resources.WORKFLOW_CATEGORY,
                                        }
                                    ]
                                }
                            },
                        ]
                    }
                ],
                data: {
                    menuDisplay: true,
                    menuData: [
                        {
                            icon: fontAwesomeIcons.farScrollOld,
                            title: 'ticket:title',
                            path: 'tickets',
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.TICKET,
                                }
                            ],
                            configShowCount: {
                                isShow: true,
                                key: COUNT_UNREAD_TICKETS
                            },
                        },
                        {
                            icon: fontAwesomeIcons.farFolderTree,
                            title: 'workflow_category:title',
                            path: RouterEnum.categories,
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.WORKFLOW_CATEGORY,
                                },
                                {
                                    permission: Permission.ACCESS,
                                    resource: Resources.WORKFLOW_CATEGORY,
                                }
                            ]
                        },
                        {
                            icon: fontAwesomeIcons.farCodeFork,
                            title: "workflow:title",
                            path: 'workflows',
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.WORKFLOW,
                                },
                                {
                                    permission: Permission.ACCESS,
                                    resource: Resources.WORKFLOW,
                                }
                            ]
                        },
                    ]
                }
            },
            {
                path: RouterEnum.reporting,
                component: SiderLayout,
                canActivateChild: [useRouteTitleGuard],
                children: [
                    {
                        path: '',
                        exact: true,
                        redirectTo: 'templates'
                    },
                    {
                        path: 'templates',
                        children: [
                            {
                                path: '',
                                exact: true,
                                component: lazy(() => import('./features/reporting/template')),
                                data: {
                                    title: 'Mẫu báo cáo',
                                    menuDisplay: false,
                                },
                            },
                            {
                                path: ':templateId(\\d+)',
                                component: lazy(() => import('./features/reporting/template/TemplateDetail')),
                                data: {
                                    menuDisplay: false,
                                    title: 'Chi tiết mẫu báo cáo',
                                },
                            },
                            {
                                path: RouterEnum.create,
                                component: lazy(() => import('./features/reporting/template/TemplateDetail')),
                                data: {
                                    menuDisplay: false,
                                    title: 'Tạo mẫu báo cáo',
                                },
                            },
                        ]
                    },
                    {
                        path: 'reports',
                        children: [
                            {
                                path: '',
                                exact: true,
                                component: lazy(() => import('./features/reporting/report')),
                                data: {
                                    title: 'Báo cáo',
                                    menuDisplay: false,
                                },
                            },
                            {
                                path: ':reportId(\\d+)',
                                component: lazy(() => import('./features/reporting/report/ReportDetail')),
                                data: {
                                    menuDisplay: false,
                                    title: 'Chi tiết báo cáo',
                                },
                            },
                            {
                                path: 'create',
                                exact: true,
                                component: lazy(() => import('./features/reporting/report/ReportDetail')),
                                data: {
                                    menuDisplay: false,
                                    title: 'Tạo báo cáo'
                                },
                            },
                        ]
                    },
                ],
                data: {
                    menuDisplay: true,
                    menuData: [
                        {
                            icon: "line-chart",
                            title: 'reporting:title_template',
                            path: 'templates',
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.TICKET,
                                }
                            ]
                        },
                        {
                            icon: "file-chart-column",
                            title: 'reporting:title_report',
                            path: 'reports',
                            permissions: [
                                {
                                    permission: Permission.READ,
                                    resource: Resources.TICKET,
                                }
                            ]
                        },
                    ]
                }
            },
            {
                path: 'application',
                exact: true,
                component: SiderLayout,
                children: [
                    {
                        path: '',
                        component: lazy(() => import('./features/application/ApplicationList')),
                        data: {
                            title: "Các ứng dụng của hệ thống",
                            menuDisplay: false,
                        },
                    },
                ],
                data: {
                    menuDisplay: false,
                    appIconDisplay: false
                }
            },
            {
                path: 'setting-notification',
                component: SiderLayout,
                canActivateChild: [useRouteTitleGuard],
                children: [
                    {
                        path: '',
                        component: lazy(() => import('./features/setting-notification')),
                        data: {
                            title: 'Cài đặt thông báo',
                            menuDisplay: false,
                        }
                    },
                ],
                data: {
                    menuDisplay: false,
                    appIconDisplay: false,
                }
            },
            {
                path: ':keyword(.*?)',
                component: redirectToApplicationPage,
            },
        ]
    },
];

export default appRoutes;
