import {all, call, put, select, takeEvery, takeLeading} from "redux-saga/effects";
import {departmentActions} from "./reducer";
import {safeCall} from "../../../common/utils";
import {selectDepartmentListLoading} from "./selector";
import {Rest} from "../../../common/models/rest";
import {Department} from "../../../models/department";
import departmentService from "./service";
import {message} from "antd";
import i18next from "i18next";

const sagas = [
    takeLeading(departmentActions.load, safeCall(function* () {
        const listLoading: boolean = yield select(selectDepartmentListLoading);
        if (!listLoading) return;

        const result: Rest<Department[]> = yield call(departmentService.flat);

        yield put(departmentActions.loaded(result));
    })),
    takeLeading(departmentActions.submit, function* ({payload}) {
        try {
            const result: Rest<Department> = payload.id
                ? yield call(departmentService.update, payload.id, payload)
                : yield call(departmentService.store, payload)

            yield put(departmentActions.updated(result.data));
            message.success(i18next.t<string>('employee:form.success'));
        } catch (e) {
            yield put(departmentActions.submitFailed());
        }
    }),
    takeEvery(departmentActions.doDelete, function* ({payload}) {
        try {
            yield call(departmentService.delete, payload);
            yield put(departmentActions.deleteSuccess(payload));
            message.success(i18next.t<string>('employee:message.delete_department_successful'));
        } catch (e) {
            yield put(departmentActions.deleteFailed(payload));
        }
    }),
];

function* departmentSagas() {
    yield all(sagas);
}

export default departmentSagas;
