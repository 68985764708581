import React, {useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Avatar, Dropdown, Button, MenuProps} from "antd";
import {useTranslation} from "react-i18next";
import auth from "../../features/auth/auth";
import {LOGIN_URL} from "../../config/properties";
import getFirstCharOfName from "../../common/utils/string-util";
import fontAwesomeIcons from "../../utils/fontAwesomeIcons";
import {Link} from "react-router-dom";

export default function ProfileButton() {
    const {t} = useTranslation();
    const logout = () => {
        auth.logout().then(() => {
            document.location.href = LOGIN_URL
        })
    }

    const items = useMemo(() => {
        const arr: MenuProps['items'] = [];

        arr.push({
            key: 'header-setting-notification',
            label: (
                <Link
                    className={'hover-text-main flex items-center py-3 hover:font-semibold'}
                    to={`/setting-notification`}
                >
                    <FontAwesomeIcon
                        className={'mr-10 w-18 fa-w-18'}
                        icon={fontAwesomeIcons.faGear}
                    />
                    {t('common:setting_notification')}
                </Link>
            ),
        })

        arr.push({
                key: 'header-view-permission',
                label: (
                    <Link
                        className={'hover-text-main flex items-center py-3 hover:font-semibold'}
                        to={'/account-permissions'}
                    >
                        <FontAwesomeIcon
                            className={'mr-10 w-18 fa-w-18'}
                            icon={fontAwesomeIcons.faUserGear}
                        />
                        {t('common:view_permission')}
                    </Link>
                ),
            },
            {
                key: 'header-logout',
                label: (
                    <div className={'hover:text-main flex items-center py-3 hover:font-semibold'}>
                        <FontAwesomeIcon
                            className="mr-10 w-18 fa-w-18"
                            icon={fontAwesomeIcons.faArrowRightFromBracket}
                        />
                        {t("common:logout")}
                    </div>
                ),
                onClick: logout,
            });
        return arr;
    }, [t]);


    return (
        <Dropdown
            trigger={['click']}
            menu={{ items }}
            placement="bottomLeft"
            arrow
            className="bg-greyFA rounded-full"
        >
            <Button type="text" className="h-48 px-10 cursor-pointer flex-i flex-row items-center">
                <Avatar
                    className="mr-8 bg-background text-black rounded-full"
                    size={36}
                    shape="square"
                    src={auth?.user?.avatar}
                >
                    {getFirstCharOfName(auth?.user?.fullname)}
                </Avatar>
                <div className="mr-8 text-12 text-left w-140 overflow-hidden">
                    <div className="font-small-title truncate text-primaryText">{auth?.user?.fullname}</div>
                    <div className="text-secondaryText font-small mb-0">
                        {auth?.user?.code}
                    </div>
                </div>
                <FontAwesomeIcon className={'mr-10'} icon={fontAwesomeIcons.faChevronDown}/>
            </Button>
        </Dropdown>
    );
}
