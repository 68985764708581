import {Badge, Button, Popover} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    loadAllNotification,
    loadCountNew,
    markAsReadAll,
    markNotificationAsRead
} from "../../features/notification/reducer";
import {
    selectAllNotification,
    selectCountNewNotification,
    selectHasNotification
} from "../../features/notification/selectors";
import {getLink, Notification} from "../../models/notification";
import {format} from "date-fns";
import {DATE_TIME_FORMAT} from "../../common/constants";
import notificationService from "../../features/notification/service";
import {Link} from "react-router-dom";
import fontAwesomeIcons from "../../utils/fontAwesomeIcons";

const NotificationItem = ({noti}: {noti: Notification}) => {
    const dispatch = useDispatch();
    const markAsRead = useCallback(async (read: boolean, event?: any) => {
        event?.preventDefault();
        event?.stopPropagation();
        try {
            await notificationService.markRead(noti, read)
            dispatch(markNotificationAsRead({id: noti.id, read}))
        } catch (e) {
        }
    }, [noti, dispatch]);
    return <div className={'notification-item ' + (noti.read_at ? '' : 'un-read')} key={noti.id}>
        <Link to={getLink(noti)} className={'cursor-pointer'} onClick={() => markAsRead(true)}>
            {noti.title}
            <div className={'text-blackSoft w-550 mb-5'}>{noti.content}</div>
            <div className={'flex justify-between text-blackSoft'}>
                <span className={'text-12'}>{format(new Date(noti.created_at), DATE_TIME_FORMAT)}</span>
                {
                    !noti.read_at
                        ? <Button type={'link'}
                                  className={'text-11 mark-read'}
                                  onClick={(event) => markAsRead(true, event)}
                        >Đánh dấu là đã đọc</Button>
                        : <Button type={'link'}
                                  className={'text-11 mark-read'}
                                  onClick={(event) => markAsRead(false, event)}
                        >Đánh dấu là chưa đọc</Button>
                }
            </div>
        </Link>
    </div>
}

const NotificationList = () => {
    const notifications = useSelector(selectAllNotification);
    const hasNoti = useSelector(selectHasNotification);
    return (
        hasNoti
            ? <div>
                <div className={'notification-list'}>
                    {
                        notifications.map((noti: Notification) => <NotificationItem noti={noti} key={noti.id}/>)
                    }
                </div>
                <div className={'text-center mt-5'}>
                    <Link to={'/notifications'}>Xem tất cả</Link>
                </div>
            </div>
            : <div>Không có thông báo</div>
    )
};

const NotificationTitle = () => {
    const hasNoti = useSelector(selectHasNotification);
    const dispatch = useDispatch();
    const markReadAll = useCallback(async (read: boolean) => {
        dispatch(markAsReadAll(read))
    }, [dispatch]);
    return (
        <div className={'flex justify-between'}>
            <div>
                Thông báo
            </div>
            {
                hasNoti

                    ?
                    <Button type={'link'}
                            className={'font-normal'}
                            onClick={() => markReadAll(true)}
                    >Đánh dấu tất cả là đã đọc</Button>
                    : null
            }
        </div>
    )
}

export default function NotificationBell() {
    const dispatch = useDispatch();
    const fetchNotification = useCallback(
        () => {
            dispatch(loadAllNotification({limit: 7}));
        },
        [dispatch],
    );
    const fetchCountNew = useCallback(
        () => {
            dispatch(loadCountNew());
        },
        [dispatch],
    );
    useEffect(() => {
        fetchNotification()
        fetchCountNew()
    }, [fetchCountNew, fetchNotification]);
    const countNewNotification = useSelector(selectCountNewNotification);
    return (
        <Popover
            className={'fsa'}
            content={NotificationList}
            title={NotificationTitle}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentElement ?? new HTMLElement()}
            trigger="click"
        >
            <div className="mr-24">
                <Badge size={'small'} count={countNewNotification} offset={[0, 6]} color={"red"} className={"mt-12"}>
                    <Button
                        type="text"
                        className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
                    >
                        <FontAwesomeIcon className="text-grey21 text-20" icon={fontAwesomeIcons.farBells}/>
                    </Button>
                </Badge>
            </div>
        </Popover>
    )
}
