import AppState from '../../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Role} from "../../../models/role";

export const selectRoleListState = (state: AppState) => state.roleList;


export const selectRoleListLoading = createSelector(
    selectRoleListState,
    state => state.loading,
)

export const selectAllRole = createSelector(
    selectRoleListState,
    state => state.roles
)

export const selectRoleList = createSelector(
    selectRoleListState,
    state => state.ids.map(id => state.list[id] as Role),
)

export const selectRoleById = (id?: number) => createSelector(
    selectRoleListState,
    state => state.list[id ?? 0],
)

export const selectCurrentRole = createSelector(
    selectRoleListState,
    state => state.currentRole
)

export const selectRoleListMeta = createSelector(
    selectRoleListState,
    state => state.meta
)

export const selectFormStatusLoading = createSelector(
    selectRoleListState,
    state => state.formStatus === 'loading'
)

export const selectRoleFormStatus= createSelector(
    selectRoleListState,
    state => state.formStatus
)