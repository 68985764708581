import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {addedNote, deletedNote, deletingNote, noteInfoLoaded, refreshNoteInfo, submittingAddNote} from "./reducer";
import {NoteState} from "./state";
import {selectDeletingStatus, selectNoteStatus, selectNoteSubmitting} from "./selector";
import noteService from "./service";
import {Note} from "../../../../models/note";
import {Rest} from "../../../../common/models/rest";
import {safeCall} from "../../../../common/utils";
import i18next from "i18next";
import {message} from "antd";

const sagas = [
    takeLeading(refreshNoteInfo, safeCall(function* ({payload}) {
        const { status }: NoteState = yield select(selectNoteStatus);
        if (status === 'loading') return
        const response: Rest<Note[]> = yield call(noteService.list, payload.customerId ?? 0, payload)
        yield put(noteInfoLoaded(response))
    })),
    takeLeading(submittingAddNote, safeCall(function *({payload}) {
        const {submitting}: NoteState = yield select(selectNoteSubmitting);
        if (submitting === 'loading') return
        const response: Rest<Note> = yield call(noteService.create, payload.customer_id ?? 0, payload)
        message.success(i18next.t<string>('common:addNoteSuccess'));
        yield put(addedNote(response))
    })),
    takeLeading(deletingNote, safeCall(function *({payload}) {
        const {deleting}: NoteState = yield select(selectDeletingStatus);
        if (deleting === 'loading') return
        const response: Rest<Note> = yield call(noteService.delete, payload.customer_id ?? 0, payload)
        message.success(i18next.t<string>('common:deleteNoteSuccess'));
        yield put(deletedNote(response))
    }))
]
function* noteSagas() {
    yield all(sagas)
}

export default noteSagas;
