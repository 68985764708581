import {CRUDService} from "../../common/service";


class CustomerStatisticService extends CRUDService<any> {
    getNameSpace(): string {
        return "customer-statistics";
    }
}

const customerStatisticService = new CustomerStatisticService();

export default customerStatisticService;