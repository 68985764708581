import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TicketLogState} from "./state";
import {Rest} from "../../../../common/models/rest";
import {TicketLog} from "../../../../models/ticket-log";
import {keyBy} from "lodash";

const slice = createSlice({
    initialState: {...new TicketLogState()},
    name: 'ticketLog',
    reducers: {
        refreshListTicketLog(state: TicketLogState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
            if (payload.ticketId !== state.currentTicketId) {
                state.currentTicketId = payload.ticketId;
                state.entities = {};
                state.ids = [];
            }
        },
        listTicketLogLoaded(state: TicketLogState, {payload}: PayloadAction<Rest<TicketLog[]>>) {
            state.ids = Array.from(new Set(payload.data.map(({id}) => (id))));
            state.entities = keyBy(payload.data, 'id');
            state.status = 'idle';
            state.meta = payload.meta;
        },
        listTicketLogLoadMoreLoaded(state: TicketLogState, {payload}: PayloadAction<Rest<TicketLog[]>>) {
            state.ids = Array.from(new Set([...state.ids, ...payload.data.map(({id}) => (id))]));
            state.entities = {...state.entities, ...keyBy(payload.data, 'id')};
            state.status = 'idle';
            state.meta = payload.meta;
        },
        loadNextPageTicketLog(state: TicketLogState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
        },
        loadingTicketTimeline(state: TicketLogState, {payload}: PayloadAction<any>) {
            state.statusTimeline = 'loading';
            if (payload.ticketId !== state.currentTicketIdTimeline) {
                state.currentTicketIdTimeline = payload.ticketId;
                state.entitiesTimeline = {};
                state.idsTimeline = [];
            }
        },
        listTicketTimelineLoaded(state: TicketLogState, {payload}: PayloadAction<Rest<TicketLog[]>>) {
            payload.data = payload.data.reverse();
            state.idsTimeline = Array.from(new Set(payload.data.map((ticketLog, index) => {
                state.isCollapse[ticketLog.id] = false;
                if (index === payload.data.length - 1) {
                    state.isCollapse[ticketLog.id] = true;
                }
                return ticketLog.id;
            })));
            state.entitiesTimeline = keyBy(payload.data, 'id');
            state.statusTimeline = 'idle';
            state.autoLoadingTicketTimeline = true;
        },
        toggleCollapse(state, {payload}: PayloadAction<number>) {
            state.isCollapse[payload] = true;
        },
        toggleExpand(state, {payload}: PayloadAction<number>) {
            state.isCollapse[payload] = false;
        }
    }
})

export const {
    refreshListTicketLog,
    listTicketLogLoaded,
    loadNextPageTicketLog,
    listTicketLogLoadMoreLoaded,
    loadingTicketTimeline,
    listTicketTimelineLoaded,
    toggleCollapse,
    toggleExpand,
} = slice.actions;

const ticketLogReducer = slice.reducer;

export default ticketLogReducer;
