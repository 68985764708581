import AppState from '../../../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Note} from "../../../../models/note";

export const selectNoteState = (state: AppState) => state.note;

export const selectNoteEntities = createSelector(
    selectNoteState,
    state => state.ids.map(id => state.entities[id] as Note)
)

export const selectNoteStatus = createSelector(
    selectNoteState,
    state => state.status
)

export const selectNoteMeta = createSelector(
    selectNoteState,
    state => state.meta
);

export const selectNoteSubmitting = createSelector(
    selectNoteState,
    state => state.submitting
)

export const selectDeletingStatus = createSelector(
    selectNoteState,
    state => state.deleting
)
