import authService from "../../features/auth/service";
import {User} from "../../models/user";
import {Employee} from "../../models/employee";
import {CloudMessaging} from '../firebase';
import {decodeToken} from "react-jwt";
import {Resources} from "../../models/enums/resources";
import {Permission} from "../../models/enums/permission";
import {APP_DEBUG} from "../../config/properties";

export const TOKEN_KEY = '__auth_token';

export type AuthListener = (_?: any) => void;

export enum AuthEvent {
    EMPLOYEE_CHANGED = 'employeeChanged',
}

export interface PermissionValidation {
    resource: Resources;
    permission: Permission;
}

class Auth {
    token?: string;
    info: any;
    user: User | undefined;
    error?: string | undefined;
    employee: Employee | undefined;
    loadedEmployee: boolean | undefined;

    listeners: Partial<Record<AuthEvent, AuthListener[]>> = {};
    constructor(
        private readonly url: string,
        private refreshTrackingUrl?: string,
        private refreshRemainTTLMls: number = 10, // Còn dư 10p thì refresh token
    ) {
    }

    public hasPermissions(permissions?: PermissionValidation[]) {
        if (!permissions?.length) {
            return true
        }
        const employeePermissions = this.employee?.role?.permissions ?? []
        for (let p of permissions) {
            const isFound = employeePermissions.find(e => {
                return e.permission === p.permission && e.resource === p.resource;
            })
            if (!isFound) {
                return false;
            }
        }
        return true;
    }

    private getRemainTTL() {
        return (this.info?.exp ?? 0) - new Date().getTime() / 1000
    }

    private getRemainTTLMls() {
        return (this.info?.exp ?? 0) * 1000 - new Date().getTime();
    }

    private initInfo(token?: string) {
        this.token = token
        this.info = decodeToken(token ?? '')
    }

    private async fetchUserInfo() {
        const res = await authService.userData()
        this.user = res.data
        return res
    }

    private registerDevice() {
        CloudMessaging.instance().getToken()
            .then(token => {
                if (token) {
                    console.log('firebase token: ', token);
                    return authService.registerDevice(token!)
                }
                else {
                    return Promise.reject('Can not generate firebase token, check notification permission or contact the admin');
                }
            })
            .then(response => {
                console.log('Device registered: ', response);
            })
            .catch(e => {
                console.error('Register device failed: ', e);
            });
    }

    async setEmployee() {
        if (this.loadedEmployee) {
            return;
        }
        this.loadedEmployee = true;
        const res = await authService.employeeData()
        this.employee = res.data;
        for (const employeeChanged of (this.listeners[AuthEvent.EMPLOYEE_CHANGED] ?? [])) {
            employeeChanged(this.employee);
        }
    }

    private saveToken(token: string) {
        this.token = token
        localStorage.setItem(TOKEN_KEY, token)
    }

    public runRefreshTokenInterval(intervalTime: number = 10 /* IntervalTime tính bằng phút */) {
        setInterval(() => {
            if (this.getRemainTTL() <= intervalTime) {
                this.refreshToken()
            }
        }, intervalTime * 60 * 1000)
    }

    public runRefreshTokenSse(refreshRate: number = 30) {
        if (!this.refreshTrackingUrl) return;
        const refreshRateMls = refreshRate * 60 * 1000;
        const sse = new EventSource(this.refreshTrackingUrl);

        sse.addEventListener("ping", () => {
            const remain = this.getRemainTTLMls();

            if (APP_DEBUG) {
                // console.log(`[Auth] Tracking event source and remain ${remain}ms`, event);
            }

            if (remain < refreshRateMls) {
                this.refreshToken();
            }
        });
    }

    async refreshToken() {
        const res = await authService.refreshToken();
        const token = res.data.token;
        this.saveToken(token);
        this.initInfo(token);
    }

    goLogin() {
        window.location.href = this.url + "?redirect_uri=" + window.location.origin + window.location.pathname + encodeURIComponent(window.location.search);
    }

    async logout() {
        await authService.logout()
        localStorage.removeItem(TOKEN_KEY)
        this.initInfo('')
        this.user = undefined
        return new Promise((resolve) => {
            resolve(this)
        })
    }

    getStoredToken() {
        return localStorage.getItem(TOKEN_KEY)
    }

    async init(): Promise<Auth> {
        (window as any).refreshToken = () => this.refreshToken();
        const urlSearchParams = new URLSearchParams(window.location.search)
        let token = urlSearchParams.get('token')
        if (token) {
            this.saveToken(token!)
            urlSearchParams.delete('token');
            // Navigate to none token link.
            window.location.href = window.location.origin
                + window.location.pathname
                + '?'
                + urlSearchParams.toString();
        } else {
            token = localStorage.getItem(TOKEN_KEY)
        }
        if (!token) {
            this.goLogin()
            return Promise.reject("Not detect any token");
        }
        this.initInfo(token!)
        if (this.getRemainTTLMls() < this.refreshRemainTTLMls * 60 * 1000) {
            try {
                await this.refreshToken();
            } catch (e) {
                this.goLogin()
                return Promise.reject("Invalid token");
            }

        }
        if (this.refreshTrackingUrl) {
            this.runRefreshTokenSse(this.refreshRemainTTLMls);
        } else {
            this.runRefreshTokenInterval();
        }
        this.registerDevice()
        await this.fetchUserInfo()
        return this
    }

    public addEventListener(event: AuthEvent, callback: AuthListener) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event]?.push(callback);
    }

    public removeEventListener(event: AuthEvent, callback: AuthListener) {
        this.listeners[event] = this.listeners[event]?.filter(c => c !== callback);
    }
}

export default Auth;
