export enum RouterEnum {
  root = '',
  login = 'login',
  dashboard = 'dashboard',
  create = 'create',
  edit = 'edit',
  delete = 'delete',
  list = 'list',
  categories = 'categories',
  application = 'application',
  leadManagement = 'lead-management',
  connect = 'connect',
  customerActivity = 'customer-activities',
  accountPermission = 'account-permissions',
  employee = 'employee',
  note = 'engagement/note',
  customerProfile = 'customer-profile',
  callManagement = 'call-management',
  departments = 'departments',
  workflow = 'workflow',
  account = 'account',
  workflows = 'workflows',
  tickets = 'tickets',
  ticket = 'ticket',
  ticket_node = 'ticket-nodes',
  processTicket = 'process-list',
  followTicket = 'follow-list',
  createdTicket = 'created-list',
  publicTicket = 'public-list',
  workflowCategory = 'workflow-categories',
  ticketProcessing = 'ticket-processing',
  ticketInformation = 'ticket-information',
  ticketHistory = 'ticket-history',
  reporting = 'reporting',
  marketInsight = 'market-insight',
  candidate = 'candidate',
  jobPost = 'job-post',
  recruitmentEfficiency = 'recruitment-efficiency',
  customerStatistic = 'customer-statistic',
  leadA = 'A',
  leadB = 'B',
  leadC = 'C',
}
