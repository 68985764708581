import {ReportingBuilderModel} from "../../models/reporting-builder-model";
import {StateStatus} from "../../common/enums";
import {ConfigChart} from "../../models/market-insight";
import {Dictionary} from "@reduxjs/toolkit";

export class MarketInsightState {
    filters: Dictionary<ReportingBuilderModel> = {};
    loadFilterStatus: StateStatus = 'idle';
    configChart: ConfigChart = {
        items: [],
        title: '',
        type: ''
    };
    loadChartConfigStatus: StateStatus = 'idle';
    loading: boolean = false;
}