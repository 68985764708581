import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';

export const selectApplicationState = (state: AppState) => state.application;

export const selectApplicationEntities = createSelector(
    selectApplicationState,
    state => state.entities
);
export const selectApplicationIds = createSelector(
    selectApplicationState,
    state => state.ids
);
export const selectApplicationStatus = createSelector(
    selectApplicationState,
    state => state.status
);
export const selectApplicationSlugs = createSelector(
    selectApplicationState,
    state => state.slugs
);
export const selectApplicationFromSlug = (slug: string) => createSelector(
    selectApplicationState,
    state => state.entities[slug]
);
