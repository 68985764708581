import {all} from 'redux-saga/effects';
import authSagas from './features/auth/sagas';
import {commonSagas} from './common/actions';
import applicationSagas from "./features/application/sagas";
import customerDashboardSagas from './features/customer-activity/Dashboard/sagas';
import customerRealtimeSagas from './features/customer-activity/RealtimeOnline/sagas';
import customerActivityHistorySaga from "./features/customer-activity/CustomerActivityHistory/sagas";
import accountPermissionSagas from "./features/account-permission/sagas";
import callLogSagas from "./features/engagement/CallLog/sagas";
import callSagas from "./features/call/sagas";
import customerDetailSagas from './features/customer-profile/CustomerDetail/sagas';
import customerProfileSagas from './features/customer-profile/CustomerProfileList/sagas';
import noteSagas from './features/customer-profile/CustomerDetail/NoteHistory/sagas';
import employeeSagas from "./features/employee/sagas";
import departmentSagas from "./features/employee/department/saga";
import managementSagas from "./features/employee/management/saga";
import roleSagas from "./features/authorization/roles/sagas";
import accountSaga from "./features/account/sagas"
import purchaseHistorySagas from "./features/customer-profile/CustomerDetail/PurchaseHistory/sagas";
import paymentHistorySagas from "./features/customer-profile/CustomerDetail/PaymentHistory/sagas";
import serviceActivationSagas from "./features/customer-profile/CustomerDetail/ServiceActivationHistory/sagas";
import notificationSagas from "./features/notification/sagas";
import workflowListSagas from "./features/workflow/workflows/sagas";
import ticketListSagas from "./features/workflow/ticket/sagas";
import ticketDetailSagas from "./features/workflow/TicketDetail/sagas";
import processorSagas from "./features/workflow/processor/sagas";
import ticketLogSagas from "./features/workflow/TicketDetail/TicketLog/sagas";
import categorySagas from "./features/workflow/category/sagas";
import settingNotificationSagas from "./features/setting-notification/sagas";
import changeLogsSagas from "./features/change-logs/sagas";
import reportTemplateSagas from "./features/reporting/template/sagas";
import reportingSagas from "./features/reporting/report/sagas";
import backendSagas from "./common/backend-util/sagas";
import marketInsightSagas from './features/market-insight/sagas';
import customerStatisticSagas from "./features/customer-statistic/saga";

function* AppSagas() {
    yield all([
        commonSagas(),
        authSagas(),
        applicationSagas(),
        customerDashboardSagas(),
        customerRealtimeSagas(),
        customerActivityHistorySaga(),
        accountPermissionSagas(),
        callLogSagas(),
        callSagas(),
        customerDetailSagas(),
        customerProfileSagas(),
        noteSagas(),
        purchaseHistorySagas(),
        paymentHistorySagas(),
        serviceActivationSagas(),
        employeeSagas(),
        departmentSagas(),
        managementSagas(),
        roleSagas(),
        accountSaga(),
        workflowListSagas(),
        ticketListSagas(),
        ticketDetailSagas(),
        processorSagas(),
        notificationSagas(),
        ticketLogSagas(),
        categorySagas(),
        settingNotificationSagas(),
        changeLogsSagas(),
        reportingSagas(),
        reportTemplateSagas(),
        backendSagas(),
        marketInsightSagas(),
        customerStatisticSagas(),
    ]);
}

export default AppSagas;
