import {Dictionary} from "@reduxjs/toolkit";
import {NotificationSetterGroup} from "../../models/enums/notification-setter-group";

export interface EditNotificationSetting {
    notification_type: number,
    is_notification_mail_channel?: boolean,
    is_notification_fcm_channel?: boolean,
}

export class SettingNotificationState {
    loading: boolean = false;
    ids: number[] = [];
    group: Dictionary<NotificationSetterGroup> = {};
}

