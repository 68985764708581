import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {loadedListCustomerStatistic, loadingListCustomerStatistic} from "./reducer";
import {selectCustomerStatisticLoading} from "./selector";
import customerStatisticService from "./service";
import {Rest} from "../../common/models/rest";
import {safeCall} from "../../common/utils";
import {CustomerStatistic} from "../../models/customer-statistic";


const sagas = [
    takeLeading(loadingListCustomerStatistic, safeCall(function* ({payload}) {
        try {
            const loading = yield select(selectCustomerStatisticLoading);
            if (!loading) return;
            const response: Rest<CustomerStatistic[]> = yield call(customerStatisticService.list, payload);

            yield put(loadedListCustomerStatistic(response));
        } catch (e) {
            console.log(e);
        }
    })),
]

function* customerStatisticSagas() {
    yield all(sagas);
}

export default customerStatisticSagas;
