import {
    Children,
    cloneElement,
    isValidElement,
    PropsWithChildren,
    ReactElement, useCallback, useMemo,
} from "react";
import {PermissionValidation} from "../../core/auth/auth";
import {useVerifiedAccess} from "../hooks/useCanAccess";

interface NeedAuthorizeProps {
    permissions?: PermissionValidation[];
    fallback?: ReactElement;
}

export function useIsAuthorized() {
    const verified = useVerifiedAccess();

    return useCallback((permissions = []) => {
        return verified(permissions);
    }, [verified]);
}

const NeedAuthorize = ({children, permissions = [], fallback, ...props}: PropsWithChildren<NeedAuthorizeProps>) => {
    const checkAuthorized = useIsAuthorized();
    const isAuthorized = useMemo(() => checkAuthorized(permissions as any), [checkAuthorized, permissions]);

    return (
        <>
            {
                isAuthorized
                    ? Children.map(children, child => isValidElement(child)
                        ? cloneElement(child, props)
                        : child
                    )
                    : fallback
            }
        </>
    );
};

export default NeedAuthorize
