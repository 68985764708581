export enum NotificationType {
    TICKET_CANCELED_FOR_PROCESSING_EMPLOYEE = 1,
    TICKET_CREATED_FOR_FOLLOWING_EMPLOYEE = 2,
    NEW_TICKET_COMMENT_FOR_CURRENT_PROCESSOR = 3,
    NODE_PROCESSED = 4,
    TICKET_CANCELED_FOR_CREATED_EMPLOYEE = 5,
    TICKET_CANCELED_FOR_FOLLOWING_EMPLOYEE = 6,
    TICKET_CREATED_FOR_PROCESSING_EMPLOYEE = 7,
    TICKET_DONE_FOR_CREATED_EMPLOYEE = 8,
    TASK_NEED_PROCESS = 9,
    TASK_OUTDATED_FOR_CREATED_EMPLOYEE = 10,
    TASK_OUTDATED_FOR_FOLLOWING_EMPLOYEE = 11,
    TASK_OUTDATED_FOR_PROCESSING_EMPLOYEE = 12,
    TICKET_DONE_FOR_FOLLOWING_EMPLOYEE = 13,
    TICKET_NODE_HAS_NEW_ASSIGN = 14,
    REQUEST_FOLLOW_TICKET = 15,
    TICKET_HAS_NEW_ADDITIONAL_FOLLOWING_EMPLOYEE = 16,
    NEW_TICKET_COMMENT_FOR_CREATED_EMPLOYEE = 17,
    TICKET_NODE_HAS_NEW_ASSIGN_FOR_CREATED_EMPLOYEE = 18,
    TICKET_NODE_HAS_NEW_ASSIGN_FOR_FOLLOWING_EMPLOYEE = 19,
    TICKET_NODE_HAS_NEW_ASSIGN_FOR_ASSIGNED_EMPLOYEE = 20,
    TICKET_OUT_DATED_FOR_CREATED_EMPLOYEE = 21,
    TICKET_OUT_DATED_FOR_FOLLOWING_EMPLOYEE = 22,
    TICKET_OUT_DATED_FOR_CURRENT_PROCESSOR = 23,
    TICKET_COMMENT_TAG_NOTIFICATION_OF_CREATOR = 24,
    TICKET_COMMENT_TAG_NOTIFICATION_OF_CURRENT_PROCESSING = 25,
    TICKET_COMMENT_TAG_NOTIFICATION_OF_FOLLOWING = 26,
    TICKET_COMMENT_TAG_NOTIFICATION_OF_CAN_NOT_ACCESS_USER = 27,
}
