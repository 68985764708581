import AppState from "../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {DataNode} from "rc-tree/lib/interface";
import {Position} from "../../../models/position";
import {HomeOutlined, UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";
import {Department} from "../../../models/department";
import {DeleteDepartmentButton, EditDepartmentButton} from "./components/Form";
import {Tooltip} from "antd";
import i18next from "i18next";

const selectDepartmentState = (state: AppState) => state.employee.department;

export const selectDepartmentListLoading = createSelector(
    selectDepartmentState,
    state => state.listStatus === 'loading',
);

export const selectDepartmentList = createSelector(
    selectDepartmentState,
    // state => Object.keys(state.entities).map(key => state.entities[key]),
    state => state.entities,
);

export const selectDepartmentFormLoading = createSelector(
    selectDepartmentState,
    state => state.formStatus === 'loading',
);

export const selectDepartmentFormStatus = createSelector(
    selectDepartmentState,
    state => state.formStatus,
);

export interface SelectDepartmentAsTreeDataProps {
    withPosition?: boolean;
    withActions?: boolean;
    enablePositionOnly?: boolean;
}

export const selectDepartmentIsRoot = (id?: number) => createSelector(
    selectDepartmentState,
    state => !!state.entities[id ?? '']?.is_root
);

export const selectDepartmentFormErrors = createSelector(
    selectDepartmentState,
    state => state.errors
);

export const selectDepartmentAsTreeNodeData = (props?: SelectDepartmentAsTreeDataProps) => createSelector(
    selectDepartmentState,
    state => {
        const {withPosition, withActions, enablePositionOnly} = props ?? {};
        const root = state.entities[state.rootId ?? ''] ?? null;
        if (!root) return [] as DataNode[];
        const getAndMapChildren = (id: number) => {
            const children: DataNode[] = [];
            if (withPosition ?? true) {
                children.push(
                    ...state.posMapping[id]
                        ?.map(i => state.positions[i] as Position)
                        ?.map(po => ({
                            title: po?.name,
                            key: `position-${po?.id}`,
                            icon: po?.is_manager ? <UserOutlined /> : <UsergroupAddOutlined />,
                            isLeaf: true,
                            value: `position-${po?.id}`,
                            label: po?.name
                        } as DataNode)) ?? []
                );
            }
            children.push(
                ...state.childMapping[id]
                    ?.map(i => state.entities[i] as Department)
                    ?.map(department => {
                        const r = {
                            title: (
                                <>
                                    <span className={'mr-6'}>{department?.name}</span>
                                    {(withActions ?? true)
                                        ?
                                            <Tooltip zIndex={10} placement="top" title={i18next.t<string>('common:editDepartment')}>
                                                <span className={'mr-5'}>
                                                    <EditDepartmentButton id={department?.id} />
                                                </span>
                                            </Tooltip>
                                        :
                                        null
                                    }
                                    {
                                        (withActions ?? true)
                                        ?
                                            <Tooltip zIndex={10} placement="top" title={i18next.t<string>('common:deleteDepartment')}>
                                                <span>
                                                    <DeleteDepartmentButton id={department?.id} />
                                                </span>
                                            </Tooltip>
                                        :
                                            null
                                    }
                                </>
                            ),
                            key: `department-${department?.id}`,
                            icon: <HomeOutlined />,
                            value: `department-${department?.id}`,
                            label: department?.name,
                            disabled: enablePositionOnly
                        } as DataNode;

                        if (department?.id) {
                            const children = getAndMapChildren(department.id);
                            if (children) {
                                r.children = children;
                            }
                        }
                        return r;
                    }) ?? []
            );

            if (children.length) return children;
            return undefined;
        };

        const rootNode = {
            title: <span className={'mr-6'}>{root.name}</span>,
            key: `department-${root.id}`,
            icon: <HomeOutlined />,
            value: `department-${root.id}`,
            label: root.name,
            disabled: enablePositionOnly
        } as DataNode;
        const children = getAndMapChildren(root.id);

        if (children) rootNode.children = children;

        return [rootNode];
    }
);

export const selectDepartmentAsTreeNodeDataOnlyTitle = (props?: SelectDepartmentAsTreeDataProps) => createSelector(
    selectDepartmentState,
    state => {
        const {withPosition} = props ?? {};
        const root = state.entities[state.rootId ?? ''] ?? null;
        if (!root) return [] as DataNode[];

        const getAndMapChildren = (id: number) => {
            const children: DataNode[] = [];
            if (withPosition ?? true) {
                children.push(
                    ...state.posMapping[id]
                        ?.map(i => state.positions[i] as Position)
                        ?.map(po => ({
                            title: po?.name,
                            key: `position-${po?.id}`,
                            isLeaf: true,
                            value: `position-${po?.id}`,
                        } as DataNode)) ?? []
                );
            }

            children.push(
                ...state.childMapping[id]
                    ?.map(i => state.entities[i] as Department)
                    ?.map(department => {
                        const r = {
                            title: department?.name,
                            key: `department-${department?.id}`,
                            value: `department-${department?.id}`,
                        } as DataNode;

                        if (department?.id) {
                            const children = getAndMapChildren(department.id);
                            if (children) {
                                r.children = children;
                            }
                        }
                        return r;
                    }) ?? []
            );

            if (children.length) return children;
            return undefined;
        };

        const rootNode = {
            title: root.name,
            key: `department-${root.id}`,
            value: `department-${root.id}`,
        } as DataNode;

        const children = getAndMapChildren(root.id);
        if (children) rootNode.children = children;

        return [rootNode];
    }
);

export const selectDepartmentAsTreeNodeDataTitleWithIcon = (props?: SelectDepartmentAsTreeDataProps) => createSelector(
    selectDepartmentState,
    state => {
        const {withActions, enablePositionOnly} = props ?? {};
        const root = state.entities[state.rootId ?? ''] ?? null;
        if (!root) return [] as DataNode[];

        const getAndMapChildren = (id: number) => {
            const children: DataNode[] = [];
            children.push(
                ...state.posMapping[id]
                    ?.map(i => state.positions[i] as Position)
                    ?.map(po => ({
                        title: po?.name,
                        key: `position-${po?.id}`,
                        icon: <UserOutlined />,
                        isLeaf: true,
                        value: `position-${po?.id}`,
                        label: po?.name
                    } as DataNode)) ?? []
            );

            children.push(
                ...state.childMapping[id]
                    ?.map(i => state.entities[i] as Department)
                    ?.map(department => {
                        const r = {
                            title: (
                                <>
                                    <span className={'mr-4'}>{department?.name}</span>
                                </>
                            ),
                            key: `department-${department?.id}`,
                            icon: <HomeOutlined />,
                            value: `department-${department?.id}`,
                            label: department?.name,
                            disabled: enablePositionOnly
                        } as DataNode;

                        if (department?.id) {
                            const children = getAndMapChildren(department.id);
                            if (children) {
                                r.children = children;
                            }
                        }
                        return r;
                    }) ?? []
            );

            if (children.length) return children;
            return undefined;
        };

        const rootNode = {
            title: (
                <>
                    <span className={'mr-4'}>{root.name}</span>
                    {(withActions ?? true) ? <EditDepartmentButton id={root.id} /> : null}
                    {(withActions ?? true) ? <DeleteDepartmentButton id={root.id} /> : null}
                </>
            ),
            key: `department-${root.id}`,
            icon: <HomeOutlined />,
            value: `department-${root.id}`,
            label: root.name,
            disabled: enablePositionOnly
        } as DataNode;

        const children = getAndMapChildren(root.id);
        if (children) rootNode.children = children;

        return [rootNode];
    }
);

