import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChangeLogsState} from "./state";
import {Rest} from "../../common/models/rest";
import {ChangeLog} from "../../models/change-log";
import keyBy from "lodash/keyBy";


const slice = createSlice({
    name: 'changeLogs',
    initialState: {...new ChangeLogsState()},
    reducers: {
        loadAllChangeLogs(state: ChangeLogsState) {
            state.loadingStatus = true;
        },
        loadAllChangeLogsDone(state: ChangeLogsState, {payload}: PayloadAction<Rest<ChangeLog[]>>) {
            state.loadingStatus = false;
            state.changeLogsIds = payload.data.map(({id}) => (id));
            state.changeLogs = keyBy(payload.data, 'id');
        }
    }
});

export const {
    loadAllChangeLogs,
    loadAllChangeLogsDone
} = slice.actions;

const changeLogs = slice.reducer;

export default changeLogs;