import { Form } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import { useHistory, useLocation } from "react-router-dom";
import { isNil, omitBy } from "lodash";

export function useQuery(name?: string) {
    const search = useLocation().search;
    const a = useMemo(() => {
        const params = new URLSearchParams(search);
        if (name) {
            return params.get(name);
        }
        const result: any = {};
        params.forEach((value, key) => {
            if (!isNil(value)) {
                result[key] = value;
            }
        });
        return result;
    }, [name, search]);

    return a;
}

export const resetPage = (changes?: any) => {
    if (!changes) {
        return changes;
    }
    if (!changes?.page) {
        return {
            ...(changes ?? {}),
            page: 1,
        };
    }
    return changes;
};

interface Options<P> {
    effect?: (data: P) => any;
    toQuery?: typeof resetPage;
    toFormValues?: (data?: any) => any;
}

function useSyncQueryForm<P>({
                                 effect,
                                 toQuery,
                                 toFormValues,
                             }: Options<P> = {}): [FormInstance<P>, (changes?: any, forceLoad?: boolean) => void, () => void, any] {
    const [form] = Form.useForm<P>();
    const query = useQuery();
    const history = useHistory();

    const filterValues = useMemo(
        () => toFormValues?.(query) ?? query,
        [query, toFormValues],
    );

    const applyEffect = useCallback(() => {
        effect?.({
            ...filterValues,
        });
    }, [effect, filterValues]);

    const onValuesChange = useCallback(
        (changes?: any, forceLoad?: boolean) => {
            const oldQueryStr = history.location.search?.slice(1);
            const data = form.getFieldsValue(true);
            const realChange = resetPage(changes);
            const realData = toQuery
                ? toQuery({
                    ...data,
                    ...(realChange ?? {}),
                })
                : {
                    ...data,
                    ...(realChange ?? {}),
                };
            const newQueryStr = new URLSearchParams(omitBy({...realData }, isNil)).toString();
            if (forceLoad && oldQueryStr === newQueryStr) {
                applyEffect();
            } else {
                history.push({
                    pathname: history.location.pathname,
                    search: newQueryStr,
                });
            }
        },
        [form, history, toQuery, applyEffect],
    );

    useEffect(() => {
        form.setFieldsValue({...filterValues});
        applyEffect();
    }, [applyEffect, filterValues, form]);

    return [form, onValuesChange, applyEffect, filterValues];
}

export default useSyncQueryForm;
