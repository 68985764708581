import {selectProcessingJobs} from "../../common/backend-util/selectors";
import {useDispatch, useSelector} from "react-redux";
import {JobInfo, JobStatus, JobType} from "../../common/backend-util/service";
import {Button, Progress} from "antd";
import WithParamsLink from "../../common/components/WithParamsLink";
import React, {SyntheticEvent, useCallback} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {backendJobClose, backendJobRead} from "../../common/backend-util/store";

const JobResult = ({job, onClick, id}: { job: JobInfo, onClick?: any, id?: any }) => {
    if (job.status === JobStatus.FINISHED) {
        if (job.type === JobType.REPORT_JOB) {
            return <WithParamsLink
                target={"_blank"}
                to={`/reporting/reports/${job.output.id}`}
                data-id={id}
                onClick={onClick}
            >
                Xem báo cáo
            </WithParamsLink>
        }
    }
    return <></>
}

const JobInfos = () => {
    const jobs = useSelector(selectProcessingJobs);
    const dispatch = useDispatch();
    const onReadJob = useCallback((event: SyntheticEvent) => {
        const id = Number(event.currentTarget.getAttribute('data-id'));
        dispatch(backendJobRead(id));
    }, [dispatch]);

    const onCloseJob = useCallback((event: SyntheticEvent) => {
        const id = Number(event.currentTarget.getAttribute('data-id'));
        dispatch(backendJobClose(id));
    }, [dispatch]);
    return (
        <div className="ant-notification ant-notification-topRight" style={{right: '0px', top: '24px', bottom: 'auto'}}>
            <div>
                {
                    jobs.filter((j: JobInfo) => !j.is_close).map((e: JobInfo) => {
                        return <div className="ant-notification-notice ant-notification-notice-closable" key={e.id}>
                            <div className="ant-notification-notice-content">
                                <div className="" role="alert">
                                    <div className="ant-notification-notice-message">{e?.message}
                                        {
                                            e.status_str
                                                ? ' - ' + e.status_str
                                                : ''
                                        }
                                    </div>
                                    <div className="ant-notification-notice-description">
                                        <JobResult job={e} onClick={onReadJob} id={e.id}/>
                                        <Progress percent={e?.progress}/>
                                    </div>
                                </div>
                            </div>
                            <Button
                                type={'link'} htmlType={'button'}
                                className="ant-notification-notice-close"
                                data-id={e.id}
                                onClick={e.status === 'finished' ? onReadJob : onCloseJob}
                            >
                                <span className="ant-notification-notice-close-x">
                                    <span role="img" aria-label="close"
                                          className="anticon anticon-close ant-notification-notice-close-icon"
                                    ></span>
                                </span>
                                <CloseOutlined/>
                            </Button>
                        </div>
                    })
                }
            </div>
        </div>
    );

}
export default JobInfos;
