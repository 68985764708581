import {Dictionary} from "@reduxjs/toolkit";
import {StateStatus} from "../../../common/enums";
import {Workflow} from "../../../models/workflow";

export class WorkflowParams {

}

export class WorkflowState {
    loading: boolean = false;
    formLoading: boolean = false;
    ids:  number[] = [];
    tableData: Dictionary<Workflow> = {};
    message?: string = '';
    total?: number = 0;
    meta: PaginationType = {} as PaginationType;
    formStatus: StateStatus = 'idle';
    workflows: Workflow[] = [];
    status: StateStatus = 'idle';
    currentNodeForm?: any = {};
    currentTicketForm?: any = {};
    getNodeFormLoading: boolean = false;
}
