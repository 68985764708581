import {Dictionary} from "@reduxjs/toolkit";
import {ReportTemplate} from "../../../models/report-template";
import {StateStatus} from "../../../common/enums";

export class ReportTemplateState {
    listLoading: boolean = false;
    listReportTemplateIds: number[] = [];
    listReportTemplate: Dictionary<ReportTemplate> = {};
    meta: PaginationType = {} as PaginationType;
    saveTemplateStatus: StateStatus = 'idle';
    deleteTemplateStatus: StateStatus = 'idle';
}