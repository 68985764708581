import {all, select, put, takeLatest, call, takeEvery} from 'redux-saga/effects';
import {CustomerParams, CustomerRealtimeState} from './state';
import {selectCustomerRealtimeState, selectCustomerParams} from './selectors';
import customerDashboardService from './service';
import {
    refreshListCustomers,
    listCustomersLoaded,
    customerParamsChange,
    loadExpandedCustomers,
    listExpandedCustomersLoaded,
} from './reducer';
import {safeCall} from '../../../common/utils';
import {Rest} from '../../../common/models/rest';
import {pushQueryToRouter} from "../../../common/actions";
import {CustomerConnect} from "../../../models/customer-connect";

const sagas = [
    takeLatest(customerParamsChange, function* ({ meta }) {
        if (meta) {
            const params: CustomerParams = yield select(selectCustomerParams);
            yield put(pushQueryToRouter(params));
        }
    }),
    takeEvery(refreshListCustomers, safeCall(function* ({payload}) {
        const { status }: CustomerRealtimeState = yield select(selectCustomerRealtimeState);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<CustomerConnect[]> = yield call(customerDashboardService.list, payload);

        const state: CustomerRealtimeState = yield select(selectCustomerRealtimeState);

        yield put(listCustomersLoaded(response));
        for (const r of response.data) {
            if (state.expandedMapping[r.employer?.id]) {
                yield put(loadExpandedCustomers(r._id));
            }

        }
    })),
    takeEvery(loadExpandedCustomers, safeCall(function* ({payload}) {
        const response: Rest<CustomerConnect[]> = yield call(customerDashboardService.getRelativeData, payload);
        yield put(listExpandedCustomersLoaded(response));
    })),
];

function* customerDashboardSagas() {
    yield all(sagas);
}

export default customerDashboardSagas;
