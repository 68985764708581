import axios from "../../../common/axios";
import {RouterEnum} from "../../../config/router.enum";
import {BASE_URL} from "../../../config/properties";
import {Rest} from "../../../common/models/rest";
import {Department} from "../../../models/department";

const PREFIX = RouterEnum.departments;

export class DepartmentService {
    flat() {
        return axios.get(`${BASE_URL}/${PREFIX}/flat`);
    }

    show(id: number) {
        return axios.get<any, Rest<Department>>(`${BASE_URL}/${PREFIX}/${id}`)
    }

    store(formData: any) {
        return axios.post<any, Rest<Department>>(`${BASE_URL}/${PREFIX}`, formData);
    }

    update(id: number, formData: any) {
        return axios.put<any, Rest<Department>>(`${BASE_URL}/${PREFIX}/${id}`, formData);
    }

    delete(id: number) {
        return axios.delete<any, Rest<Department>>(`${BASE_URL}/${PREFIX}/${id}`);
    }

    importDepartment(formData: any) {
        return axios.post<any, Rest<any>>(`${BASE_URL}/${PREFIX}/import`, formData);
    }
}

const departmentService = new DepartmentService();

export default departmentService;
