import {Dictionary} from "@reduxjs/toolkit";
import {CustomerStatistic} from "../../models/customer-statistic";

export class CustomerStatisticState {
    loading: boolean = false;
    ids:  number[] = [];
    tableData: Dictionary<CustomerStatistic> = {};
    message: string = '';
    total: number = 0;
    meta: PaginationType = {} as PaginationType;
}