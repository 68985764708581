import styles from './index.module.scss';

const GlobalLoading = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <div>
                <img src="https://www.topcv.vn/v3/images/topcv-logo-4.png" alt=""/>
                <div className={styles.loader} style={{marginTop: '-51px', marginLeft: '155px'}}>Loading.Q..</div>
            </div>
        </div>
    )
};

export default GlobalLoading;
