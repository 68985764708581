import {createSelector} from "@reduxjs/toolkit";
import AppState from "../../App.state";


const selectSettingNotificationState = (state: AppState) => state.settingNotification;

export const selectNotificationSettingLoading = createSelector(
    selectSettingNotificationState,
    state => state.loading
)
