import {CRUDService} from '../../../common/service';

export class ActivityHistory extends CRUDService<any, any, any> {
    getNameSpace(): string {
        return 'customer-activity/tracking-histories';
    }
}

const customerActivityHistoryService = new ActivityHistory();

export default customerActivityHistoryService;
