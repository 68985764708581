import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PurchaseHistoryState} from "./state";
import {Rest} from "../../../../common/models/rest";
import {Purchase} from "../../../../models/purchase";
import {keyBy} from "lodash";

const slice = createSlice({
    initialState: {...new PurchaseHistoryState()},
    name: 'purchaseHistory',
    reducers: {
        refreshListPurchase(state: PurchaseHistoryState, {payload}: PayloadAction<any>) {
            state.status = 'loading'
        },
        listPurchaseLoaded(state: PurchaseHistoryState, {payload}: PayloadAction<Rest<Purchase[]>>) {
            state.meta = payload.meta;
            state.ids = payload.data.map(({id}) => (id));
            state.entities = keyBy(payload.data, 'id');
            state.status = 'idle';
        }
    }
})

export const {
    refreshListPurchase,
    listPurchaseLoaded
} = slice.actions;

const purchaseHistoryReducer = slice.reducer;

export default purchaseHistoryReducer;
