import { CRUDService } from '../../common/service';
import { Application } from '../../models/application';
import {RouterEnum} from "../../config/router.enum";
import customerActivities from './images/customer-activities.png';
import customerProfile from './images/customer-profile.png';
import callManagement from './images/call-management.png';
import workflow from './images/workflow.png';
import employee from './images/employee.png';
import reporting from './images/reporting.png';
import {Permission} from "../../models/enums/permission";
import {Resources} from "../../models/enums/resources";

export class ApplicationService extends CRUDService<Application> {
    getNameSpace(): string {
        return RouterEnum.application;
    }
    getDataApplicationState(): object {
        return {
            data: [
                {
                    id: 1,
                    name: "Hoạt động khách hàng",
                    slug: "customer-activities",
                    permissions: [
                        // {permission: Permission.READ, resource: Resources.ACTION_REALTIME},
                        // {permission: Permission.READ, resource: Resources.ACTIVITY_HISTORY},
                        {permission: Permission.READ, resource: Resources.MARKET_INSIGHT},
                        // {permission: Permission.READ, resource: Resources.CUSTOMER_STATISTICS},
                    ],
                    image: customerActivities,
                    created_at: "2021-10-20 00:00:00",
                    updated_at: "2021-10-20 00:00:00",
                },
                {
                    id: 2,
                    name: "Hồ sơ khách hàng",
                    slug: "customer-profile",
                    permissions: [
                        {permission: Permission.READ, resource: Resources.CUSTOMER_PROFILE},
                    ],
                    image: customerProfile,
                    created_at: "2021-10-20 00:00:00",
                    updated_at: "2021-10-20 00:00:00",
                },
                // {
                //     id: 3,
                //     name: "Quản lý phiên chat",
                //     slug: "chat-management",
                //     image: callManagement,
                //     created_at: "2021-10-20 00:00:00",
                //     updated_at: "2021-10-20 00:00:00",
                // },
                {
                    id: 4,
                    name: "Quản lý cuộc gọi",
                    slug: "call-management",
                    permissions: [
                        {permission: Permission.READ, resource: Resources.ENGAGE_CALL},
                    ],
                    image: callManagement,
                    created_at: "2021-10-20 00:00:00",
                    updated_at: "2021-10-20 00:00:00",
                },
                {
                    id: 5,
                    name: "Quản lý nhân sự",
                    slug: "employee",
                    permissions: [
                        {permission: Permission.READ, resource: Resources.EMPLOYEE},
                        {permission: Permission.READ, resource: Resources.ACCOUNT},
                        {permission: Permission.READ, resource: Resources.ORGANIZATION_DEPARTMENT},
                    ],
                    image: employee,
                    created_at: "2021-10-20 00:00:00",
                    updated_at: "2021-10-20 00:00:00",
                },
                {
                    id: 6,
                    name: "Workflow",
                    slug: "workflow/tickets",
                    permissions: [
                        {permission: Permission.READ, resource: Resources.WORKFLOW},
                        {permission: Permission.READ, resource: Resources.TICKET},
                    ],
                    image: workflow,
                    created_at: "2021-10-20 00:00:00",
                    updated_at: "2021-10-20 00:00:00",
                },
                {
                    id: 7,
                    name: "Báo cáo",
                    slug: "reporting",
                    permissions: [
                        {permission: Permission.READ, resource: Resources.REPORT_INSTANCE},
                        {permission: Permission.READ, resource: Resources.REPORT_TEMPLATE},
                    ],
                    image: reporting,
                    created_at: "2021-10-20 00:00:00",
                    updated_at: "2021-10-20 00:00:00",
                },
            ]
        };
    }
}

const applicationService = new ApplicationService();

export default applicationService;
