import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";
import {CRUDService} from "../../common/service";
import {Account} from "../../models/account";
import {RouterEnum} from "../../config/router.enum";


export class AccountListService extends CRUDService<Account>{
    getAccountList() {
        return axios.get<Rest<any>>(`${BASE_URL}/account/get-accounts`);
    }

    getNameSpace(): string {
        return RouterEnum.account;
    }
}

const accountListService = new AccountListService();

export default accountListService;
