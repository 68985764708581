import {all, call, debounce, put, select, takeEvery, takeLeading} from "redux-saga/effects";
import {safeCall} from "../../../common/utils";
import {Rest} from "../../../common/models/rest";
import {
    changingWorkflowStatus,
    getAllWorkflows, getAllWorkflowsDone,
    refreshWorkflowList,
    workflowListLoaded,
    workflowStatusChanged,
    savingWorkflow,
    workflowSaved,
    getFormById,
    getNodeFormByIdDone, getTicketFormByIdDone
} from "./reducer";
import {selectStatusWorkflows, selectWorkflowListLoading} from "./selector";
import {Workflow} from "../../../models/workflow";
import workflowListService from "./service";
import {message} from "antd";
import i18next from "i18next";
import {StateStatus} from "../../../common/enums";
import history from "../../../config/history";
import {FlowElement} from "../Diagram/type";
import {NodeType} from "../../../models/enums/node-type";

const sagas = [
    takeLeading(refreshWorkflowList, safeCall(function* ({payload}) {
        const loading: boolean = yield select(selectWorkflowListLoading);
        if (!loading) return;

        const result: Rest<Workflow[]> = yield call(workflowListService.list, payload);

        yield put(workflowListLoaded(result));
    })),
    takeLeading(changingWorkflowStatus, safeCall(function* ({payload}) {
        const loading: boolean = yield select(selectWorkflowListLoading);

        if (!loading) return;
        const result: Rest<Workflow> = yield call(workflowListService.changeWorkflowStatus, payload);
        if (result) {
            yield put(workflowStatusChanged(payload));
        }
        message.success(i18next.t<string>('common:changeStatusSuccess'));
    })),
    debounce(200, getAllWorkflows, safeCall(function* ({payload}) {
        const status: StateStatus = yield select(selectStatusWorkflows);
        if (status !== 'loading') {
            return;
        }
        const res: Rest<Workflow[]> = yield call(workflowListService.getAllWorkflow, payload);
        yield put(getAllWorkflowsDone(res));
    })),
    takeEvery(savingWorkflow, safeCall(function* ({payload}) {
        try {
            if (payload?.nodes) {
                const completedNode: FlowElement[] | undefined = payload.nodes.filter((node: FlowElement) => node.type === NodeType.DONE);
                if (!completedNode || completedNode.length === 0) {
                    message.success(i18next.t<string>('common:updateWorkflowErrorMissingCompleteNode'));
                    yield put(workflowSaved());
                    return;
                }
            }
            let savedWorkflow: Rest<Workflow> | undefined = undefined;
            if (payload.id) {
                savedWorkflow = yield call(workflowListService.edit, payload.id, payload);
                message.success(i18next.t<string>('common:updateWorkflowSuccess'));
            } else {
                savedWorkflow = yield call(workflowListService.create, payload);
                message.success(i18next.t<string>('common:addWorkflowSuccess'));
                history.push(`./${savedWorkflow?.data?.id}`);
            }

            yield put(workflowSaved());
        } catch (e) {
            yield put(workflowSaved());
        }
    })),
    takeLeading(getFormById, safeCall(function* ({payload}) {
        const res: Rest<any> = yield call(workflowListService.getFormByNodeId, payload.id, payload.isFormNode);
        if (payload.isFormNode) {
            yield put(getNodeFormByIdDone(res))
            return;
        }
        yield put(getTicketFormByIdDone(res))
    }))
];

function* workflowListSagas() {
    yield all(sagas);
}

export default workflowListSagas;
