import {FormInstance} from "antd/lib/form/hooks/useForm";
import {useSelector} from "react-redux";
import useFormSyncErrors, {KeyHandler} from "../hooks/use-form-sync-errors";
import {BackendState} from "./state";
import {JobInfo} from "./service";
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NamePath} from "rc-field-form/es/interface";
import {cloneDeep} from "lodash";

const slice = createSlice({
    name: 'backend-state',
    initialState: {...new BackendState()},
    reducers: {
        errorsReceived(state, {payload}: PayloadAction<any>) {
            return {
                ...state,
                errors: payload
            }
        },
        jobReceived(state: BackendState, {payload}: PayloadAction<any>) {
        },
        jobRefreshed(state: BackendState, {payload}: PayloadAction<JobInfo[]>) {
            const cloneJobs = cloneDeep(state.jobs);
            state.jobs = payload.map((job, idx) => {
                const oldJob = cloneJobs.find((j) => j.id === job.id);

                return job.id === oldJob?.id ? {...job, is_close: oldJob?.is_close} : {...job};
            })
        },
        jobRead(state: BackendState, {payload}: PayloadAction<any>) {
            state.jobs.splice(state.jobs.findIndex((e: JobInfo) => e.id === payload), 1)
        },
        jobClose(state: BackendState, {payload}: PayloadAction<any>) {
            const cloneJobs = cloneDeep(state.jobs);
            state.jobs = cloneJobs.map((job: JobInfo) => {
                return payload === job.id ? {...job, is_close: true} : {...job};
            })
        },
        clearErrors(state: BackendState, {payload}: PayloadAction<NamePath | undefined>) {
            if (payload && state.errors) {
                Object.keys(state.errors).forEach(key => {
                    if (key.includes(payload as string)) {
                        state.errors[key] = [];
                    }
                })
            } else {
                state.errors = undefined;
            }
        },
        refreshTicketUnread(state: BackendState, {payload}: PayloadAction<any>) {
        },
        refreshTicketUnreadDone(state: BackendState, {payload}: PayloadAction<any>) {
            state.countTicketUnread = payload;
        }
    },
});

export const backendValidationErrorsReceived = slice.actions.errorsReceived;

export const backendJobReceived = slice.actions.jobReceived;

export const backendJobRefreshed = slice.actions.jobRefreshed;
export const backendJobRead = slice.actions.jobRead;
export const clearBackendErrors = slice.actions.clearErrors;
export const backendJobClose = slice.actions.jobClose;

export const backendRefreshTicketUnread = slice.actions.refreshTicketUnread;

export const backendRefreshTicketUnreadDone = slice.actions.refreshTicketUnreadDone;


const backendValidationErrorReducer = slice.reducer;

export default backendValidationErrorReducer;

export let selectBackendErrors: (state: any) => any = (state: any) => state;

export const setSelectBackendErrors = (fn: (state: any) => any) => {
    selectBackendErrors = fn;
};

const internalSelectBackendErrors = () => createSelector(
    selectBackendErrors,
    state => state.errors,
);

export const useFormSyncWithBackendValidationErrors = (form: FormInstance, enable = true, keyHandler?: KeyHandler) => {
    const errors = useSelector(internalSelectBackendErrors());
    useFormSyncErrors(form, errors, enable, keyHandler);
}

