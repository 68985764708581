import {all, call, debounce, put, select, takeLeading} from "redux-saga/effects";
import {safeCall} from "../../../common/utils";
import {Rest} from "../../../common/models/rest";
import ticketDetailService from "./service";
import {
    commentLoaded,
    refreshComment,
    refreshTicket,
    ticketLoaded,
    refreshWorkflow,
    workflowLoaded,
    creatingTicket,
    refreshNodeDetail,
    setCurrentNodeDetail,
    completingAction,
    nodeCompleted,
    updatingTask,
    createComment,
    createCommentDone,
    assigningTicketNode,
    cancelTicket,
    cancelTicketDone,
    actionCompleted,
    taskUpdated,
    ticketCreateFailed,
    completingActionFail,
    updateTaskFail,
    cancelTicketFail,
    assigningTicketFail,
    settingReceiveNotification,
    settingReceiveNotificationFail,
    settingReceiveNotificationDone,
    updateFollowing,
    updateFollowingDone,
    updateFollowingFail,
    refreshMentionTicket,
    refreshMentionTicketDone,
    refreshMentionTicketFail,
    refreshTicketFail,
    refreshTicketLite,
    requestingFollow,
    requestFollowDone,
    assignMultipleTicketNode,
    assignMultipleTicketNodeDone,
    assignMultipleTicketNodeFail,
    refreshGetCurrentNode,
    gotCurrentNode,
    resetGetCurrentNodeStatus,
    resetStatus,
    getFormResultCurrentNode,
    getFormResultCurrentNodeDone, refreshTimeline, refreshTimelineDone,
} from "./reducer";
import {Ticket} from "../../../models/ticket";
import {Comment} from "../../../models/comment";
import {TicketDetailState} from "./state";
import {selectCreateCommentStatus, selectTicketDetailState} from "./selectors";
import {Workflow} from "../../../models/workflow";
import {message} from "antd";
import i18next from "i18next";
import history from "../../../config/history";
import ticketListService from "../ticket/service";
import {NodeModel} from "../../../models/node-model";
import {listTicketTimelineLoaded} from "./TicketLog/reducer";
import {TicketLog} from "../../../models/ticket-log";
import ticketLogService from "./TicketLog/service";
import {selectAutoLoadingTicketTimeline} from "./TicketLog/selectors";
import {TicketLogType} from "../../../models/enums/ticket-log-type";

const sagas = [
    takeLeading(refreshTicket, safeCall(function* ({payload}) {
        try {
            const {status, ticket}: TicketDetailState = yield select(selectTicketDetailState);
            if (status !== 'loading') {
                return;
            }
            if (ticket?.nodes && ticket?.nodes?.length) {
                yield put(resetStatus());
                return;
            }
            const response: Rest<Ticket> = yield call(ticketDetailService.single, payload.id);
            yield put(ticketLoaded(response));
        } catch (error) {
            yield put(refreshTicketFail((error as any)?.response?.data));
        }
    })),
    takeLeading(refreshTicketLite, safeCall(function* ({payload}) {
        try {
            const {statusLite}: TicketDetailState = yield select(selectTicketDetailState);
            if (statusLite !== 'loading') {
                return;
            }
            const response: Rest<Ticket> = yield call(ticketDetailService.getTicketDetailLite, payload.id);
            yield put(ticketLoaded(response));
        } catch (error) {
            yield put(refreshTicketFail((error as any)?.response?.data));
        }
    })),
    takeLeading(refreshGetCurrentNode, safeCall(function* ({payload}) {
        try {
            const {getCurrentNodeStatus, currentNode, currentNodeId}: TicketDetailState = yield select(selectTicketDetailState);
            if (getCurrentNodeStatus !== 'loading') {
                return;
            }
            if (currentNode && currentNodeId) {
                yield put(resetGetCurrentNodeStatus());
                return;
            }
            const response: Rest<Ticket> = yield call(ticketDetailService.getCurrentProcessingNode, payload.id);
            yield put(gotCurrentNode(response));
        } catch (error) {
            yield put(resetGetCurrentNodeStatus());
        }
    })),
    takeLeading(refreshComment, safeCall(function* ({payload}) {
        const {commentStatus}: TicketDetailState = yield select(selectTicketDetailState);
        if (commentStatus !== 'loading') {
            return;
        }
        const response: Rest<Comment[]> = yield call(ticketDetailService.getComments, payload);
        yield put(commentLoaded(response));
    })),
    takeLeading(refreshWorkflow, safeCall(function* ({payload}) {
        const {workflowStatus}: TicketDetailState = yield select(selectTicketDetailState);
        if (workflowStatus !== 'loading') {
            return;
        }
        const response: Rest<Workflow> = yield call(ticketDetailService.getWorkflow, payload);
        yield put(workflowLoaded(response));
    })),
    takeLeading(creatingTicket,function* ({payload}) {
        const {creating}: TicketDetailState = yield select(selectTicketDetailState);
        if (creating !== 'loading') {
            return;
        }
        try {
            const response: Rest<Ticket> = yield call(ticketDetailService.create, payload);
            yield put(ticketLoaded(response));
            message.success(i18next.t<string>('common:addTicketSuccess'));
            history.push(`./${response.data.id}`)
        } catch (e) {
            yield put(ticketCreateFailed());
        }
    }),
    takeLeading(refreshNodeDetail, safeCall(function* ({payload}) {
        const {nodeDetailStatus, nodes, processingNodes}: TicketDetailState = yield select(selectTicketDetailState);
        if (nodeDetailStatus !== 'loading') {
            return;
        }
        const nod = processingNodes?.find(n => n?.id?.toString() === payload?.id?.toString());
        if (nod) {
            yield put(setCurrentNodeDetail(nod));
            return;
        }
        if (nodes[payload.id]) {
            yield put(setCurrentNodeDetail(nodes[payload.id]));
            return;
        }
    })),
    takeLeading(completingAction, function* ({payload}) {
        try {
            let response: Rest<Ticket>|undefined;
            if (!payload.isListTicket) {
                response = yield call(ticketDetailService.updateAndNextTask, payload);
            } else {
                response = yield call(ticketDetailService.updateTaskAndNextGetProcessingNode, payload);
            }
            yield put(refreshTimeline(payload));

            if (response) {
                yield put(actionCompleted(response));
                message.success(i18next.t<string>('common:nextTaskSuccess'));
            }
        } catch (e) {
            yield put(completingActionFail(payload.id));
        }

    }),
    takeLeading(updatingTask, function* ({payload}) {
        try {
            const response: Rest<Ticket> = yield call(ticketDetailService.updateTask, payload);

            if (!payload.isListTicket) {
                yield put(getFormResultCurrentNode(response?.data));
            }

            yield put(refreshTimeline(payload));

            yield put(taskUpdated(response));
            if (!payload.nextTo) {
                message.success(i18next.t<string>('common:updateNodeSuccess'));
            }
        } catch (e) {
            yield put(updateTaskFail(payload));
        }

    }),
    takeLeading(refreshTimeline, function* ({payload}) {
        const autoLoading: boolean = yield select(selectAutoLoadingTicketTimeline);
        if (autoLoading && payload.ticketId) {
            const ticketTimeline: Rest<TicketLog[]> = yield call(ticketLogService.getLogByID, {
                types: [TicketLogType.TICKET_RESULT_UPDATED, TicketLogType.TICKET_NODE_ASSIGN_UPDATED, TicketLogType.TICKET_NODE_UPDATED_AND_NEXT],
                important: false,
                ticketId: payload.ticketId,
                get_all: true,
            });
            yield put(listTicketTimelineLoaded(ticketTimeline));
        }
        yield put(refreshTimelineDone());
    }),
    takeLeading(createComment,function* ({payload}) {
        const {createCommentStatus}: TicketDetailState = yield select(selectCreateCommentStatus);
        if (createCommentStatus === 'loading') {
            return;
        }
        try {
            const response: Rest<Comment> = yield call(ticketDetailService.createComment, payload);
            yield put(createCommentDone(response));
            message.success(i18next.t<string>('common:addCommentSuccess'));
        } catch (e) {
            yield put(createCommentDone());
        }
    }),
    takeLeading(assigningTicketNode, function* ({payload}) {
        try {
            const response: Rest<NodeModel> = yield call(ticketDetailService.assignTicketNode, payload);
            yield put(nodeCompleted(response));
            message.success(i18next.t<string>('common:assignTicketNodeSuccess'));
        } catch (e) {
            yield put(assigningTicketFail(payload.id));
        }

    }),
    takeLeading(cancelTicket, safeCall(function* ({payload}) {
        try {
            const response: Rest<any> = yield call(ticketDetailService.cancelTicket, payload);
            yield put(cancelTicketDone(response));
            message.success(i18next.t<string>('common:cancelTicketSuccess'));
            history.push(`./`);
        } catch (e) {
            yield put(cancelTicketFail());
        }

    })),
    takeLeading(settingReceiveNotification, safeCall(function* ({payload}) {
        try {
            const response: Rest<Ticket> = yield call(ticketDetailService.setReceiveNotification, payload);
            yield put(settingReceiveNotificationDone(response));
            message.success(i18next.t<string>('common:success'));
        } catch (e) {
            yield put(settingReceiveNotificationFail());
        }

    })),
    takeLeading(updateFollowing, safeCall(function* ({payload}) {
        try {
            let response: Rest<Ticket>;
            if (payload.is_accept) {
                response = yield call(ticketDetailService.acceptRequestFollow, payload);
            } else {
                response = yield call(ticketDetailService.updateFollowing, payload);
            }
            yield put(updateFollowingDone(response));
            message.success(i18next.t<string>('common:success'));
        } catch (e) {
            yield put(updateFollowingFail());
        }

    })),
    debounce(500, refreshMentionTicket, safeCall(function* ({payload}) {
        try {
            const response: Rest<Ticket[]> = yield call(ticketListService.listMyTicketsLite, payload);
            yield put(refreshMentionTicketDone(response));
        } catch (e) {
            yield put(refreshMentionTicketFail());
        }
    })),
    takeLeading(requestingFollow, safeCall(function* ({payload}) {
        try {
            yield call(ticketDetailService.requestFollow, payload);
            yield put(requestFollowDone());
            message.success(i18next.t<string>('ticket:message.request_access_success'));
        } catch (e) {
            yield put(requestFollowDone());
        }
    })),
    takeLeading(assignMultipleTicketNode, safeCall(function* ({payload}) {
        try {
            const response: Rest<NodeModel[]> = yield call(ticketDetailService.assignMultipleTicketNode, payload.data);
            yield put(assignMultipleTicketNodeDone(response));
            if (payload.ticketId) {
                yield put(refreshTicketLite({id: payload.ticketId}));
            }
            message.success(i18next.t<string>('common:assignTicketNodeSuccess'));
        } catch (e) {
            yield put(assignMultipleTicketNodeFail());
            message.error(i18next.t<string>('common:assignTicketNodeFail'));
        }
    })),
    takeLeading(getFormResultCurrentNode, safeCall(function* ({payload}) {
        let response: Rest<any> = yield call(ticketDetailService.getFormResultNode, payload.id);
        if (response.data) {
            response.data.nodeId = payload.id;
        }
        yield put(getFormResultCurrentNodeDone(response));
    })),
];

function* ticketDetailSagas() {
    yield all(sagas);
}

export default ticketDetailSagas;
