import App from './App';
import {Router} from 'react-router-dom';
import history from './config/history';
import {Provider} from 'react-redux';
import store from './config/store';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import {RecoilRoot} from 'recoil';
import vi from 'antd/es/locale/vi_VN';
import './config/i18next';
import './index.less';
import './index.scss';
import hookGuard from 'react-hook-guard';
import auth from "./features/auth/auth";
import {library} from "@fortawesome/fontawesome-svg-core";
import fontAwesomeIcons from "./utils/fontAwesomeIcons";
import HealthCheck from "./common/components/HealthCheck";
import GlobalLoading from "./common/components/GlobalLoading/index";
import Forbidden from "./features/Forbidden";
import AntdConfigProvider from "./config/antd";
import { Suspense } from 'react';
import {APP_PREFIX, ENVIRONMENT, SENTRY_URL} from "./config/properties";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

if (vi.DatePicker?.lang) vi.DatePicker.lang.locale = 'vi';
//@ts-ignore
library.add(fontAwesomeIcons);


hookGuard.config({
    SuspenseFallback: GlobalLoading,
    CantActivateFallback: Forbidden,
});

// Go to last url
let lastUrl = localStorage.getItem("LAST_URL");
if (lastUrl && (window.location.pathname === `${APP_PREFIX}/` || window.location.pathname === APP_PREFIX) && lastUrl !== '/') {
    window.location.href = APP_PREFIX + lastUrl;
}
// eslint-disable-next-line no-restricted-globals
if (location.pathname === `${APP_PREFIX}/health`) {
    const container = document.getElementById('root')!;
    const root = createRoot(container);
    root.render(
        <HealthCheck/>,
    )
} else {

    auth.init().then((auth) => {
        if (SENTRY_URL) {
            Sentry.init({
                dsn: SENTRY_URL,
                integrations: [new BrowserTracing()],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
                environment: ENVIRONMENT,
            });
        }
        const container = document.getElementById('root')!;
        const root = createRoot(container);

        // auth.runRefreshTokenInterval(10)
        root.render(
            <Suspense fallback={<GlobalLoading />}>
                <I18nextProvider i18n={i18next}>
                    <AntdConfigProvider>
                        <Provider store={store}>
                            <Router history={history}>
                                <RecoilRoot>
                                    <App/>
                                </RecoilRoot>
                            </Router>
                        </Provider>
                    </AntdConfigProvider>
                </I18nextProvider>
            </Suspense>
        );
    })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    reportWebVitals();
}

