import {Call} from "./Call";
import JsSIP from 'jssip';
import {
    IncomingRTCSessionEvent,
} from "jssip/lib/UA";
import {IceCandidateEvent, IncomingEvent, RTCSession} from "jssip/lib/RTCSession";
import {DTMF_TRANSPORT} from "jssip/lib/Constants";
const md5 = require('md5');

export class Callio extends Call {
    private phone: JsSIP.UA | null = null;
    public call: RTCSession | null = null;
    public onPhoneRegistered: Function | undefined;
    public onPhoneDisconnected: Function | undefined;
    public onCalling: Function | undefined;
    public onCallProgress: Function | undefined;
    public onAcceptIncomingCall: Function | undefined;
    private ringtone: any;
    private callOptions = {
        mediaConstraints: {
            audio: true,
            video: false
        },
        pcConfig: {
            iceServers: [
                {
                    urls: ["stun:stun-vn-01.phonenet.io", "stun:stun.l.google.com:19302"]
                },
            ],
        }
    };

    public mute() {
        if (this.call) {
            this.call.mute()
            return true
        }
        return false
    }

    public unMute() {
        if (this.call) {
            this.call.unmute()
            return true
        }
        return false
    }

    public answer() {
        this.call!.answer(this.callOptions);
        // @ts-ignore
        this.call!.connection.onaddstream = function (e: any) {
            const remoteAudio = document.createElement('audio');
            remoteAudio.srcObject = e.stream;
            remoteAudio.play();
        };
    }
    public terminateCall() {
        if (!this.call) {
            return;
        }
        try {
            this.call.terminate();
        } catch (e) {
            console.debug(e)
        }
        this.call = null;
    }
    public callToNumber(number: string) {
        if (!this.phone) {
            this.onCantCall?.();
            return false;
        }
        this.phone?.call(number, this.callOptions);
        // @ts-ignore
        this.call.connection.onaddstream = function (e: any) {
            const remoteAudio = document.createElement('audio');
            remoteAudio.srcObject = e.stream;
            remoteAudio.play();
        };
        return true;
    }
    public sendDTMF(val: string) {
        if (this.call) {
            this.call.sendDTMF(val, {
                transportType: DTMF_TRANSPORT.RFC2833
            })
            return true
        }
        return false
    }

    public setConfig(config: any): this {
        config.ext = config.internal_number;
        return super.setConfig(config);
    }

    public init() {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
            //Kết nối webrtc tới phonenet
            const contact_uri = 'sip:' + this.config?.ext + '@' + Math.random().toString(36).substring(2, 15) + '.invalid;transport=ws';
            this.phone = new JsSIP.UA({
                sockets: [new JsSIP.WebSocketInterface('wss://' + this.config?.host + ':7443')],
                uri: 'sip:' + this.config?.ext + '@' + this.config?.host,
                realm: this.config?.host,
                ha1: md5(this.config?.ext + ':' + this.config?.host + ':' + this.config?.password),
                contact_uri: contact_uri,
                // session_timers: false,
            });
            this.phone.on('registered', (e) => {
                this.ringtone?.pause();
                if (this.onPhoneRegistered) {
                    this.onPhoneRegistered(e);
                }
            });
            this.phone.on('disconnected', () => {
                this.ringtone?.pause();
                if (this.onPhoneDisconnected) {
                    this.onPhoneDisconnected();
                }
            });
            this.phone.start();
            this.phone.on("newRTCSession", (event: IncomingRTCSessionEvent | IncomingRTCSessionEvent) => {
                this.ringtone?.pause();
                if (this.call) {
                    event.session.terminate();
                    return;
                }
                this.call = event.session;
                if (this.onCalling) {
                    this.onCalling(event, this.call);
                }
                this.call.on("icecandidate", (e: IceCandidateEvent) => {
                    setTimeout(() => {
                        e.ready();
                    }, 10000);
                });
                this.call.on("progress", (e: IncomingEvent | IncomingEvent) => {
                    if (this.call?.direction === 'incoming') {
                        if (!this.ringtone) {
                            this.ringtone = document.createElement('audio');
                            this.ringtone.setAttribute('loop', '');
                            this.ringtone.setAttribute('muted', 'muted');
                            this.ringtone.setAttribute('autoplay', '');
                            // document.body.appendChild(this.ringtone);
                            // @ts-ignore
                            // document.getElementsByTagName('video').muted = false;
                            this.ringtone.src = this.config?.ringtone;

                        }
                        this.ringtone.currentTime = 0;
                        this.ringtone.play();
                    }
                    if (this.onCallProgress) {
                        this.onCallProgress(e, this.call);
                    }
                });
                this.call.on("accepted", () => {
                    this.ringtone?.pause();
                    if (this.onAccepted) {
                        this.onAccepted();
                    }
                });
                this.call.on("confirmed", () => {
                    this.ringtone?.pause();
                    if (this.onConfirmed) {
                        this.onConfirmed();
                    }
                });
                this.call.on("ended", () => {
                    this.ringtone?.pause();
                    if (this.onEnded) {
                        this.onEnded();
                    }
                });
                this.call.on("failed", (e) => {
                    this.ringtone?.pause();
                    this.call = null;
                    if (this.onFailed) {
                        this.onFailed();
                    }
                });
            });
        }).catch(() => {
            this.onGetMediaFailed?.();
        });
    }
}