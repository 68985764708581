export enum TicketLogType {
     TICKET_CREATED = 1,
     TICKET_COMMENT_CREATED = 2,
     TICKET_NODE_PROCESSED = 3,
     TICKET_NODE_HAS_NEW_ASSIGN = 4,
     TICKET_DONE = 5,
     TICKET_CANCELED = 6,
     TICKET_RESULT_UPDATED = 7,
     TICKET_STOP_AT_TASK = 8,
     TICKET_ADDITIONAL_EMPLOYEE_UPDATED = 9,
     TASK_OUTDATED = 10,
     TICKET_NODE_ASSIGN_UPDATED= 12,
     TICKET_NODE_UPDATED_AND_NEXT = 14,
}