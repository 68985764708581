import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    accountListLoaded,
    gettingAccountList, gettingAllAccountList,
} from "./reducer";
import {safeCall} from "../../common/utils";
import {AccountListState} from "./state";
import accountListService from "./service";
import {selectAccountListLoading} from "./selectors";
import {Rest} from "../../common/models/rest";
import {Account} from "../../models/account";


const sagas = [
    takeLeading(gettingAccountList, safeCall(function* ({payload}) {
        const { loading } : AccountListState = yield select(selectAccountListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Account[]> = yield call(accountListService.getAccountList);
        yield put(accountListLoaded(response.data));
    })),
    takeLeading(gettingAllAccountList, safeCall(function* ({payload}) {
        const { loading } : AccountListState = yield select(selectAccountListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Account[]> = yield call(accountListService.list);
        yield put(accountListLoaded(response.data));
    })),
];

function* accountSagas() {
    yield all(sagas);
}

export default accountSagas;
