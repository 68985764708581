import {Dictionary} from "@reduxjs/toolkit";
import {CustomerConnect} from "../../../models/customer-connect";
import {StateStatus} from "../../../common/enums";
import {OnlineStatus} from "../../../models/enums/online-status";
import {OnlineBarNumber} from "../../../models/enums/online-bar-number";

export class CustomerOnlineBarParams {
    online_only?: number = OnlineStatus.ONLINE_ONLY;
    limit?: number = OnlineBarNumber.REQUEST_NUMBER;
    page?: number = 1;
}

export class CustomerDashboardState {
    ids: string[] = [];
    onlineBarParams = new CustomerOnlineBarParams();
    onlineBarEntities: Dictionary<CustomerConnect> = {};
    dataOnlineBar: [] = [];
    onlineBarStatus: StateStatus = 'idle';
    onlineBarMeta: PaginationType = {} as PaginationType;
}
