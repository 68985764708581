export const convertMinutesToDay = (minutes: string | number) => {
    const days: number = Math.floor(Number(minutes) / (24*60));
    const temp: number = Number(minutes) % (24*60);
    const hours: number = Math.floor(temp / 60);
    const min: number = temp % 60;

    return (days < 10 ? '0' + days : days) + ':' + (hours < 10 ? '0' + hours : hours) + ':' + (min < 10 ? '0' + min : min);
}

export const convertDayToMinutes = (day: string) => {
    const rawDay = day?.split(':') ?? [];
    return rawDay.length === 3 ? Number(rawDay[0]) * 24 * 60 + Number(rawDay[1]) * 60 + Number(rawDay[2]) : 0;
}

export const convertDayToString = (day: string) => {
    const rawDay = day?.split(':') ?? [];
    return rawDay.length === 3 ? `${Number(rawDay[0])} Ngày, ${Number(rawDay[1])} Giờ, ${Number(rawDay[2])} Phút` : '';
}