import {Dictionary} from "@reduxjs/toolkit";
import {Note} from "../../../../models/note";
import {StateStatus} from "../../../../common/enums";

export class NoteState {
    status: StateStatus = 'idle';
    entities: Dictionary<Note> = {};
    ids: number[] = [];
    meta: PaginationType = {} as PaginationType;
    submitting: StateStatus = 'idle';
    deleting: StateStatus = "idle";
}
