import { CRUDService } from '../../common/service';
import { Employee } from '../../models/employee';
import {RouterEnum} from "../../config/router.enum";

export class AccountPermissionService extends CRUDService<Employee> {
    getNameSpace(): string {
        return RouterEnum.employee + '/' + RouterEnum.accountPermission;
    }
}

const accountPermissionService = new AccountPermissionService();

export default accountPermissionService;
