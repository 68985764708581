import {combineReducers} from 'redux';
import auth from './features/auth/reducer';
import application from  './features/application/reducer';
import customerActivity from './features/customer-activity/CustomerActivityHistory/reducer'
import customerRealtime from './features/customer-activity/RealtimeOnline/reducer';
import customerDashboard from "./features/customer-activity/Dashboard/reducer";
import customerActivityHistory from './features/customer-activity/CustomerActivityHistory/reducer';
import accountPermission from './features/account-permission/reducer';
import callLog from "./features/engagement/CallLog/reducer";
import call from './features/call/reducer';
import customerDetail from './features/customer-profile/CustomerDetail/reducer';
import customerProfile from "./features/customer-profile/CustomerProfileList/reducer";
import note from  "./features/customer-profile/CustomerDetail/NoteHistory/reducer";
import employee from  "./features/employee/reducer";
import roleList from "./features/authorization/roles/reducer";
import accountList from "./features/account/reducer";
import backendErrors, {setSelectBackendErrors} from "./common/backend-util/store";
import AppState from "./App.state";
import purchaseHistory from "./features/customer-profile/CustomerDetail/PurchaseHistory/reducer"
import paymentHistory from "./features/customer-profile/CustomerDetail/PaymentHistory/reducer"
import serviceActivationHistory from "./features/customer-profile/CustomerDetail/ServiceActivationHistory/reducer";
import notification from "./features/notification/reducer";

import workflowList from "./features/workflow/workflows/reducer";
import ticketList from "./features/workflow/ticket/reducer";
import ticketDetail from "./features/workflow/TicketDetail/reducer";
import processor from "./features/workflow/processor/reducer";
import ticketLog from './features/workflow/TicketDetail/TicketLog/reducer';
import category from './features/workflow/category/reducer';
import settingNotification from "./features/setting-notification/reducer";
import changeLogs from "./features/change-logs/reducer";

import reportTemplate from './features/reporting/template/reducer';
import reporting from './features/reporting/report/reducer';
import marketInsight from "./features/market-insight/reducer";
import customerStatistic from "./features/customer-statistic/reducer";

const AppReducer = combineReducers({
    backendErrors,
    auth,
    application,
    customerActivity,
    customerActivityHistory,
    customerRealtime,
    customerDashboard,
    accountPermission,
    callLog,
    call,
    customerDetail,
    customerProfile,
    note,
    employee,
    roleList,
    accountList,
    purchaseHistory,
    paymentHistory,
    serviceActivationHistory,
    notification,
    workflowList,
    ticketList,
    ticketDetail,
    processor,
    ticketLog,
    category,
    settingNotification,
    changeLogs,
    reporting,
    reportTemplate,
    marketInsight,
    customerStatistic,
});

setSelectBackendErrors((state: AppState) => state.backendErrors);

export default AppReducer;
