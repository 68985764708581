import AppState from '../../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Category} from "../../../models/category";

export const selectCategoryState = (state: AppState) => state.category;

export const selectCategoryIds = createSelector(
    selectCategoryState,
    state => state.ids
);

export const selectAllCategories = createSelector(
    selectCategoryState,
    state => state.ids.map(id => state.entities[id] as Category),
);

export const selectInteractingOfCategory = (id: number) => createSelector(
    selectCategoryState,
    state => state.interactingIds.includes(id)
);

export const selectSingleCategory = (id?: number) => createSelector(
    selectCategoryState,
    state => state.entities[id ?? 0]
);

export const selectCategoryFormLoading = createSelector(
    selectCategoryState,
    state => state.formStatus === 'loading'
);

export const selectCategoryListLoading = createSelector(
    selectCategoryState,
    state => state.status === 'loading'
);

export const selectCategoryParams = createSelector(
    selectCategoryState,
    state => state.params
);

export const selectCategoryFormStatus = createSelector(
    selectCategoryState,
    state => state.formStatus,
);

export const selectCategoryMeta = createSelector(
    selectCategoryState,
    state => state.meta,
);

export const selectSetActiveLoading = createSelector(
    selectCategoryState,
    state => state.setActiveStatus === 'loading',
);

export const selectOtherCategory = createSelector(
    selectCategoryState,
    state => state.otherCategory
);