import {Dictionary} from '@reduxjs/toolkit';
import {StateStatus} from '../../../common/enums';
import {Category} from '../../../models/category';

export class CategoryFormData {
    id?: number;
    name?: string = undefined;
    description?: string = undefined;
}

export class CategoryParams {
    search?: string = undefined;
}

export class CategoryState {
    ids: number[] = [];
    formStatus: StateStatus = 'idle';
    meta: PaginationType = {} as PaginationType;
    params = new CategoryParams();
    entities: Dictionary<Category> = {};
    otherCategory?: Category = undefined;
    status: StateStatus = 'idle';
    interactingIds: number[] = [];
    setActiveStatus: StateStatus = 'idle';
}
