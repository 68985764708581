import {StateStatus} from "../../../../common/enums";
import {Dictionary} from "@reduxjs/toolkit";
import {ActivityLog} from "../../../../models/activity-log";

export class ServiceActivationState {
    entities: Dictionary<ActivityLog> = {};
    dateMapping: Dictionary<number[]> = {};
    status: StateStatus = 'idle';
    ids: number[] = [];
}
