import AppState from '../../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {CustomerConnect} from "../../../models/customer-connect";

export const selectCustomerRealtimeState = (state: AppState) => state.customerRealtime;


export const selectAllCustomers = createSelector(
    selectCustomerRealtimeState,
    state => Object.values(state.entities)
);

export const selectCustomerStatus = createSelector(
    selectCustomerRealtimeState,
    state => state.status
);

export const selectCustomerListLoading = createSelector(
    selectCustomerRealtimeState,
    state => state.status === 'loading'
);

export const selectCustomerParams = createSelector(
    selectCustomerRealtimeState,
    state => state.params
);

export const selectCustomerMeta = createSelector(
    selectCustomerRealtimeState,
    state => state.meta
);

export const selectCustomerDataTable = createSelector(
    selectCustomerRealtimeState,
    state => state.ids.map(id => state.entities[id] as CustomerConnect)
);

export const selectExpandedCustomerDataTable = (employerId: number) => createSelector(
    selectCustomerRealtimeState,
    state => state.expandedMapping[employerId]?.map(id => state.entities[id] as CustomerConnect) ?? []
);

export const selectExpandedCustomerMeta = (employerId: number) => createSelector(
    selectCustomerRealtimeState,
    state => state.expandedMeta[employerId]
);
