import {all, call, put, select, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import {safeCall} from "../../../common/utils";
import {Rest} from "../../../common/models/rest";
import {
    emptyProcessingNode, loadedAdvanceSearchForm, loadedFail, loadingAdvanceSearchForm,
    loadingTicketProcessingNode,
    refreshTicketList, searchingAdvanceTickets,
    ticketListLoaded,
    ticketProcessingNodeLoaded
} from "./reducer";
import {selectTicketListLoading} from "./selector";
import ticketListService from "./service";
import {Ticket} from "../../../models/ticket";
import {NodeModel} from "../../../models/node-model";
import {isEmpty} from "lodash";
import {AdvanceSearchForm} from "../../../models/advance-search-form";
import {message} from "antd";

const sagas = [
    takeLeading(refreshTicketList, safeCall(function* ({payload}) {
        const loading: boolean = yield select(selectTicketListLoading);
        if (!loading) return;

        const result: Rest<Ticket[]> = yield call(ticketListService.listTicketCustom, payload);

        yield put(ticketListLoaded(result));
    })),
    takeEvery(loadingTicketProcessingNode, safeCall(function* ({payload}) {
        const result: Rest<NodeModel[]> = yield call(ticketListService.ticketProcessingNode, payload);

        if (isEmpty(result.data)) {
            yield put(emptyProcessingNode(payload));
            return;
        }

        yield put(ticketProcessingNodeLoaded(result));
    })),
    takeLatest(loadingAdvanceSearchForm, safeCall(function* () {
        const result: Rest<AdvanceSearchForm> = yield call(ticketListService.advancedSearchForm);

        yield put(loadedAdvanceSearchForm(result));
    })),

takeLatest(searchingAdvanceTickets, safeCall(function* ({payload}) {
    try {
        const result: Rest<Ticket[]> = yield call(ticketListService.advancedSearchTickets, payload);
        yield put(ticketListLoaded(result));
    } catch (e) {
        console.log(e);
        message.error('Đã có lỗi xảy ra');
        yield put(loadedFail());
    }
}))


];

function* ticketListSagas() {
    yield all(sagas);
}

export default ticketListSagas;
