import AppState from "../../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Purchase} from "../../../../models/purchase";

export const selectPurchaseState = (state: AppState) => state.purchaseHistory;

export const selectPurchaseEntities = createSelector(
    selectPurchaseState,
    state => state.ids.map(id => state.entities[id] as Purchase)
)

export const selectPurchaseHistoryStatus = createSelector(
    selectPurchaseState,
    state => state.status
)

export const selectPurchaseHistoryMeta = createSelector(
    selectPurchaseState,
    state => state.meta
);
