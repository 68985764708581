import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CustomerActivityHistoryState} from "./state";
import {Rest} from "../../../common/models/rest";

const slice = createSlice({
    name: 'customer-activity',
    initialState: {...new CustomerActivityHistoryState()},
    reducers: {
        refreshListHistories(state: CustomerActivityHistoryState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
        },
        listHistoriesLoaded(state: CustomerActivityHistoryState, {payload}: PayloadAction<Rest<any>>) {
            return {
                ...state,
                listLoaded: true,
                status: 'idle',
                tableData: payload.data,
                meta: payload.meta
            };
        }
    }
});

export const {
    refreshListHistories,
    listHistoriesLoaded
} = slice.actions;

const customerActivityReducer = slice.reducer;

export default customerActivityReducer;
