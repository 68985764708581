import {memo, useCallback, useEffect, useMemo} from "react";
import {Layout, Button, Spin, Popover} from "antd";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RelativeLink, RouterOutlet} from "react-hook-guard";
import ProfileButton from "./components/ProfileButton";
import DocumentTitle from "./components/Title";
import { useCurrentPathName} from "../common/utils/route";
import logo from './images/logo.png';
import {Link} from "react-router-dom";
import QuickCallButton from "../features/call/QuickCallButton";
import CallBar from "../features/call/CallBar";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import auth from "../features/auth/auth";
import {useDispatch, useSelector} from "react-redux";
import {selectApplicationFromSlug, selectApplicationSlugs} from "../features/application/selectors";
import {listApplications} from "../features/application/reducer";
import NotificationBell from "./components/NotificationBell";

import appItemStyle from './appItem.module.scss';
import { PermissionValidation } from "../core/auth/auth";
import NeedAuthorize from "../common/components/NeedAuthorize";
import Feedbacks from "./components/Feedbacks";
import JobInfos from "./components/JobInfos";
import store from "../config/store";
import {backendJobReceived} from "../common/backend-util/store";
import fontAwesomeIcons from "../utils/fontAwesomeIcons";
import MenuRender from "./components/MenuRender";
import InfoApp from "./components/InfoApp";

const {Header, Content, Sider} = Layout;

export interface MenuData {
    icon: IconProp;
    title: string;
    path: string;
    permissions?: PermissionValidation[];
    configShowCount?: {isShow: boolean, key: string};
}

// const HelpButton = () => (
//     <div className="mr-16">
//         <Button
//             type="text"
//             className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
//         >
//             <FontAwesomeIcon className="text-grey21 text-20" icon={["fas", "question-circle"]}/>
//         </Button>
//     </div>
// );

const AppItem = ({slug}: {slug: string}) => {
    const application = useSelector(selectApplicationFromSlug(slug));
    return (
        <NeedAuthorize permissions={application?.permissions}>
            <RelativeLink
                to={['..', slug]}
                className={appItemStyle['app-item']}
            >
                <img
                    height={25}
                    width={40}
                    src={application?.image}
                    alt={'logo app'}
                    style={{objectFit: 'contain'}}
                />
                <span className={appItemStyle['app-name']}>{application?.name}</span>
            </RelativeLink>
        </NeedAuthorize>
    );
}

const ContentApp = () => {
    const applicationSLugs = useSelector(selectApplicationSlugs);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listApplications());
    }, [dispatch]);

    return (
        <div className={'w-342 flex h-fit flex-wrap'}>
            {
                applicationSLugs.map((slug) => (
                <AppItem slug={slug} key={slug}/>
                ))
            }
        </div>
    )
};

const AppButton = () => (
    <Popover content={ContentApp} placement="bottomRight" trigger="click">
        <div className="mr-16">
            <Button
                type="text"
                className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
            >
                <FontAwesomeIcon className="text-grey21 text-20" icon={fontAwesomeIcons.farGridHorizontal}/>
            </Button>
        </div>
    </Popover>
);

const SiderLayout = (props: any) => {
    useEffect(() => {
        store.dispatch(backendJobReceived(1));
    })
    const isDisplayMenu = props.parentRoute.data?.menuDisplay === true;
    const isHiddenAppIcon = props.parentRoute.data?.appIconDisplay === false;
    const menuData: MenuData[] = useMemo(() => props.parentRoute.data.menuData, [props.parentRoute.data]);
    const [collapsed, setCollapsed] = useState(false);

    // useEffect(() => {
    //     setCollapsed(menuData?.length === 1);
    // }, [menuData, setCollapsed]);

    const collapse = useCallback(() => {
        setCollapsed(true);
    }, [setCollapsed]);

    const toggleCollapse = useCallback(() => {
        setCollapsed(prevState => !prevState);
    }, [setCollapsed]);
    const isLoading = false;
    const currentPathname = useCurrentPathName(props.parentRoute, props.routes);

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Header
                className="bg-theme fixed w-full z-20 flex p-0"
            >
                <div className={"w-180 flex flex-row items-center pl-12"}>
                    {
                        isDisplayMenu ?
                            <FontAwesomeIcon
                                onClick={toggleCollapse}
                                className="w-18 h-18 flex justify-center items-center text-main p-10 border rounded-full
                                 hover:bg-gray-200 cursor-pointer theme-page-title z-20"
                                icon="bars"
                            /> : undefined
                    }
                    <Link to={'/'}>
                        <img
                            src={logo}
                            className="ml-30 cursor-pointer bg-logo p-5 rounded"
                            alt={'icon'}
                        />
                    </Link>
                </div>
                <div className="flex justify-between flex-row ml-16 mr-40 h-full w-full">
                    <DocumentTitle/>
                    <div className="flex flex-row items-center">
                        {auth?.user?.internal_number ? <QuickCallButton/> : undefined}
                        {/*<HelpButton/>*/}
                        <Feedbacks />
                        {isHiddenAppIcon ? undefined : <AppButton/>}
                        <NotificationBell/>
                        <ProfileButton/>
                    </div>
                </div>
                <JobInfos />
            </Header>
            <Layout style={{marginTop: 66}}>
                { isDisplayMenu ?
                        <Sider
                            collapsedWidth={69}
                            onBreakpoint={collapse}
                            breakpoint="sm"
                            style={{borderRight: '1.5px solid #F0F0F0'}}
                            collapsible
                            collapsed={collapsed}
                            trigger={null}
                            width={224}
                            className="sider-menu fixed h-full z-10"
                        >
                                <MenuRender
                                    absolutePath={props.parentRoute.absolutePath}
                                    collapsed={collapsed}
                                    currentPathname={currentPathname}
                                    menuData={menuData}
                                />
                                <InfoApp hidden={collapsed}/>
                        </Sider> : undefined
                }
                <Layout style={isDisplayMenu ? {marginLeft: collapsed ? 69 : 216} : undefined}>
                    <Content className={'bg-white'}>
                        <div
                            className="flex items-center justify-center"
                            style={{display: isLoading ? undefined : "none"}}
                        >
                            <Spin size="large"/>
                        </div>
                        <div
                            style={{
                                display: isLoading ? "none" : undefined,
                            }}
                        >
                            {auth?.user?.internal_number ? <CallBar/> : undefined}
                            <RouterOutlet {...props} />
                        </div>
                    </Content>
                    <InfoApp hidden={isDisplayMenu}/>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default memo(SiderLayout);
