import {keyBy} from "lodash";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Rest} from "../../../common/models/rest";
import {ReportTemplate} from "../../../models/report-template";
import {ReportTemplateState} from "./state";

const slice = createSlice({
    name: 'report-template',
    initialState: {...new ReportTemplateState()},
    reducers: {
        loadingReportTemplateList(state: ReportTemplateState, {payload}: PayloadAction<any>) {
            state.listLoading = true;
        },
        loadedReportTemplateList(state: ReportTemplateState, {payload}: PayloadAction<Rest<ReportTemplate[]>>) {
            state.listLoading = false;
            state.listReportTemplateIds = payload.data.map(({id}) => (id));
            state.listReportTemplate = keyBy(payload.data, 'id');
            state.meta = payload.meta;
        },
        saveTemplate(state: ReportTemplateState, {payload}: PayloadAction<any>) {
            state.saveTemplateStatus = 'loading';
        },
        saveTemplateSuccess(state: ReportTemplateState, {payload}: PayloadAction<any>) {
            state.saveTemplateStatus = 'success';
        },
        saveTemplateFail(state: ReportTemplateState) {
            state.saveTemplateStatus = 'idle';
        },
        deleteTemplate(state: ReportTemplateState, {payload}: PayloadAction<any>) {
            state.deleteTemplateStatus = 'loading';
        },
        deleteTemplateSuccess(state: ReportTemplateState) {
            state.deleteTemplateStatus = 'success';
        },
        deleteTemplateFail(state: ReportTemplateState) {
            state.deleteTemplateStatus = 'idle';
        }
    }
});

export const {
    loadingReportTemplateList,
    loadedReportTemplateList,
    saveTemplate,
    saveTemplateSuccess,
    saveTemplateFail,
    deleteTemplate,
    deleteTemplateSuccess,
    deleteTemplateFail
} = slice.actions;

const reportTemplate = slice.reducer;

export default reportTemplate;