import {CRUDService} from "../../../common/service";
import {CustomerParams} from "./state";
import {CustomerConnect} from "../../../models/customer-connect";
import {RouterEnum} from "../../../config/router.enum";
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";

export class CustomerDashboardService extends CRUDService<CustomerConnect, any, CustomerParams, string> {
    getNameSpace(): string {
        return RouterEnum.connect;
    }


    getRelativeData = (id: string) => {
        return axios.get<Rest<CustomerConnect>>(`${BASE_URL}/${this.getNameSpace()}/${id}`);
    };
}

const customerDashboardService = new CustomerDashboardService();

export default customerDashboardService;
