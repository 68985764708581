import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CustomerParams, CustomerRealtimeState} from './state';
import { Rest } from "../../../common/models/rest";
import {PreparedCaseReducer} from "../../../common/utils";
import isEqual from "lodash/isEqual";
import {CustomerConnect} from "../../../models/customer-connect";
import {keyBy} from "lodash";

const slice = createSlice({
    name: 'customerDashboard',
    initialState: {...new CustomerRealtimeState()},
    reducers: {
        refreshListCustomers(state: CustomerRealtimeState, {payload}: PayloadAction<CustomerParams>) {
            state.status = 'loading';
        },
        loadExpandedCustomers(state: CustomerRealtimeState, {payload}: PayloadAction<string>) {
            state.expandedStatus = 'loading';
        },
        listCustomersLoaded(state: CustomerRealtimeState, {payload}: PayloadAction<Rest<CustomerConnect[]>>) {
            state.meta = payload.meta;
            state.ids = payload.data.map(({_id}) => (_id));
            state.entities = {
                ...state.entities,
                ...keyBy(payload.data, '_id')
            };
            // Xem trường expandedMapping có những employerId nào không còn trong ids nữa thì xóa đi
            let expandedMappingData = {...state.expandedMapping};
            const ids = [...state.ids];
            Object.values(expandedMappingData).forEach((data ) => {
                data?.forEach( (val) => {
                    if (!ids.includes(val)) {
                        const idx = data?.indexOf(val);
                        data?.splice(idx, 1);
                    }
                })
            })
            state.expandedMapping = expandedMappingData;
            state.listLoaded = true;
            state.status = 'idle';
        },
        listExpandedCustomersLoaded(state: CustomerRealtimeState, {payload}: PayloadAction<Rest<CustomerConnect[]>>) {
            if (!payload.data?.length) return;

            const employerId = payload.data[0].userId;
            state.expandedMapping[employerId] = payload.data.map(({_id}) => _id);
            state.expandedMeta[employerId] = payload.meta;
            for (const d of payload.data) {
                state.entities[d._id] = d;
            }
            state.listExpandLoaded = true;
            state.expandedStatus = 'idle';
        },
        customerParamsChange: new PreparedCaseReducer<CustomerRealtimeState, Partial<CustomerParams> | undefined, boolean>(
            (state, {payload, meta}) => {
                const newParams = {
                    ...state.params,
                    ...payload ?? {}
                };
                if (!isEqual(state.params, newParams)) {
                    state.params = newParams;
                }
            }
        )
    }
});

export const {
    refreshListCustomers,
    listCustomersLoaded,
    customerParamsChange,
    loadExpandedCustomers,
    listExpandedCustomersLoaded,
} = slice.actions;

const customerRealtimeReducer = slice.reducer;

export default customerRealtimeReducer;
