import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "antd";
import {useState} from "react";
import {sendDTMF} from "./reducer";
import {useDispatch} from "react-redux";

const Keypad = () => {
    const dispatch = useDispatch();
    const [input, setInput] = useState('')
    const appendToInput = (val: number | string) => {
        setInput(input + val)
    }
    const send = () => {
        dispatch(sendDTMF(input))
        setInput('')
    }
    return <div className="keypad ml-4 mt-4" role="group">
        <div>
            <input value={input} className="text-center form-control-lg mb-2" onChange={(e) => setInput(e.target.value)} />
        </div>
        <div className="mt-5">
            <div className="flex justify-between">
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(1)}>1
                    </button>
                </div>
                <div className="col-xs-4  text-center">
                    <button type="button" className="num" onClick={() => appendToInput(2)}>2
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(3)}>3
                    </button>
                </div>
            </div>
        </div>
        <div className="mt-5">
            <div className="flex justify-between">
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(4)}>4
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(5)}>5
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(6)}>6
                    </button>
                </div>
            </div>
        </div>
        <div className="mt-5">
            <div className="flex justify-between">
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(7)}>7
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(8)}>8
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(9)}>9
                    </button>
                </div>
            </div>
        </div>
        <div className="mt-5">
            <div className="flex justify-between">
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput('*')}>*
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput(0)}>0
                    </button>
                </div>
                <div className="col-xs-4 text-center">
                    <button type="button" className="num" onClick={() => appendToInput('#')}>#
                    </button>
                </div>
            </div>
        </div>
        <div className={'mt-5'}>
            <Button type="primary" style={{width: '100%'}} onClick={send}>
                <FontAwesomeIcon icon="paper-plane" />
            </Button>
        </div>
    </div>
}
export default Keypad;
