import {safeCall} from "../../../common/utils";
import {initListProcessor, listProcessorLoaded, refreshListProcessor} from "./reducer";
import {all, call, debounce, put, select} from "redux-saga/effects";
import {ProcessorState} from "./state";
import {selectProcessorState} from "./selectors";
import {Processor} from "../../../models/processor";
import {Rest} from "../../../common/models/rest";
import workflowService from "./service";

const sagas = [
    debounce(200, initListProcessor, safeCall(function* ({payload}) {
        const {status, loadingParams, employeeIds, departmentIds, positionIds, roleIds}: ProcessorState = yield select(selectProcessorState);
        if (status !== 'loading') {
            return;
        }
        const empIds: number[] = [];
        const depIds: number[] = [];
        const posIds: number[] = [];
        const rolIds: number[] = [];

        loadingParams?.defaultValue?.forEach((item: any) => {
            if (String(item).includes('D') && !departmentIds.includes(item.split('D')[1])) {
                const id = item.split('D')[1];
                depIds.push(Number(id))
            }
            if (String(item).includes('P') && !positionIds.includes(item.split('P')[1])) {
                const id = item.split('P')[1];
                posIds.push(Number(id))
            }
            if (String(item).includes('R') && !roleIds.includes(item.split('R')[1])) {
                const id = item.split('R')[1];
                rolIds.push(Number(id))
            }
            if (!String(item).includes('D') && !String(item).includes('P') && !String(item).includes('R') && !employeeIds.includes(item)) {
                empIds.push(Number(item));
            }
        });
        loadingParams?.value?.forEach((item: any) => {
            if (String(item).includes('D') && !departmentIds.includes(item.split('D')[1])) {
                const id = item.split('D')[1];
                depIds.push(Number(id))
            }
            if (String(item).includes('P') && !positionIds.includes(item.split('P')[1])) {
                const id = item.split('P')[1];
                posIds.push(Number(id))
            }
            if (String(item).includes('R') && !roleIds.includes(item.split('R')[1])) {
                const id = item.split('R')[1];
                rolIds.push(Number(id))
            }
            if (!String(item).includes('D') && !String(item).includes('P') && !String(item).includes('R') && !employeeIds.includes(item)) {
                empIds.push(Number(item));
            }
        });
        const data = {
            type: loadingParams?.type_select,
            employee_ids: empIds,
            department_ids: depIds,
            position_ids: posIds,
            role_ids: rolIds
        };
        if (!empIds.length && !depIds.length && !posIds.length && !rolIds.length) {
            return;
        }
        const response: Rest<Processor> = yield call(workflowService.getListProcessor, data);
        yield put(listProcessorLoaded(response));
    })),
    debounce(500, refreshListProcessor, safeCall(function* ({payload}) {
        const {status}: ProcessorState = yield select(selectProcessorState);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<Processor> = yield call(workflowService.getListProcessor, payload);
        yield put(listProcessorLoaded(response));
    }))
];

function* processorSagas() {
    yield all(sagas);
}

export default processorSagas;
