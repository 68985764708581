import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {Processor} from "../../../models/processor";
import {BASE_URL} from "../../../config/properties";

export class ProcessorService {
    getListProcessor(params: any) {
        return axios.get<Rest<Processor>>(
            `${BASE_URL}/processor`,
            {params}
        );
    }
}
const processorService = new ProcessorService();

export default processorService;
