import {Callio} from "../../core/call/Callio";
import store from "../../config/store";
import {setCall, setCallStarted, setRinging} from "./reducer";
import {IncomingEvent, RTCSession} from "jssip/lib/RTCSession";
import {message} from "antd";

const callDriver = new Callio();

window.addEventListener('beforeunload',(event) => {
    if (callDriver.call) {
        event.preventDefault();
        const msg = 'Đang có cuộc gọi bạn có muốn thoát'
        event.returnValue = msg
        return msg
    }
});

callDriver.onAccepted = () => {
    store.dispatch(setCallStarted(true))
}
callDriver.onCalling = (e: any, call: any) => {
    // store.dispatch(setCalling(true))
    // store.dispatch(setCallBarOpen(true))
}
callDriver.onCallProgress = (e: IncomingEvent | IncomingEvent, call: RTCSession) => {
    if (call.direction === 'incoming') {
        const phoneNumber = call.remote_identity.display_name;
        store.dispatch(setCall({phoneNumber, isIncoming: true}))
    } else {
        store.dispatch(setRinging(true))
    }
}
callDriver.onConfirmed = () => {
    store.dispatch(setCallStarted(true))
}
callDriver.onEnded = () => {
    store.dispatch(setCallStarted(false))
}
callDriver.onFailed = () => {
    store.dispatch(setCallStarted(false))
}
callDriver.onGetMediaFailed = () => {
    message.error('Không thể call do không thể sử dụng mic')
}
callDriver.onCantCall = () => {
    message.error('Không thể thực hiện cuộc gọi')
}
export default callDriver;