import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {safeCall} from "../../../../common/utils";
import {listPurchaseLoaded, refreshListPurchase} from "./reducer";
import {PurchaseHistoryState} from "./state";
import {selectPurchaseState} from "./selector";
import {Purchase} from "../../../../models/purchase";
import {Rest} from "../../../../common/models/rest";
import purchaseHistoryService from "./service";

const sagas = [
    takeEvery(refreshListPurchase, safeCall(function* ({payload}) {
        const {status}: PurchaseHistoryState = yield select(selectPurchaseState);
        if (status !== 'loading') {
            return;
        }

        const response: Rest<Purchase[]> = yield call(purchaseHistoryService.getAllPurchase, payload);
        yield put(listPurchaseLoaded(response));
    }))
];

function* purchaseHistorySagas() {
    yield all(sagas);
}

export default purchaseHistorySagas;
