import {CRUDService} from "../../../common/service";

class ReportTemplateService extends CRUDService<any> {
    getNameSpace(): string {
        return "report-templates";
    }
}

const reportTemplateService = new ReportTemplateService();

export default reportTemplateService;