import {CRUDService} from "../../../common/service";
import {Workflow, WorkflowStatus} from "../../../models/workflow";
import {RouterEnum} from "../../../config/router.enum";
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";
import {NodeModel} from "../../../models/node-model";


export class WorkflowListService extends CRUDService<Workflow> {
    getNameSpace(): string {
        return RouterEnum.workflows;
    }

    getAllWorkflow(params: any) {
        return axios.get<any, Rest<Workflow>>(`${BASE_URL}/${RouterEnum.workflows}/can-use`, {params});
    }

    changeWorkflowStatus({id, status}: WorkflowStatus) {
        return axios.put<any, Rest<Workflow>>(`${BASE_URL}/${RouterEnum.workflows}/${id}/set-active`, {status});
    }

    getFormByNodeId(nodeId: string, isFormNode?: boolean) {
        const namespace = isFormNode ? 'workflow-nodes' : 'workflows';
        return axios.get<any, Rest<NodeModel>>(
            `${BASE_URL}/${namespace}/${nodeId}/form-config`,
        );
    }

    getWorkflowFullData(workflowId: number) {
        return axios.get<any, Rest<Workflow>>(`${BASE_URL}/${RouterEnum.workflows}/${workflowId}/full`);
    }
}

const workflowListService = new WorkflowListService();

export default workflowListService;
