import AppState from "../../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Payment} from "../../../../models/payment";

export const selectPaymentState = (state: AppState) => state.paymentHistory;

export const selectPaymentEntities = createSelector(
    selectPaymentState,
    state => state.ids.map(id => state.entities[id] as Payment)
)

export const selectPaymentHistoryStatus = createSelector(
    selectPaymentState,
    state => state.status
)

export const selectPaymentHistoryMeta = createSelector(
    selectPaymentState,
    state => state.meta
);
