import {all, call, put, select, takeLatest, delay, takeLeading} from "redux-saga/effects";
import {safeCall} from "../../common/utils";
import {Rest} from "../../common/models/rest";
import {
    setCall,
    fetchConfig,
    increaseCallingTime, sendDTMF,
    setCallBarOpen, setCallConfig, setCalling,
    setCallStarted,
    setContactName, setMuted
} from "./reducer";
import {
    selectCallConfig,
    selectCallStarted,
    selectIsCalling,
    selectIsIncoming
} from "./selectors";
import callService from "./service";
import callDriver from "./call-driver";
import {APP_PREFIX} from "../../config/properties";
const sagas = [
    takeLatest(setCall, safeCall(function* ({payload}) {
        let isCalling: boolean = yield select(selectIsCalling);
        if (isCalling) {
            return;
        }
        if (!payload.isIncoming) {
            const canCall = callDriver.callToNumber(payload.phoneNumber)
            if (!canCall) {
                yield put(setCalling(false))
                yield put(setCallBarOpen(false))
                return;
            }
        }
        yield put(setCalling(true))
        const res: Rest<any> = yield call(callService.getLeadByPhone, payload.phoneNumber)
        yield put(setContactName(res.data?.name))
    })),
    takeLatest(setMuted, safeCall(function* ({payload}) {
        if (payload) {
            if (!callDriver.mute()) {
                yield put(setMuted(!payload));
            }
        } else {
            if (!callDriver.unMute()) {
                yield put(setMuted(!payload));
            }
        }
    })),
    takeLatest(sendDTMF, safeCall(function* ({payload}) {
        yield callDriver.sendDTMF(payload)
    })),
    takeLeading(setCallStarted, safeCall(function* ({payload}) {
        let callStarted: boolean = yield select(selectCallStarted);
        let isIncoming: boolean = yield select(selectIsIncoming)
        if (isIncoming && callStarted) {
            callDriver.answer();
        }
        while (callStarted) {
            yield delay(1000);
            yield put(increaseCallingTime());
            callStarted = yield select(selectCallStarted)
        }
        if (!callStarted) {
            callDriver.terminateCall();
            yield delay(2000)
            const isCalling: boolean = yield select(selectIsCalling);
            if (!isCalling) {
                yield put(setCallBarOpen(false));
            }
        }
    })),
    takeLeading(fetchConfig, safeCall(function* () {
        const callConfig = (yield select(selectCallConfig));
        if (callConfig) {
            return;
        }
        const response: Rest<any> = yield call(callService.getConfig);
        const config = response.data;
        let ringtone = document.location.origin;
        if (APP_PREFIX) {
            ringtone += APP_PREFIX;
        }
        ringtone += '/ringtone.mp3'
        config.ringtone = ringtone
        callDriver.setConfig(config).init();
        yield put(setCallConfig(config));
    })),
];

function* callSagas() {
    yield all(sagas);
}

export default callSagas;
