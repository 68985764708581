import {useDispatch, useSelector} from "react-redux";
import {
    selectCallingTime,
    selectCallStarted, selectContactName,
    selectIsCallBarOpen,
    selectIsCalling, selectIsIncoming, selectIsMuted,
    selectPhoneNumber, selectRinging
} from "./selectors";
import {setCallStarted, fetchConfig, setMuted} from "./reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhoneArrowUpRight} from "@fortawesome/pro-solid-svg-icons";
import {Button, Popover} from "antd";
import Keypad from "./Keypad";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const CallBar = () => {
    const {t} = useTranslation('call');
    const dispatch = useDispatch();
    const isCalling = useSelector(selectIsCalling)
    const callStarted = useSelector(selectCallStarted)
    const isOpenCallBar = useSelector(selectIsCallBarOpen)
    const contactName = useSelector(selectContactName)
    const callingTime = useSelector(selectCallingTime)
    const phoneNumber = useSelector(selectPhoneNumber)
    const isMuted = useSelector(selectIsMuted)
    const isComing = useSelector(selectIsIncoming)
    const ringing = useSelector(selectRinging)
    useEffect(() => {
        dispatch(fetchConfig())
    }, [dispatch]);
    const endCall = () => {
        dispatch(setCallStarted(false))
    }
    const answer = () => {
        dispatch(setCallStarted(true))
    }
    const toggleMuted = () => {
        dispatch(setMuted(!isMuted))
    }
    const callStatus = () => {
        if (callStarted) {
            return t('in_call')
        } else {
            if (isCalling) {
                if (ringing ) {
                    return t('ringing')
                }
                if (isComing) {
                    return t('calling_in')
                }
                return t('calling')
            } else {
                return t('call_ended')
            }
        }
    }
    return <>
        {
            isOpenCallBar ?
                <div id="call-bar" className="call-bar">
                    <span>
                        <div className="call-spinner">
                            <div className="double-bounce1"/>
                            <div className="double-bounce2"/>
                        </div>
                    </span>
                        <span className="call-bar-center flex">
                            {
                                !isComing
                                    ? <span className="px-10 text-main">
                                        <FontAwesomeIcon icon={faPhoneArrowUpRight} />
                                        </span>
                                    : <span className="px-10 text-yellow-400">
                                        <FontAwesomeIcon icon="arrow-down" />
                                        </span>
                            }
                        <span style={{color: 'white'}}>
                            <span className="px-10 border-left">
                                <i className="mdi mdi-account"/>
                                <span className="contact-name">
                                    {contactName || t('unknown_contact')} -&nbsp;
                                </span>
                                <span className="call-number">
                                    {phoneNumber}
                                </span>
                            </span>
                            <span className="call-status px-10 border-left">
                                {callStatus()}
                            </span>
                            <span className="call-timer pr-10">
                                {callingTime}
                            </span>
                        </span>
                            {
                                callStarted ?
                                <span className="call-feature px-10 border-left text-white">
                                    {isMuted ? t('mic_off') : t('mic_on')}
                                </span> : <></>
                            }
                        <span className="call-feature px-10 border-left">
                            {
                                isMuted ?
                                    <Button type="primary" shape="circle" className="mr-10" onClick={toggleMuted}>
                                        <FontAwesomeIcon icon="microphone" />
                                    </Button> :
                                    <Button type="primary" shape="circle" danger className="mr-10" onClick={toggleMuted}>
                                        <FontAwesomeIcon icon="microphone-slash" />
                                    </Button>
                            }
                            <Popover content={<Keypad />} trigger="click">
                                <Button type="primary" shape="circle" >
                                    <FontAwesomeIcon icon="grip-horizontal" />
                                </Button>
                            </Popover>
                        </span>
                        <span className="call-action px-10 border-left">
                            {(isComing && !callStarted)
                                ? <Button type="primary" shape="circle" onClick={answer} className="mr-10">
                                    <FontAwesomeIcon icon="phone"/>
                                </Button>
                            : <></>
                            }
                            <Button type="primary" shape="circle" danger onClick={endCall}>
                                <FontAwesomeIcon icon="phone-slash"/>
                            </Button>
                        </span>
                    </span>
                </div> :
                <div></div>
        }
    </>
}

export default CallBar;