import {Ticket} from "../../../models/ticket";
import {StateStatus} from "../../../common/enums";
import {Node} from "reactflow";
import {Elements} from "../Diagram/type";
import {Comment} from "../../../models/comment";
import {Workflow} from "../../../models/workflow";
import {Dictionary} from "@reduxjs/toolkit";

export class TicketDetailState {
    ticket: Ticket = {
        id: 0,
        name: '',
    };
    status: StateStatus = 'idle';
    statusLite: StateStatus = 'idle';
    getCurrentNodeStatus: StateStatus = 'idle';
    elements: Elements = [];
    comments: Comment[] = [];
    workflow?: Workflow = undefined;
    workflowStatus: StateStatus = 'idle';
    nodes: Dictionary<Node> = {};
    processingNodes: Node[] = [];
    nodeIds: string[] = [];
    nodeDetailStatus: StateStatus = 'idle';
    currentNodeId?: number | string = undefined;
    currentNode?: Node = undefined;
    commentStatus: StateStatus = 'idle';
    creating: StateStatus = 'idle';
    nextActionStatus: StateStatus = 'idle';
    updateTaskStatus: StateStatus = 'idle';
    createCommentStatus: StateStatus = 'idle';
    ticketNodeAssignStatus: StateStatus = 'idle';
    cancelTicketStatus: StateStatus = 'idle';
    receiveNotificationStatus: StateStatus = 'idle';
    editFollowingStatus: StateStatus = 'idle';
    mentionTickets: Dictionary<Ticket> = {};
    mentionTicketIds: number[] = [];
    mentionTicketStatus: StateStatus = 'idle';
    refreshTicketCode: string = '';
    requestFollowStatus: StateStatus = 'idle';
    ticketNodeAssignMultipleStatus: StateStatus = 'idle';
    formResultNodeLoading: boolean = false;
    formResultNodes: Dictionary<any> = {};
    refreshTimelineStatus: StateStatus = 'idle';
}
