import {createSelector} from "@reduxjs/toolkit";
import AppState from '../../../App.state';
export const selectCustomerActivityHistoryState = (state: AppState) => state.customerActivityHistory;
export const selectHistoryListLoading = createSelector(
    selectCustomerActivityHistoryState,
    state => state.listLoaded
);
export const selectHistoriesTableData = createSelector(
    selectCustomerActivityHistoryState,
    state => state.tableData
);
export const selectPagination = createSelector(
    selectCustomerActivityHistoryState,
    state => state.meta,
);
