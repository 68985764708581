import {Button, Popover} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useTranslation} from "react-i18next";
import WithParamsLink from "../../common/components/WithParamsLink";
import { RouterEnum } from "../../config/router.enum";
import fontAwesomeIcons from "../../utils/fontAwesomeIcons";

const FeedbackContent = () => {
    const {t} = useTranslation("common");
    return (
        <div className={'w-240'}>
            <WithParamsLink className={'w-full flex items-center p-16 hover:bg-mainLight'}
                            to={`/workflow/${RouterEnum.tickets}/create?workflow_id=30`}
                            target={"_blank"}>
                <FontAwesomeIcon className="text-main text-20 pr-12" icon={['fas', 'comment-arrow-up']}/>
                <span>{t("send-feedback")}</span>
            </WithParamsLink>
            <WithParamsLink className={'w-full flex items-center p-16 hover:bg-mainLight'}
                            target={"_blank"}
                            to={`/workflow/${RouterEnum.tickets}/follow-list?workflow_ids=30`}>
                <FontAwesomeIcon className="text-main text-20 pr-12" icon={['fas', 'list-check']}/>
                <span>{t("process-feedback")}</span>
            </WithParamsLink>
        </div>
    );
}

const Feedbacks = () => {
    return (
            <Popover content={FeedbackContent} placement="bottomRight" trigger="click">
                <div className="mr-12">
                    <Button
                        type="text"
                        className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
                    >
                        <FontAwesomeIcon className="text-grey21 text-20" icon={fontAwesomeIcons.farCircleEnvelope}/>
                    </Button>
                </div>
            </Popover>
    );
}

export default Feedbacks;