import AppState from "../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Ticket} from "../../../models/ticket";
import {NodeModel} from "../../../models/node-model";
import {FilterItem} from "../../../models/reporting-builder-model";


const selectTicketListState = (state: AppState) => state.ticketList;

export const selectTicketListLoading = createSelector(
    selectTicketListState,
    state => state.loading,
);
export const selectTicketSubTableLoading = (ticketId: number) => createSelector(
    selectTicketListState,
    state => state.subTableLoading[ticketId],
);

export const selectTicketListTableData = createSelector(
    selectTicketListState,
    state => state.ids.map(id => state.tableData[id] as Ticket)
);

export const selectTicketIds = createSelector(
    selectTicketListState,
    state => state.ids,
);

export const selectNodesOfTicket = (ticketId: number) => createSelector(
    selectTicketListState,
    state => state.ticketNodeMapping[ticketId]?.map(nodeId => state.processingNodes[nodeId] as NodeModel)?.filter(node => !!node),
);

export const selectTicketListMeta = createSelector(
    selectTicketListState,
    state => state.meta,
);

export const selectNodeIsInteracting = (id: number) => createSelector(
    selectTicketListState,
    state => state.interactingNodeIds.includes(id)
);

export const selectNode = (id: number) => createSelector(
    selectTicketListState,
    state => state.processingNodes[id],
);

export const selectTotalTicket = createSelector(
    selectTicketListState,
    state => state.meta?.total ?? 0,
);

export const selectAdvanceSearchForm = createSelector(
    selectTicketListState,
    state => state.advanceSearchForm,
)

export const selectAdvancedSearchFilterWithCode = (code: string) => createSelector(
    selectTicketListState,
    state => state.advanceSearchForm?.filters?.find((value: FilterItem) => value?.code === code),
)