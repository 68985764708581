import {Note} from "../../../../models/note";
import {RouterEnum} from "../../../../common/enums";
import {filterParams} from "../../../../common/utils";
import axios from "../../../../common/axios";
import {Rest} from "../../../../common/models/rest";
import {BASE_URL} from "../../../../config/properties";

export class NoteService {
    list = (id: number, rawParams?: any) => {
        const params = rawParams ? filterParams(rawParams) : {};
        return axios.get<Rest<Note>>(
            `${BASE_URL}/${RouterEnum.note}/`,
            {params}
        );
    }

    create = (id: number, data: any) => {
        return axios.post<Rest<Note>>(`${BASE_URL}/${RouterEnum.note}/`, data);
    }

    delete = (id: number, data: any) => {
        return axios.delete<Rest<Note>>(`${BASE_URL}/${RouterEnum.note}/` + data.id, {data});
    }
}

const noteService = new NoteService();

export default noteService;
