import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";

export class CallService {
    getConfig = () => {
        return axios.get<Rest<any>>(
        `${BASE_URL}/engagement/call-config`,
        );
    }
    getLeadByPhone = (phone: string) => {
        return axios.get<Rest<any>>(
            `${BASE_URL}/engagement/lead-by-phone`,
            {
                params: {phone}
            }
        );
    }
}

const callService = new CallService();

export default callService;