import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {TicketListState, TicketParams} from "./state";
import {Rest} from "../../../common/models/rest";
import {Ticket} from "../../../models/ticket";
import {NodeModel} from "../../../models/node-model";
import {
    actionCompleted,
    assigningTicketFail,
    assigningTicketNode,
    completingActionFail,
    nodeCompleted,
    taskUpdated,
    updateTaskFail,
    updatingTask
} from "../TicketDetail/reducer";
import {HasIdModel} from "../../../common/models/has-id";
import {Draft} from "immer";
import {AdvanceSearchForm} from "../../../models/advance-search-form";
import {NodeType} from "../../../models/enums/node-type";

const filterIdFromInteracting = (state: TicketListState|Draft<TicketListState>, {payload}: PayloadAction<number>) => {
    state.interactingNodeIds = state.interactingNodeIds.filter(id => id !== payload);
}

const slice = createSlice({
    name: 'ticket/ticket',
    initialState: {...new TicketListState()},
    reducers: {
        refreshTicketList(state: TicketListState, {payload}: PayloadAction<TicketParams>) {
            state.loading = true;
        },
        ticketListLoaded(state: TicketListState, {payload}: PayloadAction<Rest<Ticket[]>>) {
            state.meta = payload.meta;

            const tableData: Dictionary<Ticket> = {};
            const ids: number[] = [];

            for (const d of payload.data) {
                ids.push(d.id);
                tableData[d.id] = d;
            }

            state.tableData = tableData;
            state.ids = ids;

            state.loading = false;
        },
        loadingTicketProcessingNode(state: TicketListState, {payload}: PayloadAction<number>) {
            state.subTableLoading[payload] = true;
        },
        ticketProcessingNodeLoaded(state: TicketListState, {payload}: PayloadAction<Rest<NodeModel[]>>) {
            const nodeIds = [];
            const nodes: Dictionary<NodeModel> = state.processingNodes;

            for (const node of payload.data ?? []) {
                if (node.is_employee_can_assign || node.is_employee_can_update_result || node.is_employee_can_next) {
                    if (node.type.toString() === NodeType.ASSIGN && !node.is_employee_can_update_result && !node.is_employee_can_next) continue;
                    nodeIds.push(node.id);
                    nodes[node.id] = node;
                }
            }
            if (nodeIds.length) {
                state.ticketNodeMapping[payload.data[0].ticket_id] = nodeIds;
            }
            state.processingNodes = nodes;
            state.subTableLoading[payload.data[0].ticket_id] = false;
        },
        emptyProcessingNode(state: TicketListState, {payload}: PayloadAction<number>) {
            state.subTableLoading[payload] = false;
        },
        loadingAdvanceSearchForm(state: TicketListState) {
            state.advanceSearchFormLoading = true;
        },
        loadedAdvanceSearchForm(state: TicketListState, {payload}: PayloadAction<Rest<AdvanceSearchForm>>) {
            state.advanceSearchForm = payload.data;
        },
        searchingAdvanceTickets(state: TicketListState, {payload}: PayloadAction<any>) {
            state.loading = true;
        },
        loadedFail(state) {
            state.loading = false;
        }
    },
    extraReducers: {
        [nodeCompleted.type]: (state, {payload}: PayloadAction<Rest<NodeModel>>) => {
            const node = payload.data;
            state.processingNodes[node.id] = node;
            filterIdFromInteracting(state, {payload: node.id, type: ''});
        },
        [assigningTicketFail.type]: filterIdFromInteracting,
        [completingActionFail.type]: filterIdFromInteracting,
        [actionCompleted.type]: (state, {payload}: PayloadAction<Rest<Ticket>>) => {
            const ticket = payload.data;

            state.ticketNodeMapping[ticket.id] = ticket.processing_nodes?.map(({id}) => id);
            for (const node of ticket?.processing_nodes ?? []) {
                if (node.is_employee_can_assign || node.is_employee_can_update_result || node.is_employee_can_next) {
                    if (node.type.toString() === NodeType.ASSIGN && !node.is_employee_can_update_result && !node.is_employee_can_next) continue;
                    state.processingNodes[node.id] = node;
                }
            }
            state.tableData[ticket.id] = ticket;
        },
        [updatingTask.type]: (state, {payload}: PayloadAction<HasIdModel>) => {
            const id = payload.id;
            if (!state.interactingNodeIds.includes(id)) {
                state.interactingNodeIds = [...state.interactingNodeIds, id];
            }
        },
        [taskUpdated.type]: (state, {payload}) => {
            filterIdFromInteracting(state, {payload: payload.data.id, type: ''});
        },
        [updateTaskFail.type]: (state, {payload}) => {
            filterIdFromInteracting(state, {payload: payload.id, type: ''});
        },
        [assigningTicketNode.type]: (state, {payload}: PayloadAction<HasIdModel>) => {
            const id = payload.id;
            if (!state.interactingNodeIds.includes(id)) {
                state.interactingNodeIds = [...state.interactingNodeIds, id];
            }
        },
    }
});

export const {
    refreshTicketList,
    ticketListLoaded,
    loadingTicketProcessingNode,
    ticketProcessingNodeLoaded,
    emptyProcessingNode,
    loadingAdvanceSearchForm,
    loadedAdvanceSearchForm,
    searchingAdvanceTickets,
    loadedFail,
} = slice.actions;

const ticketListReducer = slice.reducer;

export default ticketListReducer;
