import {Dictionary} from "@reduxjs/toolkit";
import {StateStatus} from "../../../common/enums";
import {Ticket} from "../../../models/ticket";
import {NodeModel} from "../../../models/node-model";
import {AdvanceSearchForm} from "../../../models/advance-search-form";

export class TicketParams {

}

export class TicketListState {
    loading: boolean = false;
    formLoading: boolean = false;
    subTableLoading: Dictionary<boolean> = {};
    ids:  number[] = [];
    tableData: Dictionary<Ticket> = {};

    ticketNodeMapping: Dictionary<number[]> = {};
    processingNodes: Dictionary<NodeModel> = {};
    message?: string = '';
    total?: number = 0;
    meta: PaginationType = {} as PaginationType;
    formStatus: StateStatus = 'idle';

    interactingNodeIds: number[] = [];
    advanceSearchFormLoading: boolean = false;
    advanceSearchForm: AdvanceSearchForm = {
        code: '',
        name: '',
        columns: [],
        filters: [],
    };
}
