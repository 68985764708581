import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CustomerDashboardState} from './state';
import { Rest } from "../../../common/models/rest";
import {PaginationMeta} from "../../../common/models/pagination-meta";

const slice = createSlice({
    name: 'customerDashboard',
    initialState: {...new CustomerDashboardState()},
    reducers: {
        refreshListOnlineBarCustomers(state: CustomerDashboardState) {
            state.onlineBarStatus = 'loading';
        },
        listOnlineBarCustomersLoaded(state: CustomerDashboardState, {payload}: PayloadAction<Rest<any>>) {
            const dataOnlineBar: [] = payload.data;
            let onlineBarMeta: PaginationMeta = payload.meta;
            return {
                ...state,
                onlineBarStatus: 'idle',
                dataOnlineBar,
                onlineBarMeta
            };
        },
    }
});

export const {
    refreshListOnlineBarCustomers,
    listOnlineBarCustomersLoaded,
} = slice.actions;

const customerDashboardReducer = slice.reducer;

export default customerDashboardReducer;
