import AppState from "../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Workflow} from "../../../models/workflow";

const selectWorkflowListState = (state: AppState) => state.workflowList;

export const selectWorkflowListLoading = createSelector(
    selectWorkflowListState,
    state => state.loading,
);

export const selectWorkflowListTableData = createSelector(
    selectWorkflowListState,
    state => state.ids.map(id => state.tableData[id] as Workflow)
);

export const selectAllWorkflows = createSelector(
    selectWorkflowListState,
    state => state.workflows
)

export const selectStatusWorkflows = createSelector(
    selectWorkflowListState,
    state => state.status
)

export const selectSavingWorkflow = createSelector(
    selectWorkflowListState,
    state => state.formStatus === 'loading'
)

export const selectWorkflowFormStatus = createSelector(
    selectWorkflowListState,
    state => state.formStatus
);

export const selectWorkflowListMeta = createSelector(
    selectWorkflowListState,
    state => state.meta,
);

export const selectCurrentNodeForm = (isCreate = false) => createSelector(
    selectWorkflowListState,
    state => isCreate ? undefined : state.currentNodeForm,
);

export const selectCurrentTicketForm = createSelector(
    selectWorkflowListState,
    state => state.currentTicketForm,
);

export const selectCurrentNodeFormLoading = createSelector(
    selectWorkflowListState,
    state => state.getNodeFormLoading,
);