import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomerStatisticState} from "./state";
import {Rest} from "../../common/models/rest";
import {CustomerStatistic} from "../../models/customer-statistic";
import {keyBy} from "lodash";


const slice = createSlice({
    name: 'customer-statistic',
    initialState: {...new CustomerStatisticState()},
    reducers: {
        loadingListCustomerStatistic(state, payload) {
            state.loading = true;
        },
        loadedListCustomerStatistic(state, {payload}: PayloadAction<Rest<CustomerStatistic[]>>) {
            state.loading = false;
            state.ids = payload.data.map((item: any) => item.id);
            state.tableData = keyBy(payload.data, 'id');
            state.message = payload.message;
            state.meta = payload.meta;
            state.total = payload.meta.total;
        }
    },
});

export const {
    loadingListCustomerStatistic,
    loadedListCustomerStatistic
} = slice.actions;

const customerStatistic = slice.reducer;

export default customerStatistic;