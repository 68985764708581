import {all, select, call, put, takeEvery} from 'redux-saga/effects';
import customerActivityHistoryService from '../services/activity-historiy'
import {
    refreshListHistories,
    listHistoriesLoaded
} from './reducer';
import {
    selectCustomerActivityHistoryState
} from './selector'
import {safeCall} from '../../../common/utils';
import {Rest} from '../../../common/models/rest';
import {CustomerActivityHistoryState} from "./state";

const sagas = [
    takeEvery(refreshListHistories, safeCall(function* ({payload}) {
        const { status }: CustomerActivityHistoryState = yield select(selectCustomerActivityHistoryState);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<any> = yield call(customerActivityHistoryService.list, payload);
        yield put(listHistoriesLoaded(response));
    })),
];


function* customerActivitySagas() {
    yield all(sagas);
}

export default customerActivitySagas;
