import {NetworkStatus} from "../../models/enums/network-status";
import {MicStatus} from "../../models/enums/mic-status";
interface CallConfig {
    internal_number: string,
    password: string,
    host: string
}

export class CallState {
    phoneNumber: string | undefined;
    contactName: string | undefined;
    isMuted: boolean = false;
    isCalling: boolean = false;
    isIncoming: boolean = false;
    isCallBarOpen: boolean = false;
    callStarted: boolean = false;
    callRinging: boolean = false;
    callingTime: number = 0;
    fetchingConfig: boolean = false;
    callConfig?: CallConfig | undefined = undefined;
    dtmf: string = '';
    networkStatus: NetworkStatus | undefined;
    micStatus: MicStatus | undefined;
}
