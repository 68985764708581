import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {loadingParamsData, ProcessorState} from "./state";
import {Rest} from "../../../common/models/rest";
import {Processor} from "../../../models/processor";
import {Employee} from "../../../models/employee";
import {Department} from "../../../models/department";
import {Position} from "../../../models/position";
import {Role} from "../../../models/role";

const slice = createSlice({
    name: 'processor',
    initialState: {...new ProcessorState()},
    reducers: {
        refreshListProcessor(state, {payload}: PayloadAction<any>) {
            state.status = 'loading'
        },
        initListProcessor(state, {payload}: PayloadAction<loadingParamsData>) {
            if (payload.value) {
                state.loadingParams.value = Array.from(new Set([...state.loadingParams.value ?? [], ...payload.value]));
            }
            if (payload.defaultValue) {
                state.loadingParams.defaultValue = Array.from(new Set([...state.loadingParams.defaultValue ?? [], ...payload.defaultValue]));
            }
            if (payload.type_select) {
                payload.type_select.forEach((item: any) => {
                    if (!state.loadingParams.type_select?.includes(item)) {
                        state.loadingParams.type_select?.push(item);
                    }
                });
            }
            state.status = 'loading'
        },
        listProcessorLoaded(state, {payload}: PayloadAction<Rest<Processor>>) {
            const processors = payload.data;
            let employeeIds: number[] = [];
            let employees: Dictionary<Employee> = {};
            processors.employees?.forEach((emp: Employee) => {
                employeeIds.push(emp.id);
                employees[emp.id] = emp;
                state.processors[emp.id] = emp;
                if (!state.processorIds.includes(emp.id)) {
                    state.processorIds.push(emp.id);
                }
            });
            state.employees = employees;
            state.employeeIds = employeeIds;

            let departmentIds: number[] = [];
            let departments: Dictionary<Department> = {};
            processors.departments?.forEach((dep: Department) => {
                departmentIds.push(dep.id);
                departments[dep.id] = dep;
                state.processors['D' + dep.id] = dep;
                if (!state.processorIds.includes('D' + dep.id)) {
                    state.processorIds.push('D' + dep.id);
                }
            });
            state.departments = departments;
            state.departmentIds = departmentIds;

            let positionIds: number[] = [];
            let positions: Dictionary<Position> = {};
            processors.positions?.forEach((pos: Position) => {
                positionIds.push(pos.id);
                positions[pos.id] = pos;
                state.processors['P' + pos.id] = pos;
                if (!state.processorIds.includes('P' + pos.id)) {
                    state.processorIds.push('P' + pos.id);
                }
            });
            state.positions = positions;
            state.positionIds = positionIds;

            let roleIds: number[] = [];
            let roles: Dictionary<Role> = {};
            processors.roles?.forEach((role: Role) => {
                roleIds.push(role.id);
                roles[role.id] = role;
                state.processors['R' + role.id] = role;
                if (!state.processorIds.includes('R' + role.id)) {
                    state.processorIds.push('R' + role.id);
                }
            });
            state.roles = roles;
            state.roleIds = roleIds;

            state.status = 'idle'
            state.loadingParams = {
                value: [],
                defaultValue: [],
                type_select: []
            }
        },
    }

});

export const {
    refreshListProcessor,
    listProcessorLoaded,
    initListProcessor
} = slice.actions;

const processorReducer = slice.reducer;

export default processorReducer;
