import {Form} from "antd";
import {Rest} from "../models/rest";
import {useEffect, useLayoutEffect, useRef} from "react";
import {useFormSyncWithBackendValidationErrors} from "../backend-util/store";
import {FieldData} from "rc-field-form/es/interface";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {StateStatus} from "../enums";
import {useSelector} from "react-redux";

export interface UseAutoloadFormProps<IdType> {
    id?: IdType;
    loader?: (id: IdType) => Promise<Rest<any>>;
    pipe?: (value: any, isClone?: any) => any;
    syncBackendError?: boolean;
    prompt?: boolean | string;
    formStatusSelector?: (state: any) => StateStatus;
    isClone?: any;
}

function useAutoloadForm<T = number>({id, loader, pipe, syncBackendError, prompt, formStatusSelector, isClone}: UseAutoloadFormProps<T>) {
    const [form] = Form.useForm();
    const {block} = useHistory();
    const {t} = useTranslation('common');
    const formStatus = useSelector(formStatusSelector ?? (() => 'idle' as StateStatus));
    const formStatusRef = useRef<StateStatus>('idle');

    useLayoutEffect(() => {
        const oldStatus = formStatusRef.current;

        if (oldStatus === 'loading' && formStatus === 'success') {
            form.setFields(Object.entries(form.getFieldsValue()).map(([name, value]) => ({
                name,
                value,
                touched: false,
            })))
        }

        formStatusRef.current = formStatus;
    }, [form, formStatusRef, formStatus]);

    useEffect(() => {
        if (id) {
            loader?.(id)
                .then(response => {
                    const val = pipe ? pipe(response.data, isClone) : response.data;
                    const fields: FieldData[] = Object.entries(val).map(([name, value]) => ({
                        name, value, touched: false
                    }));
                    form.setFields(fields);
                })
                .catch(e => console.log(e));
        } else {
            form.resetFields();
        }
    }, [id, loader, form, pipe, isClone]);

    useEffect(() => {
        if (prompt) {
            const beforeUnload = (e?: BeforeUnloadEvent) => {
                if (form.isFieldsTouched()) {
                    e = e || window.event;
                    e?.preventDefault();
                    // For IE and Firefox prior to version 4
                    if (e) {
                        e.returnValue = "";
                    }

                    // For Safari
                    return "";
                }
            };
            window.addEventListener('beforeunload', beforeUnload);

            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            }
        }
    }, [prompt, form]);

    useLayoutEffect(() => {
        const doBlock = prompt ? block((location, action) => {
            if (form.isFieldsTouched()) {
                const message = typeof prompt === "string"
                    ? prompt
                    : t('block_navigate_when_use_form');
                return message as string;
            }
        }) : () => 1;

        return () => {
            doBlock();
        };
    }, [prompt, form, block, t]);


    useFormSyncWithBackendValidationErrors(form, syncBackendError);

    return form;
}

export default useAutoloadForm;
