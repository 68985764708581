import AppState from "../../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {TicketLog} from "../../../../models/ticket-log";

export const selectTicketLogState = (state: AppState) => state.ticketLog;


export const selectAllTicketTimeline = createSelector(
    selectTicketLogState,
    state => state.idsTimeline.map(id => state.entitiesTimeline[id] as TicketLog) ?? [],
)

export const selectTicketTimelineStatus = createSelector(
    selectTicketLogState,
    state => state.statusTimeline,
)

export const selectAllTicketLog = createSelector(
    selectTicketLogState,
    state => state.ids.map(id => state.entities[id] as TicketLog)
)

export const selectTicketLogLoading = createSelector(
    selectTicketLogState,
    state => state.status === 'loading'
)

export const selectTotalTicketLog = createSelector(
    selectTicketLogState,
    state => state.meta.total
)

export const selectCurrentPageTicketLog = createSelector(
    selectTicketLogState,
    state => state.meta.current_page
)

export const selectTicketLogNextPageParams = createSelector(
    selectTicketLogState,
    state => ({
        ticketId: state.currentTicketId,
        page: state.meta.current_page + 1,
    })
);

export const selectTicketTimelineIsCollapse = (collapseId: number) => createSelector(
    selectTicketLogState,
    state => state.isCollapse[collapseId],
)

export const selectAutoLoadingTicketTimeline = createSelector(
    selectTicketLogState,
    state => state.autoLoadingTicketTimeline
)

export const selectCreateTicketLog = createSelector(
    selectTicketLogState,
    state => (state.ids.map(id => state.entities[id] as TicketLog) ?? []).reverse().at(0)
)