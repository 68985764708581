export const APP_PREFIX = process?.env?.REACT_APP_PREFIX || '';
export const DOMAIN = process?.env?.REACT_APP_BASE_DOMAIN || '';
export const API_URL = `api`;
export const BASE_URL = `${API_URL}/v1`;
export const APP_DEBUG = process?.env?.NODE_ENV === 'development';
export const STORAGE_PATH = process?.env?.REACT_APP_STORAGE_PATH ?? 'https://static-ved.topcv.vn';
export const FOLDER_WORKING = process?.env?.REACT_APP_FOLDER_WORKING ?? 'working';
export const AUTH_STORAGE_KEY = process?.env?.REACT_APP_AUTH_STORAGE_KEY || 'token';
export const RICH_EDITOR_API_KEY = process?.env?.REACT_APP_TINY_MCE_KEY ?? '';
export const LOGIN_URL = `${DOMAIN}/auth/login`;
export const SSE_TRACKING_URL = `${DOMAIN}/api/stream`;

export const SRP_URL = process?.env?.SRP_URL ?? 'https://tuyendung.topcv.vn';
export const MS_DOC_PREVIEW_URL = process?.env?.REACT_APP_MS_DOC_PREVIEW_URL ?? 'https://view.officeapps.live.com/op/embed.aspx?src=';
export const FILE_UPLOAD_ACCEPT_EXT = '.jpg,.jpeg,.png,.gif,.doc,.docx,.xls,.xlsx,.txt,.pdf,.webp,.JPG,.JPEG,.PNG,.GIF,.DOC,.DOCX,.XLS,.XLSX,.TXT,.PDF,.WEBP,.mp4,.wmv,.mov,.avi,.mkv,.MP4,.WMV,.MOV,.AVI,.MKV';
export const FILE_UPLOAD_MAX_SIZE = 20971520; // 20MB to bytes
export const FILE_UPLOAD_ACCEPT_EXCEL = '.XLS,.XLSX,.xls,.xlsx';
export const FEEDBACK_WORKFLOW_ID = process?.env?.REACT_APP_FEEDBACK_WORKFLOW_ID ?? 0;
export const LOCAL_STORAGE_VERSION_KEY = 'VERSION_LOCAL_KEY';
export const APP_VERSION = process.env.REACT_APP_VERSION ?? 'v1.0.0';
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENV ?? 'local';
export const COUNT_UNREAD_TICKETS = 'COUNT_UNREAD_TICKETS';
export const WORKFLOW_PRINT_ID = process.env.REACT_APP_WOKKFLOW_PRINT_ID ?? 77;
