export enum NodeType {
    CANCEL = '0',
    START = '1',
    TASK = '10',
    ACTION = '20',
    AND = '40',
    OR = '30',
    DONE = '100',
    EDGE = '50',
    ASSIGN = '12',
}
