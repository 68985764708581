
export interface SelectEntry {
    value: any;
    label: string;
}

function enumToSelectEntries(data: any): SelectEntry[] {
    return Object.entries(data)
        .filter(entry => isNaN(Number(entry[0])))
        .map(entry => ({
            value: entry[1],
            label: entry[0] as string
        }));
}

export default enumToSelectEntries;
