import {useAppTitleValue} from "../../common/title";
import {Tooltip} from "antd";

export default function Title() {
    const title = useAppTitleValue();

  return (
      <Tooltip title={title}>
          <div
              className="hidden md:block text-black font-bold ml-43 theme-page-title overflow-ellipsis-1"
              style={{fontSize: '1.1vw', height: "auto"}}
          >
              {title}
          </div>
      </Tooltip>);
}
