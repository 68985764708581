import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {DepartmentState} from "./state";
import {Rest} from "../../../common/models/rest";
import {Department} from "../../../models/department";
import {Position} from "../../../models/position";

const initialState = {...new DepartmentState()};

const slice = createSlice({
    name: 'employee/department',
    initialState,
    reducers: {
        load(state) {
            state.listStatus = 'loading';
        },
        loaded(state, {payload}: PayloadAction<Rest<Department[]>>) {
            let root: Department = {} as any;
            const entities: Dictionary<Department> = {};
            const childMapping: Dictionary<number[]> = {};
            const posMapping: Dictionary<number[]> = {};
            const positions: Dictionary<Position> = {};
            for (const department of payload.data) {
                if (department.is_root) {
                    root = department;
                }
                entities[department.id] = department;

                if (department.parent_id) {
                    if (!childMapping[department.parent_id]) {
                        childMapping[department.parent_id] = [];
                    }
                    childMapping[department.parent_id]?.push(department.id);
                }

                posMapping[department.id] = department.positions?.map(po => po.id) ?? [];
                department.positions?.forEach(po => positions[po.id] = po);
            }
            return {
                ...state,
                rootId: root.id,
                entities,
                childMapping,
                posMapping,
                positions,
                listStatus: 'success',
            }
        },
        submit(state, {payload}: PayloadAction<any>) {
            state.formStatus = 'loading';
        },
        submitFailed(state) {
            state.formStatus = 'idle';
        },
        updated(state, {payload}: PayloadAction<Department>) {
            const old = state.entities[payload.id];
            if (old) {
                if (old.parent_id !== payload.parent_id) {
                    if (old.parent_id) {
                        state.childMapping[old.parent_id] = state.childMapping[old.parent_id]
                            ?.filter(i => i !== old.id);
                    }
                }
            }

            if (payload.parent_id) {
                if (!state.childMapping[payload.parent_id]?.includes(payload.id)) {
                    state.childMapping[payload.parent_id] = [
                        ...state.childMapping[payload.parent_id] ?? [],
                        payload.id
                    ]
                }
            }

            state.entities[payload.id] = payload;
            state.posMapping[payload.id] = payload.positions?.map(({id}) => id);
            payload.positions?.forEach(po => {
                state.positions[po.id] = po;
            });
            state.formStatus = 'success';
        },
        doDelete(state, {payload}: PayloadAction<number>) {
            state.interactingIds.push(payload);
        },
        deleteSuccess(state, {payload}: PayloadAction<number>) {
            state.interactingIds = state.interactingIds.filter(i => i !== payload);
            const entity = state.entities[payload];
            if (entity) {
                if (entity?.parent_id) {
                    state.childMapping[entity.parent_id] = state.childMapping[entity.parent_id]
                        ?.filter(i => i !== entity.id);
                }
                state.childMapping[entity.id]?.forEach(i => delete state.entities[i]);
                state.posMapping[entity.id]?.forEach(i => delete state.positions[i]);
                delete state.childMapping[entity.id];
                delete state.positions[entity.id];
            }
        },
        deleteFailed(state, {payload}: PayloadAction<number>) {
            state.interactingIds = state.interactingIds.filter(i => i !== payload);
        }
    },
});

export const departmentActions = slice.actions;

const departmentReducer = slice.reducer;

export default departmentReducer;
