import AppState from "../../App.state";
import {createSelector} from "@reduxjs/toolkit";

export const selectMarketInsightState = (state: AppState) => state.marketInsight;

export const selectLoadFilterStatus = createSelector(
    selectMarketInsightState,
    state => state.loadFilterStatus
)

export const selectFiltersByCode = (code: string) => createSelector(
    selectMarketInsightState,
    state => state.filters[code]?.filters ?? []
)

export const selectConfigChart = createSelector(
    selectMarketInsightState,
    state => state.configChart
)

export const selectLoadChartConfigStatus = createSelector(
    selectMarketInsightState,
    state => state.loadChartConfigStatus
)