import {CRUDService} from '../../../common/service';
import {Category} from '../../../models/category';
import {CategoryFormData, CategoryParams} from './state';
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";
import {RouterEnum} from "../../../config/router.enum";
import {CommonStatus} from "../../../common/enums";
import {filterParams} from "../../../common/utils";

interface SetActiveParams {
    id: number;
    status: CommonStatus;
}

export class CategoryService extends CRUDService<Category, CategoryFormData, CategoryParams> {
    getNameSpace(): string {
        return 'workflow-categories';
    }
    getAll(rawParams: any) {
        const params = rawParams ? filterParams(rawParams) : {};
        return axios.get<any, Rest<Category>>(
            `${BASE_URL}/${RouterEnum.workflowCategory}/get-all`,
            {params}
        );
    }
    softDelete(id: number) {
        return axios.delete<any, Rest<Category>>(`${BASE_URL}/${RouterEnum.workflowCategory}/soft-delete/${id}`);
    }
    setActive({id, status}: SetActiveParams) {
        return axios.put<any, Rest<Category>>(
            `${BASE_URL}/${RouterEnum.workflowCategory}/${id}/set-active`,
            {status}
        );
    }
}

const categoryService = new CategoryService();

export default categoryService;
