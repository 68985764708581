import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {safeCall} from "../../../../common/utils";
import {listServiceActivationLoaded, refreshListServiceActivation} from "./reducer";
import {ServiceActivationState} from "./state";
import {selectServiceActivationState} from "./selector";
import serviceActivationService from "./service";
import {ActivityLog} from "../../../../models/activity-log";
import {Rest} from "../../../../common/models/rest";

const sagas = [
    takeEvery(refreshListServiceActivation, safeCall(function* ({payload}) {
        const {status}: ServiceActivationState = yield select(selectServiceActivationState);
        if (status !== 'loading') {
            return;
        }

        const response: Rest<ActivityLog[]> = yield call(serviceActivationService.list, payload);
        yield put(listServiceActivationLoaded(response));
    }))
];

function* serviceActivationSagas(){
    yield all(sagas);
}

export default serviceActivationSagas;
