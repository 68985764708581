import AppState from "../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Notification} from "../../models/notification";

const selectNotificationState = (state: AppState) => state.notification;

export const selectAllNotification = createSelector(
    selectNotificationState,
    state => state.allIds.map(id => state.allNotification[id] as Notification),
);

export const selectNotificationTableData = createSelector(
    selectNotificationState,
    state => state.ids.map(id => state.tableData[id] as Notification),
);

export const selectAllNotificationLoading = createSelector(
    selectNotificationState,
    state => state.allNotificationLoading,
);

export const selectNotificationTableLoading = createSelector(
    selectNotificationState,
    state => state.loading,
);

export const selectNotificationTableMeta = createSelector(
    selectNotificationState,
    state => state.meta,
);

export const selectCountNewNotification = createSelector(
    selectNotificationState,
    state => state.countNew,
);

export const selectHasNotification = createSelector(
    selectNotificationState,
    state => !!state.allIds.length,
);

export const selectMarkAsReadAllLoading = createSelector(
    selectNotificationState,
    state => state.markAsReadAllLoading,
);