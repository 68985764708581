import {Rest} from '../../common/models/rest';
import {API_URL} from '../../config/properties';
import {LoginFormData} from './state';
import axios from '../../common/axios';
import {AuthResponse} from '../../models/auth-response';
import {User} from "../../models/user";
import {Employee} from "../../models/employee";

export class AuthService {
    login(value: LoginFormData) {
        return axios.post<Rest<AuthResponse>>(`${API_URL}/auth/login`, value);
    }

    userData() {
        return axios.get<User>(`${API_URL}/auth/me`);
    }

    employeeData() {
        return axios.get<Employee>(`${API_URL}/auth/employee`);
    }

    refreshToken () {
        return axios.post<any, Rest<AuthResponse>>(`${API_URL}/auth/refresh-token`);
    }

    logout() {
        return axios.post(`${API_URL}/auth/logout`);
    }

    registerDevice(token: string) {
        return axios.post(`${API_URL}/v1/notifications/devices`, {token});
    }
}

const authService = new AuthService();

export default authService;
