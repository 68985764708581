import {Dictionary} from "@reduxjs/toolkit";
import {CustomerConnect} from "../../../models/customer-connect";
import {StateStatus} from "../../../common/enums";
import {OnlineStatus} from "../../../models/enums/online-status";

export class CustomerParams {
    employer_name?: string = undefined;
    employer_id?: number = undefined;
    company_name?: string = undefined;
    url?: string = undefined;
    page_title?: string = undefined;
    online_only?: number = OnlineStatus.ALL;
    limit?: number = 10;
    page?: number = 1;
}

export class CustomerRealtimeState {
    ids: string[] = [];
    expandedMapping: Dictionary<string[]> = {};
    entities: Dictionary<CustomerConnect> = {};
    params = new CustomerParams();
    status: StateStatus = 'idle';
    expandedStatus: StateStatus = 'idle';
    meta: PaginationType = {} as PaginationType;
    expandedMeta: Dictionary<PaginationType> = {};
    listLoaded = false;
    listExpandLoaded = false;
    expandedKeys: string[] = [];
}
