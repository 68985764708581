export enum Resources {
    ACTION_REALTIME = 'ACTION_REALTIME',
    ACTIVITY_HISTORY = 'ACTIVITY_HISTORY',
    MARKET_INSIGHT = 'MARKET_INSIGHT',
    CUSTOMER_STATISTICS = 'CUSTOMER_STATISTICS',
    CUSTOMER_PROFILE = 'CUSTOMER_PROFILE',
    CUSTOMER_INFO = 'CUSTOMER_INFO',
    PAGE_VIEW_HISTORY = 'PAGE_VIEW_HISTORY',
    CALL_HISTORY = 'CALL_HISTORY',
    NOTE_HISTORY = 'NOTE_HISTORY',
    PURCHASE_HISTORY = 'PURCHASE_HISTORY',
    PAYMENT_HISTORY = 'PAYMENT_HISTORY',
    ACTIVITY_LOG_HISTORY = 'ACTIVITY_LOG_HISTORY',
    ENGAGE_CALL = 'ENGAGE_CALL',
    EMPLOYEE = 'EMPLOYEE',
    ORGANIZATION_DEPARTMENT = 'ORGANIZATION_DEPARTMENT',
    WORKSPACE = 'WORKSPACE',
    ACCOUNT = 'ACCOUNT',
    WORKFLOW = 'WORKFLOW',
    TICKET = 'TICKET',
    WORKFLOW_CATEGORY = 'WORKFLOW_CATEGORY',
    ROLE = 'ROLE',
    REPORT_TEMPLATE = 'REPORT_TEMPLATE',
    REPORT_INSTANCE = 'REPORT_INSTANCE',
}
