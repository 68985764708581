import {StateStatus} from "../../../../common/enums";
import {Dictionary} from "@reduxjs/toolkit";
import {Purchase} from "../../../../models/purchase";

export class PurchaseHistoryState {
    status: StateStatus = 'idle';
    entities: Dictionary<Purchase> = {};
    ids: number[] = [];
    meta: PaginationType = {} as PaginationType;
}
