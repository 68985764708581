import {CRUDService} from "../../../../common/service";
import {ActivityLog} from "../../../../models/activity-log";

class ServiceActivationService extends CRUDService<ActivityLog> {
    getNameSpace(): string {
        return "sales/activity-logs";
    }

}

const serviceActivationService = new ServiceActivationService();

export default serviceActivationService;
