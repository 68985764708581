import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    editingNotifications,
    loadedNotificationSetterList,
    loadingFailed,
    loadingNotificationSetterList
} from "./reducer";
import {safeCall} from "../../common/utils";
import {NotificationSetterGroup} from "../../models/enums/notification-setter-group";
import {Rest} from "../../common/models/rest";
import settingNotificationService from "./service";
import {selectNotificationSettingLoading} from "./selector";
import {SettingNotificationState} from "./state";
import {message} from 'antd';
import i18next from "i18next";

const sagas = [
    takeLeading(loadingNotificationSetterList, safeCall(function* () {
        const loading: SettingNotificationState = yield select(selectNotificationSettingLoading);
        if (!loading) {
            return;
        }
        const response: Rest<NotificationSetterGroup[]> = yield call(settingNotificationService.list);
        yield put(loadedNotificationSetterList(response));
    })),
    takeLeading(editingNotifications, function* ({payload}) {
        const loading: SettingNotificationState = yield select(selectNotificationSettingLoading);
        if (!loading) {
            return;
        }
        try {
            const response: Rest<NotificationSetterGroup[]> = yield call(settingNotificationService.massEdit, payload);
            yield put(loadedNotificationSetterList(response));
            message.success(i18next.t<string>('notification:save_notification_successfully_successfully'));
        } catch (e) {
            yield put(loadingFailed());
        }
    }),
];

function* settingNotificationSagas() {
    yield all(sagas);
}

export default settingNotificationSagas;
