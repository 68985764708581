import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CallLogState} from "./state";
import {Rest} from "../../../common/models/rest";
import {CallLog} from "../../../models/call-log";

const slice = createSlice({
    name: 'call-log',
    initialState: {...new CallLogState()},
    reducers: {
        refreshCallLog(state, {payload}: PayloadAction<any>) {
            state.status = 'loading';
        },
        callLogLoaded(state, {payload}: PayloadAction<Rest<CallLog[]>>) {
            return {
                ...state,
                listLoaded: true,
                status: 'idle',
                tableData: payload.data,
                meta: payload.meta
            };
        }
    }
});

export const {
    refreshCallLog,
    callLogLoaded
} = slice.actions;

const callLogReducer = slice.reducer;

export default callLogReducer;
