import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RoleListState} from "./state";
import {Role} from "../../../models/role";
import {keyBy, remove} from "lodash";
import {Rest} from "../../../common/models/rest";

const slice = createSlice({
    name: 'roleList',
    initialState: {...new RoleListState()},
    reducers: {
        gettingRoleList(state: RoleListState, {payload}: PayloadAction<number[]|undefined>) {
            state.loading = true;
        },
        roleListLoaded(state: RoleListState, {payload}: PayloadAction<Rest<Role[]>>) {
            state.loading = false;
            state.roles = payload.data;
        },
        refreshRoles(state: RoleListState, {payload}: PayloadAction<any>) {
            state.loading = true;
        },
        rolesLoaded(state: RoleListState, {payload}: PayloadAction<Rest<Role[]>>) {
            state.meta = payload.meta;
            state.ids = payload.data.map(({id}) => (id));
            state.list = keyBy(payload.data, 'id');
            state.loading = false;
        },
        getRole(state: RoleListState, {payload}: PayloadAction<number>) {
            state.loading = true;
        },
        getRoleDone(state: RoleListState, {payload}: PayloadAction<Rest<Role>>) {
            state.loading = false;
            state.currentRole = payload.data;
        },
        savingRole(state: RoleListState, {payload}: PayloadAction<any>) {
            state.formStatus = 'loading';
        },
        savingRoleSuccess(state: RoleListState, {payload}: PayloadAction<Rest<Role>>) {
            state.formStatus = 'success';
            state.currentRole = payload.data;
        },
        savingRoleFailed(state: RoleListState) {
            state.formStatus = 'idle';
        },
        deleteRole(state: RoleListState, {payload}: PayloadAction<number>) {
            state.loading = true;
        },
        deleteRoleSuccess(state: RoleListState, {payload}: PayloadAction<number>) {
            delete state.list[payload];
            remove(state.ids, (id) => id === payload);
            state.loading = false;
        },
        deleteRoleFailed(state: RoleListState) {
            state.loading = false;
        }
    }
});

export const {
    gettingRoleList,
    roleListLoaded,
    refreshRoles,
    rolesLoaded,
    getRole,
    getRoleDone,
    savingRole,
    savingRoleSuccess,
    savingRoleFailed,
    deleteRole,
    deleteRoleSuccess,
    deleteRoleFailed
} = slice.actions;

const roleListReducer = slice.reducer;

export default roleListReducer;
