import {Notification} from "../../models/notification";
import {CRUDService} from "../../common/service";
import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";

export class NotificationService extends CRUDService<Notification> {
    getNameSpace(): string {
        return "notifications";
    }

    countNew = () => {
        return axios.get<Rest<any>>(`${BASE_URL}/${this.getNameSpace()}/count-new`);
    };

    markRead = (noti: Notification, read: boolean) => {
        return axios.put<Rest<any>>(`${BASE_URL}/${this.getNameSpace()}/${noti.id}/mark-as-read`, {read});
    };
    markReadAll = (read: boolean) => {
        return axios.put<Rest<any>>(`${BASE_URL}/${this.getNameSpace()}/mark-as-read-all`, {read});
    };
}

const notificationService = new NotificationService();

export default notificationService;