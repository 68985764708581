import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AccountListState} from "./state";
import {Account} from "../../models/account";

const slice = createSlice({
    name: 'accountList',
    initialState: {...new AccountListState()},
    reducers: {
        gettingAccountList(state: AccountListState) {
            state.loading = true;
        },
        accountListLoaded(state: AccountListState, {payload}: PayloadAction<Account[]>) {
            const data: Account[] = [];
            const getIds = (accounts: Account[]) => {
                return accounts.map(account => {
                    const {...cloneData} = account;
                    data[account.id] = cloneData;
                    return account.id;
                })
            };
            const ids = getIds(Object.values(payload));

            state.data = data;
            state.ids = ids;
            state.loading = false;
        },
        gettingAllAccountList(state: AccountListState) {
            state.loading = true;
        },
    }
});

export const {
    gettingAccountList,
    accountListLoaded,
    gettingAllAccountList,
} = slice.actions;

const accountListReducer = slice.reducer;

export default accountListReducer;
