import AppState from "../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {CustomerStatistic} from "../../models/customer-statistic";


export const selectCustomerStatisticState = (state: AppState) => state.customerStatistic;

export const selectCustomerStatisticLoading = createSelector(
    selectCustomerStatisticState,
    state => state.loading
);

export const selectCustomerStatisticTableData = createSelector(
    selectCustomerStatisticState,
    state => state.ids.map((id) => state.tableData[id] as CustomerStatistic)
);

export const selectCustomerStatisticMeta = createSelector(
    selectCustomerStatisticState,
    state => state.meta,
)