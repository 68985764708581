import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {
    deleteTemplate,
    deleteTemplateFail,
    deleteTemplateSuccess,
    loadedReportTemplateList,
    loadingReportTemplateList,
} from "./reducer";
import {safeCall} from "../../../common/utils";
import {selectReportTemplateState} from "./selectors";
import {ReportTemplateState} from "./state";
import {ReportTemplate} from "../../../models/report-template";
import {Rest} from "../../../common/models/rest";
import reportTemplateService from "./service";
import {
    saveTemplate,
    saveTemplateSuccess,
} from "./reducer";
import {message} from "antd";
import i18next from "i18next";
import history from "../../../config/history";

const sagas = [
    takeLatest(loadingReportTemplateList, safeCall(function* ({payload}) {
        const {listLoading}: ReportTemplateState = yield select(selectReportTemplateState);
        if (!listLoading) {
            return;
        }
        const response: Rest<ReportTemplate[]> = yield call(reportTemplateService.list, payload);
        yield put(loadedReportTemplateList(response));
    })),
    takeLatest(saveTemplate, safeCall(function* ({payload}) {
        const {saveTemplateStatus}: ReportTemplateState = yield select(selectReportTemplateState);
        if (saveTemplateStatus !== 'loading') {
            return;
        }
        try {
            const response: Rest<any> = yield payload.id ? call(reportTemplateService.edit, payload.id, {...payload, templateId: undefined}) : call(reportTemplateService.create, payload);
            yield put(saveTemplateSuccess(response));
            message.success(i18next.t<string>(payload.id ? 'reporting:update_template_success' : 'reporting:create_template_success'));
            history.push(`./${response.data.id}`);
        } catch (e) {
            throw e;
        }
    })),
    takeLatest(deleteTemplate, safeCall(function* ({payload}) {
        const {deleteTemplateStatus}: ReportTemplateState = yield select(selectReportTemplateState);
        if (deleteTemplateStatus !== 'loading') {
            return;
        }
        try {
            yield call(reportTemplateService.delete, payload);
            yield put(deleteTemplateSuccess());
            message.success(i18next.t<string>('reporting:delete_template_success'));
            history.push('./');
        } catch (e) {
            yield put(deleteTemplateFail());
        }
    })),
];

function* reportTemplateSagas() {
    yield all(sagas);
}

export default reportTemplateSagas;