import {Dictionary} from '@reduxjs/toolkit';
import {StateStatus} from '../../../common/enums';
import {CallLog} from "../../../models/call-log";

export class CallLogState {
    formStatus: StateStatus = 'idle';
    entities: Dictionary<any> = {};
    childrenMapper: Dictionary<number[]> = {};
    tableData: CallLog[] = [];
    meta: PaginationType = {} as PaginationType;
    listLoaded = false;
    status: StateStatus = 'idle';
}
