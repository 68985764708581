import AppState from "../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {ErrorCode} from "../../../models/enums/error-code";
import {Node} from "reactflow";

export const selectTicketDetailState = (state: AppState) => state.ticketDetail;

export const selectTicket = createSelector(
    selectTicketDetailState,
    state => state.ticket
)

export const selectStatus = createSelector(
    selectTicketDetailState,
    state => state.status
)

export const selectTicketNodes = createSelector(
    selectTicketDetailState,
    state => state.nodeIds.map(id => state.nodes[id])
)

export const selectTicketNodesObj = createSelector(
    selectTicketDetailState,
    state => state.nodes
)

export const selectTicketWorkflow = createSelector(
    selectTicketDetailState,
    state => state.workflow
)

export const selectTicketElements = createSelector(
    selectTicketDetailState,
    state => state.elements
)

export const selectTicketComments = createSelector(
    selectTicketDetailState,
    state => state.comments ?? []
)

export const selectCurrentNodeId = createSelector(
    selectTicketDetailState,
    state => state.currentNodeId
)

export const selectCurrentNode = createSelector(
    selectTicketDetailState,
    state => state.currentNode
)

export const selectCreateCommentStatus = createSelector(
    selectTicketDetailState,
    state => state.createCommentStatus
)

export const selectCancelTicketStatus = createSelector(
    selectTicketDetailState,
    state => state.cancelTicketStatus
)

export const selectCancelTicketLoading = createSelector(
    selectTicketDetailState,
    state => state.cancelTicketStatus === 'loading'
)

export const selectTicketFormLoading = createSelector(
    selectTicketDetailState,
    state => state.creating === 'loading',
)

export const selectTicketFormStatus = createSelector(
    selectTicketDetailState,
    state => state.creating
)

export const selectWorkflowLoading = createSelector(
    selectTicketDetailState,
    state => state.workflowStatus === 'loading'
)

export const selectNodeDetailLoading = createSelector(
    selectTicketDetailState,
    state => state.nodeDetailStatus === 'loading'
)

export const selectTicketLoading = createSelector(
    selectTicketDetailState,
    state => state.status === 'loading'
)

export const selectTicketLiteLoading = createSelector(
    selectTicketDetailState,
    state => state.statusLite === 'loading'
)

export const selectGetCurrentNodeStatusLoading = createSelector(
    selectTicketDetailState,
    state => state.getCurrentNodeStatus === 'loading'
)

export const selectCommentLoading = createSelector(
    selectTicketDetailState,
    state => state.commentStatus === 'loading'
)

export const selectUpdateTaskLoading = createSelector(
    selectTicketDetailState,
    state => state.updateTaskStatus === 'loading'
)

export const selectUpdateTaskStatus = createSelector(
    selectTicketDetailState,
    state => state.updateTaskStatus
)

export const selectNextActionLoading = createSelector(
    selectTicketDetailState,
    state => state.nextActionStatus === 'loading'
)

export const selectNextActionStatus = createSelector(
    selectTicketDetailState,
    state => state.nextActionStatus
)

export const selectTicketNodeAssignLoading = createSelector(
    selectTicketDetailState,
    state => state.ticketNodeAssignStatus === 'loading'
)

export const selectTicketNodeAssignMultipleLoading = createSelector(
    selectTicketDetailState,
    state => state.ticketNodeAssignMultipleStatus === 'loading'
)

export const selectTicketNodeAssignMultipleSuccess = createSelector(
    selectTicketDetailState,
    state => state.ticketNodeAssignMultipleStatus === 'success'
)

export const selectFollowTicketLoading = createSelector(
    selectTicketDetailState,
    state => state.receiveNotificationStatus === 'loading'
)

export const selectEditFollowingStatus = createSelector(
    selectTicketDetailState,
    state => state.editFollowingStatus
)

export const selectMentionTicketLoading = createSelector(
    selectTicketDetailState,
    state => state.mentionTicketStatus === 'loading'
)

export const selectMentionTickets = createSelector(
    selectTicketDetailState,
    state => state.mentionTicketIds.map(id => state.mentionTickets[id])
)

export const selectCanAccessTicket = createSelector(
    selectTicketDetailState,
    state => state.refreshTicketCode !== ErrorCode.TICKET_POLICY_SHOW
)

export const selectRequestFollowStatus = createSelector(
    selectTicketDetailState,
    state => state.requestFollowStatus
)

export const selectProcessingNodes = createSelector(
    selectTicketDetailState,
        state => state.processingNodes
)

export const selectFormResultByNodeId = (id: string) => createSelector(
    selectTicketDetailState,
    state => state.formResultNodes[id]
)

export const selectTicketNodeAssignStatus = createSelector(
    selectTicketDetailState,
    state => state.ticketNodeAssignStatus
)

export const selectNodesCanProcess = createSelector(
    selectTicketDetailState,
    state => state.processingNodes.filter((node: Node) => node.data?.is_employee_can_update_result || node.data?.is_employee_can_next)
)

export const selectFormResultNodeLoading = createSelector(
    selectTicketDetailState,
    state => state.formResultNodeLoading
)