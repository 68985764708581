import {all, call, put, select, debounce, takeLeading} from "redux-saga/effects";
import {
    deleteRole,
    deleteRoleFailed,
    deleteRoleSuccess,
    getRole,
    getRoleDone,
    gettingRoleList,
    refreshRoles,
    roleListLoaded,
    rolesLoaded,
    savingRole,
    savingRoleFailed,
    savingRoleSuccess,
} from "./reducer";
import {safeCall} from "../../../common/utils";
import {RoleListState} from "./state";
import {selectFormStatusLoading, selectRoleListLoading} from "./selectors";
import {Rest} from "../../../common/models/rest";
import {Role} from "../../../models/role";
import roleListService from "./service";
import {message} from "antd";
import i18next from "i18next";
import history from "../../../config/history";


const sagas = [
    debounce(100, gettingRoleList, safeCall(function* ({payload}) {
        const { loading } : RoleListState = yield select(selectRoleListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Role[]> = yield call(roleListService.getALlRole);
        yield put(roleListLoaded(response));
    })),
    takeLeading(refreshRoles, safeCall(function* ({payload}) {
        const { loading } : RoleListState = yield select(selectRoleListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Role[]> = yield call(roleListService.list, payload);
        yield put(rolesLoaded(response));
    })),
    takeLeading(getRole, safeCall(function* ({payload}) {
        const { loading } : RoleListState = yield select(selectRoleListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Role> = yield call(roleListService.single, payload);
        yield put(getRoleDone(response));
    })),
    takeLeading(savingRole, safeCall(function* ({payload}) {
        const loading = yield select(selectFormStatusLoading);
        if (!loading) {
            return;
        }
        try {
            let response: Rest<Role> | undefined;
            if (typeof payload.id !== 'undefined' && payload.id !== null && !isNaN(Number(payload.id))) {
                response = yield call(roleListService.edit, payload.id, payload);
            } else {
                response = yield call(roleListService.create, payload);
            }

            if (response?.status) {
                yield put(savingRoleSuccess(response));
                message.success(i18next.t<string>('common:success'));
                history.push('.');
            } else {
                yield put(savingRoleFailed());
                message.error(i18next.t<string>('common:fail'));
            }
        } catch (e) {
            yield put(savingRoleFailed());
            message.error(i18next.t<string>('common:fail'));
        }
    })),
    takeLeading(deleteRole, safeCall(function* ({payload}) {
        const { loading } : RoleListState = yield select(selectRoleListLoading);
        if (loading) {
            return;
        }
        try {
            const response: number = yield call(roleListService.delete, payload);
            if (response) {
                yield put(deleteRoleSuccess(payload));
                message.success(i18next.t<string>('common:success'));
             }
        } catch (e) {
            yield put(deleteRoleFailed());
        }
    }))
];

function* roleSagas() {
    yield all(sagas);
}

export default roleSagas;
