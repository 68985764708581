import {matchPath, useHistory} from "react-router-dom";
import {useMemo} from "react";

type AppDetail = {
    app?: string;
};

export const useCurrentPathName = (parentRoute: any, routes: any[]) => {
    const history = useHistory();
    return useMemo(() => {
        const r = [...routes].reverse();
        for (const route of r) {
            const matched = matchPath<AppDetail>(
                history.location.pathname,
                {
                    path: '/' + [...parentRoute.commands, route.path].filter(p => !!p).join('/'),
                },
            );
            if (matched) {
                return `${parentRoute.absolutePath}/${route.path}`;
            }
        }
        return '';
    }, [history.location.pathname, parentRoute.absolutePath, parentRoute.commands, routes]);
}
