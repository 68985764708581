import {StateStatus} from "../../../../common/enums";
import {Dictionary} from "@reduxjs/toolkit";
import {Payment} from "../../../../models/payment";

export class PaymentHistoryState {
    status: StateStatus = 'idle';
    entities: Dictionary<Payment> = {};
    ids: number[] = [];
    meta: PaginationType = {} as PaginationType;
}
