import {Dictionary} from "@reduxjs/toolkit";
import {PaginationMeta} from "../../common/models/pagination-meta";
import {StateStatus} from "../../common/enums";
import {Notification} from "../../models/notification";

export class NotificationParams {
    limit?: number = undefined;
}

export class NotificationState {
    allNotification: Dictionary<Notification> = {};
    allNotificationLoading: boolean = false;
    allIds: number[] = [];
    allNotiMeta?: PaginationMeta;

    countNewLoading: boolean = false;
    countNew: number = 0;

    loading: boolean = false;
    formLoading: boolean = false;
    ids:  number[] = [];
    tableData: Dictionary<Notification> = {};
    message: string = '';
    total: number = 0;
    meta: PaginationType = {} as PaginationType;
    formStatus: StateStatus = 'idle';

    markAsReadAllLoading: boolean = false;
}