import {Role} from "../../../models/role";
import {Dictionary} from "@reduxjs/toolkit";
import {StateStatus} from "../../../common/enums";


export class RoleListState {
    ids: number[] = [];
    list: Dictionary<Role> = {};
    loading: boolean = false;
    roles: Role[] = [];
    meta: PaginationType = {} as PaginationType;
    formStatus: StateStatus = 'idle';
    currentRole?: Role = undefined;
}
