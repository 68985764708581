import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EditNotificationSetting, SettingNotificationState} from "./state";
import {NotificationSetterGroup} from "../../models/enums/notification-setter-group";
import {Rest} from "../../common/models/rest";

const slice = createSlice({
    name: 'settingNotification',
    initialState: {...new SettingNotificationState()},
    reducers: {
        loadingNotificationSetterList(state: SettingNotificationState) {
            state.loading = true;
        },
        loadedNotificationSetterList(state: SettingNotificationState, {payload}: PayloadAction<Rest<NotificationSetterGroup[]>>) {
            state.ids = [];
            state.group = {};

            payload.data.forEach((notiSetterGr: NotificationSetterGroup, index) => {
                state.ids.push(index);
                state.group[index] = notiSetterGr;
            });

            state.loading = false;
        },
        editingNotifications(state, {payload}: PayloadAction<EditNotificationSetting[]>) {
            state.loading = true;
        },
        loadingFailed(state) {
            state.loading = false;
        }
    }
});

export const {
    loadingNotificationSetterList,
    loadedNotificationSetterList,
    editingNotifications,
    loadingFailed,
} = slice.actions;

const settingNotificationReducer = slice.reducer;

export default settingNotificationReducer;