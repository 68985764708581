import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {ManagementState, EmployeeParams} from "./state";
import {Rest} from "../../../common/models/rest";
import {Employee, EmployeeAddData} from "../../../models/employee";
import {CommonStatus} from "../../../common/enums";

const slice = createSlice({
    name: 'employee/management',
    initialState: {...new ManagementState()},
    reducers: {
        refreshEmployeeList(state: ManagementState, {payload}: PayloadAction<EmployeeParams>) {
            state.loading = true;
        },
        employeeListLoaded(state: ManagementState, {payload}: PayloadAction<Rest<Employee[]>>) {
            const tableData: Dictionary<Employee> = {};
            const getIds = (employeeData: Employee[]) => {
                return employeeData.map(employee => {
                    const {...restInfo} = employee;
                    if (restInfo) tableData[employee.id] = restInfo;
                    return employee.id;
                })
            };
            const ids = getIds(payload.data);
            return {
                ...state,
                tableData,
                ids,
                meta: payload.meta,
                total: payload.meta.total,
                loading: false,
            };
        },
        addingEmployee(state: ManagementState, {payload}: PayloadAction<EmployeeAddData>) {
            state.formLoading = true;
            state.formStatus = 'loading';
        },
        employeeAdded(state: ManagementState, {payload}: PayloadAction<Employee>) {
            if (!state.ids.includes(payload.id)) {
                state.ids = [payload.id, ...state.ids];
            }
            state.formStatus = 'success';
            state.tableData[payload.id] = payload;
            state.formLoading = false;
        },
        addingEmployeeFailed(state) {
            state.formStatus = 'idle';
        },

        changingEmployeeStatus(state: ManagementState, {payload}: PayloadAction<{id: number, status: CommonStatus}>) {
            state.formLoading = true;
        },
        employeeStatusChanged(state: ManagementState, {payload}: PayloadAction<Employee>) {
            let tableData = {...state.tableData}
            let employee: any = {...tableData[payload.id]};
            employee.status = payload.status;
            tableData[payload.id] = employee;
            return {
                ...state,
                tableData,
                formLoading: false,
            }
        },
        changingEmployeeRole(state: ManagementState, {payload}: PayloadAction<{id: number, role_id: number}>) {
            state.formLoading = true;
        },
    }
});

export const {
    refreshEmployeeList,
    employeeListLoaded,
    addingEmployee,
    employeeAdded,
    changingEmployeeStatus,
    employeeStatusChanged,
    changingEmployeeRole,
    addingEmployeeFailed,
} = slice.actions;

const employeeManagement = slice.reducer;

export default employeeManagement;
