import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    allNotificationLoaded,
    countNewLoaded,
    loadAllNotification, loadCountNew,
    loadNotificationTable, markAsReadAll, markAsReadAllCompleted, markNotificationAsRead,
    notificationTableLoaded
} from "./reducer";
import {safeCall} from "../../common/utils";
import {NotificationState} from "./state";
import {selectAllNotificationLoading, selectMarkAsReadAllLoading, selectNotificationTableLoading} from "./selectors";
import {Rest} from "../../common/models/rest";
import {Notification} from "../../models/notification";
import notificationService from "./service";

const saga = [
    takeLeading(loadAllNotification, safeCall(function* ({payload}) {
        const {loading}: NotificationState = yield select(selectAllNotificationLoading);
        if (loading) {
            return;
        }
        const response: Rest<Notification[]> = yield call(notificationService.list, payload);
        yield put(allNotificationLoaded(response));
    })),
    takeLeading(loadCountNew, safeCall(function* () {
        const response: Rest<any> = yield call(notificationService.countNew);
        yield put(countNewLoaded(response));
    })),
    takeLeading(loadNotificationTable, safeCall(function* ({payload}) {
        const {loading}: NotificationState = yield select(selectNotificationTableLoading);
        if (loading) {
            return;
        }
        const response: Rest<Notification[]> = yield call(notificationService.list, payload);
        yield put(notificationTableLoaded(response));
    })),
    takeLeading(markAsReadAll, safeCall(function* ({payload}) {
        const {loading}: NotificationState = yield select(selectMarkAsReadAllLoading);
        if (loading) {
            return;
        }
        const response: Rest<any> = yield call(notificationService.markReadAll, payload);
        yield put(markAsReadAllCompleted(response));
        yield put(loadCountNew());
    })),
    takeLeading(markNotificationAsRead, safeCall(function* ({payload}) {
        yield put(loadCountNew());
    }))

];

function* notificationSagas() {
    yield all(saga);
}

export default notificationSagas;
