import i18next from 'i18next';
import backend from 'i18next-http-backend';
import {APP_PREFIX, APP_VERSION} from './properties';

i18next
    .use(backend)
    // .use(reactI18nextModule)
    .init({
        ns: [
            'enums',
            'application',
            'common',
            'customer-activity',
            'customer-profile',
            'call',
            'employer',
            'employee',
            'workflow',
            'validation',
            'ticket',
            'workflow_category',
            'dynamic-form',
            'reporting'
        ],
        interpolation: {escapeValue: false},  // React already does escaping
        lng: 'vi',                              // language to use
        fallbackLng: 'vi',
        // whitelist: ['vi', 'en'],
        backend: {
            loadPath: `${APP_PREFIX}/i18n/{{lng}}/{{ns}}.json?v=${APP_VERSION}`,
        }
    });
export default i18next;
