import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {NoteState} from "./state";
import {Note} from "../../../../models/note";
import {Rest} from "../../../../common/models/rest";

const slice = createSlice({
    name: 'note',
    initialState: {...new NoteState()},
    reducers: {
        refreshNoteInfo(state: NoteState, {payload}: PayloadAction<any>) {
            state.status = "loading";
        },
        noteInfoLoaded(state: NoteState, {payload}: PayloadAction<Rest<Note[]>>) {
            let entities: Dictionary<Note> = {};
            const getIds = (notes: Note[]) => {
                return notes.map(note => {
                    entities[note.id] = note;
                    return note.id;
                })
            };
            const ids = getIds(payload.data);
            return {
                ...state,
                ids,
                entities,
                status: "idle",
                meta: payload.meta,
            }
        },
        submittingAddNote(state: NoteState, {payload}: PayloadAction<any>) {
            state.submitting = 'loading';
        },
        addedNote(state: NoteState, {payload}: PayloadAction<Rest<Note>>) {
            state.ids.unshift(payload.data.id);
            state.entities[payload.data.id] = payload.data;
            state.submitting = 'success';
        },
        deletingNote(state: NoteState, {payload}: PayloadAction<any>) {
            state.deleting = 'loading';
        },
        deletedNote(state: NoteState, {payload}: PayloadAction<Rest<Note>>) {
            state.ids = state.ids.filter(id => Number(payload.data.id) !== Number(id));
            delete state.entities[payload.data.id];
            state.deleting = 'idle';
        }
    }
})

export const {
    refreshNoteInfo,
    noteInfoLoaded,
    submittingAddNote,
    addedNote,
    deletingNote,
    deletedNote
} = slice.actions;

export default slice.reducer;
