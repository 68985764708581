import AppState from "../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {ChangeLog} from "../../models/change-log";

const selectChangeLogsState = (state: AppState) => state.changeLogs;

export const selectAllChangeLogs = createSelector(
    selectChangeLogsState,
    state => state.changeLogsIds.map(id => state.changeLogs[id] as ChangeLog),
);

export const selectLoadingChangeLogs = createSelector(
    selectChangeLogsState,
    state => state.loadingStatus
)

export const selectLastChangeLogs = createSelector(
    selectChangeLogsState,
    state => state.changeLogs[Math.max(...state.changeLogsIds)] as ChangeLog
)