import AppState from '../../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Employee} from "../../../models/employee";

const selectEmployeeManagementState = (state: AppState) => state.employee.management;


export const selectEmployeeListTableData = createSelector(
    selectEmployeeManagementState,
    state => state.ids.map(id => state.tableData[id] as Employee),
);

export const selectEmployeeListTotal = createSelector(
    selectEmployeeManagementState,
    state => state.total,
)

export const selectEmployeeListLoading = createSelector(
    selectEmployeeManagementState,
    state => state.loading,
)

export const selectEmployeeListFormLoading = createSelector(
    selectEmployeeManagementState,
    state => state.formStatus === 'loading',
)

export const selectEmployeeMeta = createSelector(
    selectEmployeeManagementState,
    state => state.meta,
)

export const selectEmployeeById = (id?: number) => createSelector(
    selectEmployeeManagementState,
    state => state.tableData[id ?? 0],
)

export const selectEmployeeFormStatus = createSelector(
    selectEmployeeManagementState,
    state => state.formStatus,
)
