import {Button, Input, Popover} from "antd";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/pro-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {selectCallConfig, selectMicStatus, selectNetworkStatus} from "./selectors";
import {setCall, setMicStatus, setNetworkStatus} from "./reducer";
import {NetworkStatus} from "../../models/enums/network-status";
import {MicStatus} from "../../models/enums/mic-status";
import {useTranslation} from "react-i18next";

const QuickCallButton = () => {
    const {t} = useTranslation('call')
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const hide = () => {
        setVisible(false)
    };
    const call = () => {
        hide();
        dispatch(setCall({phoneNumber, isIncoming: false}));
    }

    const handleVisibleChange = (visible: any) => {
        setVisible(visible)
    };
    const callConfig = useSelector(selectCallConfig)
    useEffect(() => {
        if (navigator.onLine) {
            dispatch(setNetworkStatus(NetworkStatus.OK));
        } else {
            dispatch(setNetworkStatus(NetworkStatus.NOT_OK));
        }
        try {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
                dispatch(setMicStatus(MicStatus.OK));
            }).catch((err) => {
                dispatch(setMicStatus(MicStatus.CAN_NOT_USE));
            });
        } catch (error) {
            dispatch(setMicStatus(MicStatus.CAN_NOT_CALL));
        }
    }, [dispatch])
    const networkStatus = useSelector(selectNetworkStatus)
    const micStatus = useSelector(selectMicStatus)
    const popoverContent = (
        <div>
            <div>
                <div>
                    Host: {callConfig?.host}
                </div>
                <div>
                    Máy nhánh: {callConfig?.internal_number}
                </div>
            </div>
            <hr color={'#F4F4F4'}/>
            <div>
                {
                    networkStatus === NetworkStatus.OK
                        ? <div className="text-main">{t('network_ok')}</div>
                        : <div className="text-red">{t('network_not_ok')}</div>
                }
                {
                    micStatus === MicStatus.OK
                        ? <div className="text-main">{t('mic_ok')}</div>
                        : (micStatus === MicStatus.CAN_NOT_USE
                            ?<div className="text-red">{t('mic_can_not_use')}</div>
                            :<div className="text-red">{t('mic_can_not_phone')}</div>)
                }
            </div>
            <div className="mt-5">
                <Input value={phoneNumber} onChange={(e) => {setPhoneNumber(e.target.value)}} placeholder="SĐT" />
            </div>
            <div className="mt-5 text-right">
                <Button onClick={call}>
                    <FontAwesomeIcon icon={faPhone}/>
                </Button>
            </div>
        </div>
    )
    return <div className="mr-12">
        <Popover
            content={popoverContent}
            title={t('phone')}
            trigger="click"
            open={visible}
            onOpenChange={handleVisibleChange}
        >
            <Button
                type="text"
                className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
            >
                <FontAwesomeIcon className="text-grey21 text-20" icon={faPhone}/>
            </Button>
        </Popover>
    </div>
}
export default QuickCallButton;

