import {CRUDService} from "../../../../common/service";
import axios from "../../../../common/axios";
import {Rest} from "../../../../common/models/rest";
import {BASE_URL} from "../../../../config/properties";
import {Payment} from "../../../../models/payment";

class PaymentHistoryService extends CRUDService<Payment> {
    getNameSpace(): string {
        return "";
    }

    getAllPayment(params: any) {
        return axios.get<Rest<Payment[]>>(
            `${BASE_URL}/sales/payments`,
            {params}
        );
    }
}

const paymentHistoryService = new PaymentHistoryService();

export default paymentHistoryService;
