import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    loadAllChangeLogs,
    loadAllChangeLogsDone
} from "./reducer";
import {safeCall} from "../../common/utils";
import {ChangeLogsState} from "./state";
import {Rest} from "../../common/models/rest";
import {ChangeLog} from "../../models/change-log";
import changeLogService from "./service";
import {selectLoadingChangeLogs} from "./selectors";

const saga = [
    takeLeading(loadAllChangeLogs, safeCall(function* () {
        const {loadingStatus}: ChangeLogsState = yield select(selectLoadingChangeLogs);
        if (loadingStatus) {
            return;
        }
        const response: Rest<ChangeLog[]> = yield call(changeLogService.list);
        yield put(loadAllChangeLogsDone(response));
    })),
];

function* changeLogsSagas() {
    yield all(saga);
}

export default changeLogsSagas;
