import {Dictionary} from "@reduxjs/toolkit";
import {StateStatus} from "../../../../common/enums";
import {TicketLog} from "../../../../models/ticket-log";

export class TicketLogState {
    entities: Dictionary<TicketLog> = {};
    status: StateStatus = 'idle';
    ids: number[] = [];
    meta: PaginationType = {} as PaginationType;
    currentTicketId?: number| string;

    entitiesTimeline: Dictionary<TicketLog> = {};
    statusTimeline: StateStatus = 'idle';
    idsTimeline: number[] = [];
    currentTicketIdTimeline?: number| string;

    isCollapse: Dictionary<boolean> = {};

    autoLoadingTicketTimeline: boolean = false;
}