import {all, select, call, put, takeLeading} from 'redux-saga/effects';
import { ApplicationState } from './state';
import { selectApplicationState } from './selectors';
import applicationService from './service';
import {
    listApplications,
    listApplicationsLoaded
} from './reducer';
import {safeCall} from '../../common/utils';
import {Rest} from '../../common/models/rest';
import {Application} from "../../models/application";

const sagas = [
    takeLeading(listApplications, safeCall(function* () {
        const { status }: ApplicationState = yield select(selectApplicationState);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<Application[]> = yield call(applicationService.getDataApplicationState);
        yield put(listApplicationsLoaded(response));
    })),
];

function* applicationSagas() {
    yield all(sagas);
}

export default applicationSagas;
