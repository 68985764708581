import {all, select, call, put, takeLeading, takeEvery} from 'redux-saga/effects';
import {CategoryState} from './state';
import {
    selectCategoryListLoading,
    selectCategoryState,
    selectSetActiveLoading,
    selectSingleCategory
} from './selectors';
import categoryService from './service';
import {message} from 'antd';
import {
    categoryFormSubmitted,
    singleCategoryAdded,
    submitCategoryForm,
    listCategoriesLoaded,
    categoryDeleted,
    categoryDeleteFailed,
    deleteCategory,
    refreshListCategories,
    loadingListCategories,
    categoriesListLoaded,
    softDeletingCategory,
    categorySoftDeleted,
    settingActive, setActiveDone, setActiveFail, categorySoftDeletedFail,
} from './reducer';
import {safeCall} from '../../../common/utils';
import {Rest} from '../../../common/models/rest';
import {Category} from '../../../models/category';
import i18next from "i18next";

const sagas = [
    takeLeading(submitCategoryForm, function* ({payload}) {
        try {
            const id = payload.id;
            const response: Rest<Category> = id
                ? yield call(categoryService.edit, id, payload)
                : yield call(categoryService.create, payload)
            const msg = id ? i18next.t<string>('workflow_category:update_successfully') : i18next.t<string>('workflow_category:create_successfully');
            message.success(msg);
            yield all([
                put(categoryFormSubmitted(true)),
                put(singleCategoryAdded(response.data, false))
            ]);
        } catch (e) {
            yield put(categoryFormSubmitted(false))
        }
    }),

    takeLeading(refreshListCategories, safeCall(function* ({payload}) {
        const { status }: CategoryState = yield select(selectCategoryState);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<Category[]> = yield call(categoryService.list, payload);
        yield put(listCategoriesLoaded(response));
    })),

    takeEvery(deleteCategory, function* ({payload}) {
        try {
            const category: Category = yield select(selectSingleCategory(payload));
            yield call(categoryService.delete, category.id);
            yield put(categoryDeleted(payload));
            message.success((i18next.t<string>('workflow_category:delete_successfully')));
        } catch (e) {
            yield put(categoryDeleteFailed(payload))
        }
    }),
    takeLeading(loadingListCategories, function* ({payload}) {
        const status: boolean = yield select(selectCategoryListLoading);

        if (!status) {
            return;
        }
        const response: Rest<Category[]> = yield call(categoryService.getAll, payload);
        yield put(categoriesListLoaded(response));
    }),
    takeLeading(softDeletingCategory, function* ({payload}) {
        const status: boolean = yield select(selectCategoryListLoading);

        if (!status || !payload.id) {
            return;
        }
        try {
            const response: number = yield call(categoryService.softDelete, payload.id);
            yield put(categorySoftDeleted(response));
            message.success(i18next.t<string>('workflow_category:delete_successfully'));
        } catch (e) {
            yield put(categorySoftDeletedFail());
        }
    }),
    takeLeading(settingActive, function* ({payload}) {
        try {
            const loading: boolean = yield select(selectSetActiveLoading);

            if (!loading || !payload.id) {
                return;
            }

            const response: Rest<any> = yield call(categoryService.setActive, payload);
            yield put(setActiveDone(response));
            message.success(i18next.t<string>('common:updateStatusWorkflowCategorySuccess'));
        } catch (e) {
            yield put(setActiveFail())
        }

    }),
];


function* categorySagas() {
    yield all(sagas);
}

export default categorySagas;
