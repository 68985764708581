import {Dictionary} from "@reduxjs/toolkit";
import {Employee} from "../../../models/employee";
import {Position} from "../../../models/position";
import {Department} from "../../../models/department";
import {StateStatus} from "../../../common/enums";
import {ProcessorType} from "../../../models/enums/processor-type";
import {Role} from "../../../models/role";
import {ProcessorDefault} from "../../../models/enums/processor-default";

export interface loadingParamsData {
    defaultValue?: any,
    value?: (string | number)[],
    type_select?: ProcessorType[]
}

export interface DefaultSelectData {
    id: number;
    name: string;
}

export interface ProcessorPreviousData {
    label: string,
    value: string,
}

export class ProcessorState {
    employees: Dictionary<Employee> = {};
    employeeIds: number[] = [];
    positions: Dictionary<Position> = {};
    positionIds: number[] = [];
    departments: Dictionary<Department> = {};
    departmentIds: number[] = [];
    roles: Dictionary<Role> = {};
    roleIds: number[] = [];
    defaultSelectIds: number[] = [ProcessorDefault.TICKET_CREATOR, ProcessorDefault.DIRECT_MANAGER, ProcessorDefault.PROCESSOR_PREVIOUS_TASK]
    defaultSelects: Dictionary<DefaultSelectData> = {
        [ProcessorDefault.TICKET_CREATOR]: {
            id: ProcessorDefault.TICKET_CREATOR,
            name: "Người tạo ticket"
        },
        [ProcessorDefault.DIRECT_MANAGER]: {
            id: ProcessorDefault.DIRECT_MANAGER,
            name: "Người lãnh đạo trực tiếp"
        },
        [ProcessorDefault.PROCESSOR_PREVIOUS_TASK]: {
            id: ProcessorDefault.PROCESSOR_PREVIOUS_TASK,
            name: "Người xử lý bước trước"
        }
    }
    status: StateStatus = 'idle';
    processors: Dictionary<Employee | Position | Department | Role> = {};
    processorIds: Array<any> = [];
    loadingParams: loadingParamsData = {
        defaultValue: [],
        value: [],
        type_select: []
    };
}
