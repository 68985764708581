import { Dictionary } from '@reduxjs/toolkit';
import { StateStatus } from '../../common/enums';
import { Application } from '../../models/application';

export class ApplicationState {
    ids: number[] = [];
    entities: Dictionary<Application> = {};
    status: StateStatus = 'idle';
    slugs: string[] = [];
}
