import {memo, useMemo} from "react";
import {generatePath, Link, LinkProps, useRouteMatch} from "react-router-dom";

const WithParamsLink = memo(({to, useRelative, ...props}: LinkProps & {useRelative?: boolean}) => {
    const {path, params} = useRouteMatch();

    const realTo = useMemo(() => {
        return typeof to !== 'string'
            ? to
            : generatePath<string>(
                useRelative
                    ? `${path}/${to}`
                    : to,
                params
            )
    }, [to, useRelative, path, params]);

    return <Link to={realTo} {...props} />;
});

export default WithParamsLink;
