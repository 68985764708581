import {createSelector} from "@reduxjs/toolkit";
import AppState from '../../../App.state';
export const callLogState = (state: AppState) => state.callLog;
export const selectCallLogStatus = createSelector(
    callLogState,
    state => state.status
);
export const selectCallLogTableData = createSelector(
    callLogState,
    state => state.tableData
);

export const selectPagination = createSelector(
    callLogState,
    state => state.meta
);
