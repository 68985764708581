import {all, call, put, select, takeEvery, takeLeading} from "redux-saga/effects";
import {safeCall} from "../../../../common/utils";
import {
    listTicketLogLoaded,
    loadNextPageTicketLog,
    refreshListTicketLog,
    listTicketLogLoadMoreLoaded,
    loadingTicketTimeline, listTicketTimelineLoaded,
} from "./reducer";
import {TicketLogState} from "./state";
import {selectTicketLogNextPageParams, selectTicketLogState} from "./selectors";
import {Rest} from "../../../../common/models/rest";
import {TicketLog} from "../../../../models/ticket-log";
import ticketLogService from "./service";

function* load(payload: any, loadMore: boolean) {
    const {status}: TicketLogState = yield select(selectTicketLogState);
    if (status !== "loading") {
        return;
    }
    const response: Rest<TicketLog[]> = yield call(ticketLogService.getLogByID, payload);
    if (loadMore) {
        yield put(listTicketLogLoadMoreLoaded(response));
        return;
    }
    yield put(listTicketLogLoaded(response));
}


const sagas = [
    takeLeading(refreshListTicketLog, safeCall(function* ({payload}) {
        yield load(payload, false);
    })),

    takeLeading(loadNextPageTicketLog, safeCall(function* ({payload}) {
        const params: any = yield select(selectTicketLogNextPageParams);
        yield load({...params, ...payload}, true);
    })),

    takeEvery(loadingTicketTimeline, safeCall(function* ({payload}) {
        const response: Rest<TicketLog[]> = yield call(ticketLogService.getLogByID, payload);

        yield put(listTicketTimelineLoaded(response));
    })),
];

function* ticketLogSagas() {
    yield all(sagas);
}

export default ticketLogSagas;
