import {all, call, delay, put, takeLatest} from "redux-saga/effects";
import {
    backendJobRefreshed,
    backendJobReceived,
    backendJobRead,
    backendJobClose,
    backendRefreshTicketUnread, backendRefreshTicketUnreadDone
} from "./store";
import {safeCall} from "../utils";
import {Rest} from "../models/rest";
import backendService, {JobInfo} from "./service";
import ticketListService from "../../features/workflow/ticket/service";

const saga = [
    takeLatest(backendJobReceived, safeCall(function* ({payload}) {
        const response: Rest<JobInfo[]> = yield call(backendService.getJobs);
        yield put(backendJobRefreshed(response.data ?? []))
        if (!response.data?.length || !response.data.find((e: any) => e.status !== 'finished')) {
            return;
        }
        yield delay(1500);
        yield put(backendJobReceived(payload))
    })),
    takeLatest(backendJobRead, safeCall(function* ({payload}) {
        yield call(backendService.readJobs, payload);
        yield put(backendJobReceived(1));
    })),
    takeLatest(backendJobClose, safeCall(function* ({payload}) {
        yield put(backendJobReceived(1));
    })),
    takeLatest(backendRefreshTicketUnread, safeCall(function* ({payload}) {
        const data = {
            processing: ["E" + payload]
        };
        const resProcessTicket: Rest<any> = yield call(ticketListService.countUnreadTicketList, {
            path: "count-unread-process-list",
            ...data
        });

        const resFollowTicket: Rest<any> = yield call(ticketListService.countUnreadTicketList,{
            path: "count-unread-follow-list"
        });

        yield put(backendRefreshTicketUnreadDone((resProcessTicket.data?.count ?? 0) + resFollowTicket.data?.count ?? 0));
    })),
];

function* backendSagas() {
    yield all(saga);
}

export default backendSagas;
