import {all} from "redux-saga/effects";


function* employeeSagas() {
    yield all([

    ]);
}

export default employeeSagas;
