import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {TicketDetailState} from "./state";
import {Rest} from "../../../common/models/rest";
import {Ticket} from "../../../models/ticket";
import {Edge, MarkerType, Node} from "reactflow";
import {Comment} from "../../../models/comment";
import {Workflow} from "../../../models/workflow";
import {TicketNodeStatus} from "../../../models/enums/ticket-node-status";
import {findIndex, groupBy, keyBy, set} from "lodash";
import {FlowElement} from "../Diagram/type";
import {NodeType} from "../../../models/enums/node-type";
import {setNode} from '../utils/node';
import {NodeModel} from "../../../models/node-model";
import {HasIdModel} from "../../../common/models/has-id";
import {ErrorRequest} from "../../../common/models/error-request";
import uniqBy from "lodash/uniqBy";

interface FormattedElement {
    nodes: Node[];
    edges: Edge[];
    nodeIds: string[];
    nodesObj: Dictionary<Node>;
    processingNodes?: Node[];
}

const formatTicketElement = (payload: Rest<Ticket>): FormattedElement => {
    const nodeIds: string[] = [];
    const nodesObj: Dictionary<Node> = {};
    const processingNodes: Node[] = [];

    let edges: Edge[] = payload.data?.node_edges?.map((edge: any): Edge => {
        return {
            id: String(edge.id),
            type: 'edge',
            source: String(edge.source),
            target: String(edge.target),
            className: '',
            markerEnd: {
                type: MarkerType.ArrowClosed,
            }
        }
    }) ?? [];

    const edgeMap = groupBy(edges, 'source');
    const nodeMap = keyBy(payload.data?.nodes ?? [], 'id');

    const nodes = payload.data?.nodes?.map((node): Node => {
        const nodeId = String(node.id);
        nodeIds.push(nodeId);
        if (node.status === TicketNodeStatus.PROCESSED) {
            for (const edge of edgeMap[node.id] ?? []) {
                const target = nodeMap[edge.target];
                const source = nodeMap[edge.source];
                if (
                    source?.status === TicketNodeStatus.PROCESSED
                ) {
                    if (
                        ![NodeType.ACTION, NodeType.OR, NodeType.ASSIGN].includes(source?.type?.toString() as any)
                        && !target?.is_employee_can_update_result
                        && target?.status !== TicketNodeStatus.PROCESSED
                    ) {
                        continue;
                    }
                    edge.className = 'edge-processed'
                }
            }
        }
        if (node.status === TicketNodeStatus.CAN_PROCESS && String(node.type) === String(NodeType.TASK)) {
            edges = edges.map((edge) => {
                if (String(edge.source) === String(node.id)) {
                    return {
                        ...edge,
                        className: "edge-processing"
                    }
                }

                return edge;
            })
        }
        const nod: Node = setNode(node);
        nodesObj[nodeId] = nod;
        return nod;
    }) ?? [];

    payload.data?.processing_nodes?.forEach((node) => {
        const nod: Node = setNode(node);
        processingNodes.push(nod);
    });

    return {
        nodes,
        edges,
        nodeIds,
        nodesObj,
        processingNodes
    }
}

const getCurrentNodeId = (nodeIds: string[], nodes: Dictionary<Node>) => {
    return nodeIds.filter((id) =>
        nodes[id]?.type === NodeType.TASK &&
        nodes[id]?.data?.status === TicketNodeStatus.CAN_PROCESS &&
        nodes[id]?.data?.is_employee_can_update_result)?.[0];
}

const slice = createSlice({
    name: 'ticketDetail',
    initialState: {...new TicketDetailState()},
    reducers: {
        refreshTicket(state: TicketDetailState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
            state.refreshTicketCode = '';
        },
        refreshTicketLite(state: TicketDetailState, {payload}: PayloadAction<any>) {
            state.statusLite = 'loading';
            state.refreshTicketCode = '';
        },
        refreshGetCurrentNode(state: TicketDetailState, {payload}: PayloadAction<any>) {
            state.getCurrentNodeStatus = 'loading';
        },
        gotCurrentNode(state: TicketDetailState, {payload}: PayloadAction<any>) {
            if (payload?.data?.id) {
                state.currentNode = setNode(payload?.data);
                state.currentNodeId = state.currentNode?.id;
            }

            state.getCurrentNodeStatus = 'idle';
        },
        resetGetCurrentNodeStatus(state: TicketDetailState) {
            state.getCurrentNodeStatus = 'idle';
        },
        resetTicket(state) {
            state.ticket = {
                id: 0,
                name: '',
            }
            state.nodeIds = [];
            state.nodes = {};
            state.workflow = undefined;
            state.elements = [];
        },
        refreshTicketFail(state, {payload}: PayloadAction<ErrorRequest | undefined>) {
            state.refreshTicketCode = payload?.code ?? '';
            state.status = 'idle';
            state.statusLite = 'idle';
        },
        resetStatus(state) {
            state.status = 'idle';
        },
        ticketLoaded(state: TicketDetailState, {payload}: PayloadAction<Rest<Ticket>>) {
            state.ticket = payload.data;
            const formatedData = formatTicketElement(payload);
            state.nodeIds = formatedData.nodeIds;
            state.nodes = formatedData.nodesObj;
            state.processingNodes = formatedData.processingNodes ?? [];
            state.workflow = payload.data?.workflow ?? undefined;
            state.elements = [...formatedData.nodes, ...formatedData.edges];
            state.status = 'idle';
            state.statusLite = 'idle';
            state.creating = "success";
            if (formatedData.nodeIds.length) {
                state.currentNodeId = getCurrentNodeId(formatedData.nodeIds, formatedData.nodesObj);
            }
        },
        refreshComment(state: TicketDetailState, {payload}: PayloadAction<any>) {
            state.commentStatus = 'loading';
        },
        commentLoaded(state, {payload}: PayloadAction<Rest<Comment[]>>) {
            state.comments = payload.data;
            state.commentStatus = "success";
        },
        refreshWorkflow(state, {payload}: PayloadAction<any>) {
            state.workflowStatus = "loading";
        },
        workflowLoaded(state, {payload}: PayloadAction<Rest<Workflow>>) {
            const nodes = payload.data?.nodes?.map((node: any): Node => {
                return setNode(node);
            }) ?? [];

            const edges = payload.data?.node_edges?.map((edge: any): Edge => {
                return {
                    id: String(edge.id),
                    type: 'edge',
                    source: String(edge.source),
                    target: String(edge.target),
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    }
                }
            }) ?? [];
            state.elements = nodes.concat(edges);
            state.workflow = payload.data;
            state.workflowStatus = "idle";
        },
        creatingTicket(state, {payload}: PayloadAction<any>) {
            state.creating = "loading";
        },
        ticketCreateFailed(state) {
            state.creating = 'idle';
        },
        setCurrentNodeId(state, {payload}: PayloadAction<any>) {
            state.currentNodeId = payload.id
        },
        refreshNodeDetail(state, {payload}: PayloadAction<any>) {
            state.nodeDetailStatus = "loading";
        },
        nodeDetailLoaded(state, {payload}: PayloadAction<Rest<any>>) {
            const id: string = String(payload.data.id);
            const node: Node = setNode(payload.data);
            state.nodes[id] = node;
            state.nodeIds.push(id);
            //state.currentNode = node;
            state.nodeDetailStatus = "idle";
        },
        setCurrentNodeDetail(state, {payload}: PayloadAction<Node | undefined>) {
            state.currentNode = payload;
            state.nodeDetailStatus = "idle";
        },
        completingAction(state, {payload}: PayloadAction<any>) {
            state.nextActionStatus = "loading";
        },
        completingActionFail(state, {payload}: PayloadAction<number>) {
            state.nextActionStatus = "idle";
        },
        nodeCompleted(state, {payload}: PayloadAction<Rest<NodeModel>>) {
            const node: Node = setNode(payload.data);
            state.nodes[node.id] = node;
            const index = findIndex(state.elements, (elm: FlowElement) => elm.id === String(node.id))
            state.elements[index] = node;
            const newProcessingNodes: Node[] = [];
            state.processingNodes.forEach(nod => {
                if (nod.id === node.id) {
                    newProcessingNodes.push(node)
                } else {
                    newProcessingNodes.push(nod)
                }
            })
            state.processingNodes = [...newProcessingNodes];
            state.currentNode = node;
            state.ticketNodeAssignStatus = "success";
        },
        taskUpdated(state, {payload}: PayloadAction<Rest<any>>) {
            if (state.currentNode && state.currentNode.data) {
                state.currentNode.data.processing_note = payload.data.processing_note ?? '';
            }
            state.updateTaskStatus = "success";
        },
        actionCompleted(state, {payload}: PayloadAction<Rest<Ticket>>) {
            const formatedData = formatTicketElement(payload);
            state.nodeIds = formatedData.nodeIds;
            state.nodes = formatedData.nodesObj;
            state.elements = [...formatedData.nodes, ...formatedData.edges];

            state.currentNode = undefined;
            state.currentNodeId = undefined;
            const canNextProcessingNode = formatedData.processingNodes?.filter((node: Node) => node.data?.is_employee_can_update_result);
            if (canNextProcessingNode && canNextProcessingNode.length > 0) {
                state.currentNode = canNextProcessingNode[0];
                state.currentNodeId = state.currentNode.id;
            }

            state.processingNodes = formatedData.processingNodes ?? [];
            state.nextActionStatus = "idle";
            if (payload?.data?.status) {
                state.ticket.status = payload.data?.status;
            }
            state.updateTaskStatus = "success";
        },
        updatingTask(state, {payload}: PayloadAction<any>) {
            // state.updateTaskStatus = "loading";
            if (payload.nextTo) {
                state.nextActionStatus = "loading";
            }
        },
        updateTaskFail(state, {payload}: PayloadAction<HasIdModel>) {
            state.updateTaskStatus = "idle";
            state.nextActionStatus = 'idle';
        },
        createComment(state, {payload}: PayloadAction<any>) {
            state.createCommentStatus = 'loading';
        },
        createCommentDone(state, {payload}: PayloadAction<Rest<any>|undefined>) {
            if (payload?.data) {
                state.comments.push(payload.data);
            }
            state.createCommentStatus = 'success';
        },
        assigningTicketNode(state, {payload}: PayloadAction<any>) {
            state.ticketNodeAssignStatus = "loading";
        },
        assigningTicketFail(state, {payload}: PayloadAction<number>) {
            state.ticketNodeAssignStatus = "idle";
        },
        cancelTicket(state, {payload}: PayloadAction<any>) {
            state.cancelTicketStatus = 'loading';
        },
        cancelTicketFail(state) {
            state.cancelTicketStatus = 'idle';
        },
        cancelTicketDone(state, {payload}: PayloadAction<Rest<any>>) {
            state.cancelTicketStatus = 'idle';
        },
        settingReceiveNotification(state, {payload}: PayloadAction<any>) {
            state.receiveNotificationStatus = 'loading';
        },
        settingReceiveNotificationFail(state) {
            state.receiveNotificationStatus = 'idle';
        },
        settingReceiveNotificationDone(state, {payload}: PayloadAction<Rest<Ticket>>) {
            state.receiveNotificationStatus = 'idle';
            state.ticket.is_receiving_notification = !!payload.data.is_receiving_notification;
        },
        updateFollowing(state, {payload}: PayloadAction<any>) {
            state.editFollowingStatus = 'loading';
        },
        updateFollowingFail(state) {
            state.editFollowingStatus = 'idle';
        },
        updateFollowingDone(state, {payload}: PayloadAction<Rest<Ticket>>) {
            state.editFollowingStatus = 'success';
            state.ticket.additional_following_employees = payload.data.additional_following_employees;
            state.ticket.is_receiving_notification = payload.data.is_receiving_notification;
        },
        refreshMentionTicket(state, {payload}: PayloadAction<any>) {
            state.mentionTicketStatus = 'loading';
        },
        refreshMentionTicketFail(state) {
            state.mentionTicketStatus = 'idle';
        },
        refreshMentionTicketDone(state, {payload}: PayloadAction<Rest<Ticket[]>>) {
            const mentionTickets: Dictionary<Ticket> = {};
            const mentionTicketIds: number[] = [];

            payload.data.forEach((ticket) => {
                mentionTickets[ticket.id] = ticket;
                mentionTicketIds.push(ticket.id);
            })

            state.mentionTickets = mentionTickets;
            state.mentionTicketIds = mentionTicketIds;
            state.mentionTicketStatus = 'idle';
        },
        requestingFollow(state, {payload}: PayloadAction<any>) {
            state.requestFollowStatus = 'loading';
        },
        requestFollowDone(state) {
            state.requestFollowStatus = 'success';
        },
        assignMultipleTicketNode(state, {payload}: PayloadAction<any>) {
            state.ticketNodeAssignMultipleStatus = "loading";
        },
        assignMultipleTicketNodeDone(state, {payload}: PayloadAction<Rest<NodeModel[]>>) {
            const nodes = payload.data;
            const edges = state.elements?.filter((elm: FlowElement) => (elm as any)?.source);
            const sourceEdgeMap = groupBy(edges, 'source');
            const targetEdgeMap = groupBy(edges, 'target');
            const newProcessingNodes: Node[] = [];
            nodes?.forEach((node: NodeModel) => {
                const updatedNode = setNode(node);
                state.nodes[node.id] = updatedNode;
                const index = findIndex(state.elements, (el) => String(el.id) === String(node.id));
                state.elements[index] = updatedNode;
                if (state.currentNode?.id && String(node.id) === String(state.currentNode.id)) {
                    state.currentNode = updatedNode;
                }
                // kiem tra node duoc gan nguoi xu li co do chinh minh xu ly khong de update quyen next cua buoc xu li
                if (updatedNode?.data?.is_employee_can_update_result) {
                    const targets = sourceEdgeMap[updatedNode.id];
                    targets?.forEach((edge: FlowElement) => {
                        if ('target' in edge && edge?.target && state.nodes[edge?.target]) {
                            set(state.nodes[edge.target] as object, "data.is_employee_can_next", true);
                        }
                    })

                    if (updatedNode?.data?.status === TicketNodeStatus.CAN_PROCESS) {
                        const sources = targetEdgeMap[updatedNode.id];
                        sources.forEach((edge: FlowElement) => {
                            edge.className = 'edge-processed'
                        })
                    }
                } else {
                    const targets = sourceEdgeMap[updatedNode.id];
                    targets?.forEach((edge: FlowElement) => {
                        if ('target' in edge && edge?.target && state.nodes[edge?.target]) {
                            set(state.nodes[edge.target] as object, "data.is_employee_can_next", false);
                        }
                    })

                    if (updatedNode?.data?.status === TicketNodeStatus.CAN_PROCESS) {
                        const sources = targetEdgeMap[updatedNode.id];
                        sources.forEach((edge: FlowElement) => {
                            edge.className = ''
                        })
                    }
                }
                for (let nod of state.processingNodes) {
                    if (nod.id === updatedNode.id) {
                        newProcessingNodes.push(updatedNode)
                    } else {
                        newProcessingNodes.push(nod)
                    }
                }
            })
            state.processingNodes = [...uniqBy(newProcessingNodes, (node: any) => node?.id?.toString())];
            // state.currentNodeId = getCurrentNodeId(state.nodeIds, state.nodes);
            state.ticket.is_decided_processor = nodes[0]?.ticket?.is_decided_processor;
            state.ticketNodeAssignMultipleStatus = "success";
        },
        assignMultipleTicketNodeFail(state) {
            state.ticketNodeAssignMultipleStatus = 'idle';
        },
        getFormResultCurrentNode(state, { payload }: PayloadAction<any>) {
            state.formResultNodeLoading = true;
            state.updateTaskStatus = 'idle';
        },
        getFormResultCurrentNodeDone(state, { payload }: PayloadAction<Rest<any>>) {
            state.formResultNodeLoading = false;
            if (payload.data) {
                state.formResultNodes[payload.data.nodeId] = {...payload.data};
            }
        },
        refreshTimeline(state, {payload}: PayloadAction<any>) {
            state.refreshTimelineStatus = 'loading';
        },
        refreshTimelineDone(state) {
            state.refreshTimelineStatus = 'idle';
        },
        resetState(state) {
            state.ticket = {
                id: 0,
                name: '',
            };
            state.elements = [];
            state.comments = [];
            state.workflow = undefined;
            state.nodes = {};
            state.processingNodes = [];
            state.nodeIds = [];
            state.currentNodeId = undefined;
            state.currentNode = undefined;
            state.mentionTickets = {};
            state.mentionTicketIds = [];
            state.refreshTicketCode = '';
        },
        resetTicketNodeAssignStatus(state) {
            state.ticketNodeAssignStatus = 'idle';
        },
        resetUpdateTaskStatus(state) {
          state.updateTaskStatus = 'idle';
        },
    },
});

export const {
    refreshTicket,
    resetTicket,
    ticketLoaded,
    refreshComment,
    commentLoaded,
    refreshWorkflow,
    workflowLoaded,
    creatingTicket,
    setCurrentNodeId,
    refreshNodeDetail,
    nodeDetailLoaded,
    setCurrentNodeDetail,
    completingAction,
    nodeCompleted,
    taskUpdated,
    actionCompleted,
    updatingTask,
    createComment,
    createCommentDone,
    assigningTicketNode,
    cancelTicket,
    cancelTicketDone,
    ticketCreateFailed,
    completingActionFail,
    updateTaskFail,
    cancelTicketFail,
    assigningTicketFail,
    settingReceiveNotification,
    settingReceiveNotificationDone,
    settingReceiveNotificationFail,
    updateFollowing,
    updateFollowingDone,
    updateFollowingFail,
    refreshMentionTicket,
    refreshMentionTicketDone,
    refreshMentionTicketFail,
    refreshTicketFail,
    resetStatus,
    refreshTicketLite,
    requestingFollow,
    requestFollowDone,
    assignMultipleTicketNode,
    assignMultipleTicketNodeDone,
    assignMultipleTicketNodeFail,
    refreshGetCurrentNode,
    gotCurrentNode,
    resetGetCurrentNodeStatus,
    resetState,
    getFormResultCurrentNode,
    getFormResultCurrentNodeDone,
    refreshTimeline,
    refreshTimelineDone,
    resetTicketNodeAssignStatus,
    resetUpdateTaskStatus,
} = slice.actions;

const ticketDetailReducer = slice.reducer;

export default ticketDetailReducer;
