import ReactLinkify from "react-linkify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fontAwesomeIcons from "../../utils/fontAwesomeIcons";
import React, {useCallback, useState} from "react";
import AutoHighLightText from "./AutoHighLightText";
import {useQuery} from "../hooks";

const CustomShowDescription = ({description, showChar = 150}:{description: string, showChar?: number}) => {
    const [isHidden, setHidden] = useState(description.length > showChar);
    const pattern = useQuery('search');
    const handleOnClick = useCallback((event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setHidden(old => !old);
    }, [setHidden]);

    return (
        <>
            {
                description?.length > showChar ?
                    <>
                        {
                            isHidden ?
                                <>
                                    <ReactLinkify><AutoHighLightText pattern={pattern}><span className={'break-words'}>{description.substring(0, showChar)}...</span></AutoHighLightText></ReactLinkify>
                                    <span className={'text-main ml-3 cursor-pointer'} onClick={handleOnClick}>Xem thêm<FontAwesomeIcon className={'ml-3'} icon={fontAwesomeIcons.faChevronDown}/></span>
                                </>
                                :
                                <>
                                    <ReactLinkify><AutoHighLightText pattern={pattern}><span className={'break-words'}>{description}</span></AutoHighLightText></ReactLinkify>
                                    <span className={'text-main ml-3 cursor-pointer'} onClick={handleOnClick}>Thu gọn<FontAwesomeIcon className={'ml-3'} icon={fontAwesomeIcons.faChevronUp}/></span>
                                </>
                        }
                    </>
                    : <ReactLinkify><AutoHighLightText pattern={pattern}><span className={'break-words'}>{description ?? ''}</span></AutoHighLightText></ReactLinkify>
            }
        </>
    )
}
export default CustomShowDescription;