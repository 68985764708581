import { RouterOutlet } from 'react-hook-guard';
import appRoutes from './App.routes';
import {useEffect} from "react";
import {CloudMessaging} from "./core/firebase";

function App() {
    useEffect(() => {
        try {
            CloudMessaging.instance().onMessageListener();
            // eslint-disable-next-line no-empty
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
            <RouterOutlet routes={appRoutes} relativeMode={true} />
        </>;
}

export default App;
