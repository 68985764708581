import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ServiceActivationState} from "./state";
import {Rest} from "../../../../common/models/rest";
import {ActivityLog} from "../../../../models/activity-log";
import {keyBy} from "lodash";

const slice = createSlice({
    initialState: {...new ServiceActivationState()},
    name: 'serviceActivationHistory',
    reducers: {
        refreshListServiceActivation(state: ServiceActivationState, {payload}: PayloadAction<any>) {
            state.status = 'loading';
        },
        listServiceActivationLoaded(state: ServiceActivationState, {payload}: PayloadAction<Rest<ActivityLog[]>>) {
            state.status = 'idle';
            state.ids = payload.data.map(({id}) => (id));
            state.entities = keyBy(payload.data, 'id');
            state.dateMapping = {};
            for (const d of payload.data) {
                if (!state.dateMapping[d.date]) {
                    state.dateMapping[d.date] = [];
                }
                state.dateMapping[d.date]?.push(d.id);
            }
        }
    }
})

export const {
    refreshListServiceActivation,
    listServiceActivationLoaded
} = slice.actions;

const serviceActivationReducer = slice.reducer;

export default serviceActivationReducer;
