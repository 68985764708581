import AppState from "../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Employee} from "../../../models/employee";
import {Department} from "../../../models/department";
import {Position} from "../../../models/position";
import {Role} from "../../../models/role";
import {DefaultSelectData} from "./state";

export const selectProcessorState = (state: AppState) => state.processor;

export const selectProcessorEmployees = createSelector(
    selectProcessorState,
    state => state.employeeIds.map(id => state.employees[id] as Employee)
)

export const selectProcessorEmployeesObj = createSelector(
    selectProcessorState,
    state => state.employees
)

export const selectProcessorDepartments = createSelector(
    selectProcessorState,
    state => state.departmentIds.map(id => state.departments[id] as Department)
)

export const selectProcessorDepartmentsObj = createSelector(
    selectProcessorState,
    state => state.departments
)

export const selectProcessorPositions = createSelector(
    selectProcessorState,
    state => state.positionIds.map(id => state.positions[id] as Position)
)

export const selectProcessorPositionsObj = createSelector(
    selectProcessorState,
    state => state.positions
)

export const selectProcessorRoles = createSelector(
    selectProcessorState,
    state => state.roleIds.map(id => state.roles[id] as Role)
)

export const selectProcessorRolesObj = createSelector(
    selectProcessorState,
    state => state.roles
)

export const selectProcessors = createSelector(
    selectProcessorState,
    state => state.processors
)

export const selectProcessorLoading = createSelector(
    selectProcessorState,
    state => state.status === 'loading'
)

export const selectDefaultSelect = createSelector(
    selectProcessorState,
    state => state.defaultSelectIds.map(id => state.defaultSelects[id] as DefaultSelectData)
)
export const selectDefaultSelectObj = createSelector(
    selectProcessorState,
    state => state.defaultSelects
)
