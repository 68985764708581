import {CRUDService} from '../../../common/service';
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";

export class CallLog extends CRUDService<any, any, any> {
    getNameSpace(): string {
        return 'engagement/call-logs';
    }
    getAudio (id: string | number) {
        return axios.get<any, Rest<{audio: string}>>(`${BASE_URL}/${this.getNameSpace()}/${id}/audio`);
    }
}

const callLogService = new CallLog();

export default callLogService;
