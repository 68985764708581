import {ChangeLog} from "../../models/change-log";
import {CRUDService} from "../../common/service";

export class ChangeLogService extends CRUDService<ChangeLog> {
    getNameSpace(): string {
        return "change-logs";
    }
}

const changeLogService = new ChangeLogService();

export default changeLogService;