import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {RouteWithCommand} from "react-hook-guard";
import {useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";

const titleAtom = atom({
    key: "app-title",
    default: ""
})

export const useAppTitleValue = () => useRecoilValue(titleAtom);
export const useSetAppTitle = () => {
    const setTitle = useSetRecoilState(titleAtom);
    return useCallback((title: string) => {
        setTitle(title);
        document.title = title;
    }, [setTitle]);
};
export const useAppTitle = () => useRecoilState(titleAtom);

const setLastUrl = (lastUri: string) => {
    localStorage.setItem("LAST_URL", lastUri);
}

export const useRouteTitleGuard = (route: RouteWithCommand) => {
    const history = useHistory();
    setLastUrl(history.location.pathname);
    const setAppTitle = useSetAppTitle();
    useEffect(() => {
        if (route?.data?.title) {
            setAppTitle(route.data.title);
        }
    },[route, setAppTitle]);

    return true;
}
