import {all, select, call, put, takeEvery} from 'redux-saga/effects';
import {
    callLogLoaded, refreshCallLog
} from './reducer';
import {
    selectCallLogStatus
} from './selector'
import {safeCall} from '../../../common/utils';
import {Rest} from '../../../common/models/rest';
import callLogService from "../services/call-log";
import {CallLog} from "../../../models/call-log";

const sagas = [
    takeEvery(refreshCallLog, safeCall(function* ({payload}) {
        const status: string = yield select(selectCallLogStatus);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<CallLog[]> = yield call(callLogService.list, payload);
        yield put(callLogLoaded(response));
    })),
];


function* callLogSagas() {
    yield all(sagas);
}

export default callLogSagas;
