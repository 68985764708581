import {useCallback, useEffect, useMemo, useState} from "react";
import auth from "../../features/auth/auth";
import {AuthEvent, PermissionValidation} from "../../core/auth/auth";

export function useVerifiedAccess() {
    const [changed, setChanged] = useState(1);

    useEffect(() => {
        const set = () => {
            setChanged((old) => old + 1);
        };

        auth.addEventListener(AuthEvent.EMPLOYEE_CHANGED, set);

        return () => {
            auth.removeEventListener(AuthEvent.EMPLOYEE_CHANGED, set);
        };
    }, [setChanged]);

    return useCallback((permissions: PermissionValidation[] = []) => {
        return !!changed && auth.hasPermissions(permissions ?? []);
    }, [changed]);
}

function useCanAccess(permissions?: PermissionValidation[]) {
    const verified = useVerifiedAccess();
    return useMemo(() => verified(permissions), [verified, permissions]);
}

export default useCanAccess;
