import {TreeSelect} from "antd";
import {TreeSelectProps} from "antd/es";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectDepartmentAsTreeNodeData} from "../selector";
import {useEffect} from "react";
import {departmentActions} from "../reducer";

export interface DepartmentSelectorProps<T> extends TreeSelectProps<T> {
    withPosition?: boolean;
}

function DepartmentSelector<T>({
   treeData,
   placeholder,
   treeDefaultExpandAll,
   withPosition,
    ...props
}: DepartmentSelectorProps<T>) {
    const {t} = useTranslation('employee')

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(departmentActions.load());
    }, [dispatch]);

    const departments = useSelector(selectDepartmentAsTreeNodeData({
        withPosition: false,
        withActions: false,
    }));

    return (
        <TreeSelect
            treeNodeFilterProp={'title'}
            treeData={treeData ?? departments as any ?? []}
            placeholder={placeholder ?? t('select')}
            treeDefaultExpandAll={treeDefaultExpandAll ?? true}
            {...props}
        />
    )
}

export default DepartmentSelector;
