import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CallState} from "./state";
import {NetworkStatus} from "../../models/enums/network-status";
import {MicStatus} from "../../models/enums/mic-status";
const slice = createSlice({
    name: 'call',
    initialState: {...new CallState()},
    reducers: {
        setCall(state, {payload}: PayloadAction<{phoneNumber: string, isIncoming: boolean}>) {
            if (state.isCalling) {
                return;
            }
            state.isCallBarOpen = true
            state.callingTime = 0;
            state.phoneNumber = payload.phoneNumber
            state.callRinging = false;
            state.isIncoming = payload.isIncoming
            state.isMuted = false
        },
        setCalling(state, {payload}: PayloadAction<boolean>) {
            state.isCalling = payload
        },
        setCallBarOpen(state, {payload}: PayloadAction<boolean>) {
            state.isCallBarOpen = payload
        },
        setContactName(state, {payload}: PayloadAction<string>) {
            state.contactName = payload
        },
        setMuted(state, {payload}: PayloadAction<boolean>) {
            state.isMuted = payload
        },
        increaseCallingTime(state) {
            state.callingTime++
        },
        fetchConfig(state) {
            state.fetchingConfig = true;
        },
        setCallStarted(state, {payload}: PayloadAction<boolean>) {
            state.callStarted = payload;
            state.isCalling = payload;
            if (payload) {
                state.callingTime = 0;
            }
        },
        setRinging(state, {payload}: PayloadAction<boolean>) {
            state.callRinging = payload;
        },
        sendDTMF(state, {payload}: PayloadAction<string>) {
            state.dtmf = payload
        },
        setCallConfig(state, {payload}: PayloadAction<any>) {
            state.callConfig = payload
        },
        setNetworkStatus(state, {payload}: PayloadAction<NetworkStatus>) {
            state.networkStatus = payload
        },
        setMicStatus(state, {payload}: PayloadAction<MicStatus>) {
            state.micStatus = payload
        },
    },
});

export const {
    setCall,
    increaseCallingTime,
    fetchConfig,
    setCallStarted,
    setCallBarOpen,
    setContactName,
    setMuted,
    sendDTMF,
    setCallConfig,
    setMicStatus,
    setNetworkStatus,
    setCalling,
    setRinging
} = slice.actions;

const callReducer = slice.reducer;

export default callReducer;
