export abstract class Call {
    protected config: {
        host: string;
        ext: string | number;
        password: string;
        ringtone: string
    } | undefined;
    public onCalling: any;
    public onAccepted: Function | undefined;
    public onConfirmed: Function | undefined;
    public onEnded: Function | undefined;
    public onFailed: Function | undefined;
    public onGetMediaFailed: Function | undefined;
    public onCantCall: Function | undefined;
    public abstract answer(): void;
    public abstract callToNumber(number: string): void;
    public abstract terminateCall(): void;
    public abstract init(): void;
    public abstract mute(): boolean;
    public abstract unMute(): boolean;
    public abstract sendDTMF(val: string): boolean;
    public setConfig(config: any) {
        this.config = config
        return this
    }
}