import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    addingEmployee, changingEmployeeRole,
    changingEmployeeStatus,
    employeeAdded,
    employeeListLoaded, employeeStatusChanged,
    refreshEmployeeList, addingEmployeeFailed
} from "./reducer";
import {safeCall} from "../../../common/utils";
import {ManagementState} from "./state";
import {selectEmployeeListFormLoading, selectEmployeeListLoading} from "./selectors";
import {Rest} from "../../../common/models/rest";
import employeeListService from './service';
import {Employee} from "../../../models/employee";
import i18next from "i18next";
import {message} from "antd";

const saga = [
    takeLeading(refreshEmployeeList, safeCall(function* ({payload}) {
        const {loading}: ManagementState = yield select(selectEmployeeListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Employee[]> = yield call(employeeListService.list, payload);
        yield put(employeeListLoaded(response));
    })),

    takeLeading(addingEmployee, function* ({payload}) {
        try {
            const {formLoading}: ManagementState = yield select(selectEmployeeListFormLoading);
            if (formLoading) {
                return;
            }
            const response: Rest<Employee> = yield call(employeeListService.create, payload);
            yield put(employeeAdded(response.data));
            message.success(i18next.t<string>('common:addEmployeeSuccess'));
        } catch (e) {
            message.error((e as any)?.response?.data?.message);
            yield put(addingEmployeeFailed());
        }
    }),

    takeLeading(changingEmployeeStatus, safeCall(function* ({payload}) {
        const {formLoading}: ManagementState = yield select(selectEmployeeListFormLoading);
        if (formLoading) {
            return;
        }
        const response: Rest<Employee> = yield call(employeeListService.changeEmployeeStatus, payload);
        yield put(employeeStatusChanged(response.data));
        message.success(i18next.t<string>('common:changeStatusSuccess'));
    })),

    takeLeading(changingEmployeeRole, safeCall(function* ({payload}) {
        const {formLoading}: ManagementState = yield select(selectEmployeeListFormLoading);
        if (formLoading) {
            return;
        }
        const response: Rest<Employee> = yield call(employeeListService.edit, payload?.id, payload);
        yield put(employeeAdded(response.data));
        message.success(i18next.t<string>('common:updateEmployeeSuccess'));
    })),
];

function* managementSagas() {
    yield all(saga);
}

export default managementSagas;
