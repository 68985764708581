import {memo, PropsWithChildren, useMemo} from "react";

export interface AutoHighLightTextProps {
    pattern?: string;
}

const AutoHighLightText = memo(({children, pattern}: PropsWithChildren<AutoHighLightTextProps>) => {
    const explodedPattern = useMemo(() => new Set(pattern?.toString()?.split(' ').map(p => p.toLowerCase())), [pattern]);
    if (!pattern) return <>{children}</>;

    if (typeof children === 'number') {
        return explodedPattern.has(children.toString()) ? <u>{children}</u> : <>{children}</>;
    }

    if (typeof children === 'string') {
        return (
            <>
                {
                    children?.split(' ').map((text, i) => {
                        if (explodedPattern.has(text) || explodedPattern.has(text.toLowerCase())) return <u key={i}>{text} </u>
                        return <span key={i}>{text} </span>;
                    })
                }
            </>
        );
    }

    return (
        <>
            {children}
        </>
    );
});

export default AutoHighLightText