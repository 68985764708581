import moment from 'moment';

export const SERVER_DATETIME_FORMAT = `${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`;
export const MINIMAL_DATE_FORMAT = 'HH:mm DD/MM';
export const MONTH_FORMAT = 'MM/YYYY';
export const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm'
export const DATE_TIME_FORMAT_FULL = 'dd/MM/yyyy HH:mm:ss'
export const STANDARD_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const FULL_DATE_TIME_FORMAT = 'yyyyMMddHHmmss'
export const TIME_INTERVAL = 60 * 1000 // interval 60s
export const STANDARD_DATE_FORMAT = 'yyyy-MM-dd'
export const TIME_DATE_FORMAT = 'HH:mm:ss dd/MM/yyyy';
export const TIME_DAY_FORMAT = 'HH:mm dd/MM/yyyy';
export const DAY_TIME_FORMAT = 'ddMMHHmm';
export const DD_MM_YYYY_FORMAT = 'dd-MM-yyyy';
export const DATE_TIME_FORMAT_UPPERCASE = 'DD/MM/YYYY hh:mm'
