import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {WorkflowState, WorkflowParams} from "./state";
import {Rest} from "../../../common/models/rest";
import {Workflow} from "../../../models/workflow";
import {set} from "lodash";

const slice = createSlice({
    name: 'workflow/workflow',
    initialState: {...new WorkflowState()},
    reducers: {
        refreshWorkflowList(state: WorkflowState, {payload}: PayloadAction<WorkflowParams>) {
            state.loading = true;
        },
        workflowListLoaded(state: WorkflowState, {payload}: PayloadAction<Rest<Workflow[]>>) {
            const tableData: Dictionary<Workflow> = {};

            const getIds = (workflowData: Workflow[]) => {
                return workflowData.map(workflow => {
                    const {...restInfo} = workflow;
                    if (restInfo) tableData[workflow.id] = restInfo;
                    return workflow.id;
                })
            };
            const ids = getIds(payload.data);

            state.tableData = tableData;
            state.ids = ids;
            state.meta = payload.meta;
            state.loading = false;
        },
        changingWorkflowStatus(state: WorkflowState, {payload}: PayloadAction<{id: number, status: boolean}>) {
            state.loading = true;
        },
        workflowStatusChanged(state: WorkflowState, {payload}: PayloadAction<{id: number, status: boolean}>) {
            set(state, `tableData.${payload.id}.is_active`, payload.status);
            state.loading = false;
        },
        getAllWorkflows(state: WorkflowState, {payload}: PayloadAction<any>) {
            state.status = 'loading'
        },
        getAllWorkflowsDone(state: WorkflowState, {payload}: PayloadAction<Rest<Workflow[]>>) {
            state.status = 'idle'
            state.workflows = payload.data
        },
        savingWorkflow(state, {payload}: PayloadAction<any>) {
            state.formStatus = 'loading';
        },
        workflowSaved(state, {payload}: PayloadAction<Rest<Workflow> | undefined>) {
            state.formStatus = 'success';
        },
        getFormById(state, {payload}: PayloadAction<any>) {
            state.getNodeFormLoading = true;
        },
        getNodeFormByIdDone(state, {payload}: PayloadAction<Rest<any>>) {
            state.getNodeFormLoading = false;
            state.currentNodeForm = payload.data ?? {};
        },
        getTicketFormByIdDone(state, {payload}: PayloadAction<Rest<any>>) {
            state.getNodeFormLoading = false;
            state.currentTicketForm = payload.data ?? {};
        }
    },
});

export const {
    refreshWorkflowList,
    workflowListLoaded,
    changingWorkflowStatus,
    workflowStatusChanged,
    getAllWorkflows,
    getAllWorkflowsDone,
    savingWorkflow,
    workflowSaved,
    getFormById,
    getNodeFormByIdDone,
    getTicketFormByIdDone
} = slice.actions;

const workflowListReducer = slice.reducer;

export default workflowListReducer;
