import {all, call, debounce, put, select, takeLatest} from "redux-saga/effects";
import {
    loadedReportInstanceList,
    loadingReportInstanceList,
    loadReportingBuilder,
    loadReportingBuilderDone,
    loadTypeReportingBuilder,
    loadTypeReportingBuilderDone,
    loadPreviewReport,
    loadPreviewReportDone,
    saveReport,
    saveReportDone,
    loadPreviewReportFail,
} from "./reducer";
import {ReportingState} from "./state";
import {selectReportingState} from "./selectors";
import reportingService from "./service";
import {safeCall} from "../../../common/utils";
import {ReportingBuilderModel, TypeReporting} from "../../../models/reporting-builder-model";
import {Rest} from "../../../common/models/rest";
import {ReportingInstance} from "../../../models/reporting-instance";
import {message} from "antd";
import i18next from "i18next";
import history from "../../../config/history";

const sagas = [
    debounce(200, loadReportingBuilder, safeCall(function* ({payload}) {
        const {reportBuilderLoading}: ReportingState = yield select(selectReportingState);
        if (!reportBuilderLoading) {
            return;
        }
        const response: Rest<ReportingBuilderModel> = yield call(reportingService.getReportingBuilder, payload);
        yield put(loadReportingBuilderDone(response));
    })),
    takeLatest(loadTypeReportingBuilder, safeCall(function* () {
        const {typeReportingBuilderLoading}: ReportingState = yield select(selectReportingState);
        if (!typeReportingBuilderLoading) {
            return;
        }
        const response: Rest<TypeReporting[]> = yield call(reportingService.getTypeReportingBuilder);
        yield put(loadTypeReportingBuilderDone(response));
    })),
    takeLatest(loadingReportInstanceList, safeCall(function* ({payload}) {
        const { reportInstanceListLoading }: ReportingState = yield select(selectReportingState);
        if (!reportInstanceListLoading) {
            return;
        }

        const response: Rest<ReportingInstance[]> = yield call(reportingService.list, payload);
        yield put(loadedReportInstanceList(response));
    })),
    takeLatest(loadPreviewReport, safeCall(function* ({payload}) {
        const { rowsLoading }: ReportingState = yield select(selectReportingState);
        if (!rowsLoading) {
            return;
        }
        try {
            const response: Rest<any> = yield call(reportingService.loadPreviewReport, payload);
            yield put(loadPreviewReportDone(response));
        } catch (e) {
            yield put(loadPreviewReportFail());
        }

    })),
    takeLatest(saveReport, safeCall(function* ({payload}) {
        const { saveReportStatus }: ReportingState = yield select(selectReportingState);
        if (!saveReportStatus) {
            return;
        }
        try {
            const response: Rest<ReportingInstance> = payload.id ? yield call(reportingService.edit, payload.id, {...payload, id: undefined}) : yield call(reportingService.create, payload);
            yield put(saveReportDone());
            message.success(i18next.t<string>(payload.id ? 'reporting:update_report_success' : 'reporting:create_report_success'));
            if (!payload.id) {
                history.push(`./${response.data.id}`);
            }
        } catch (e: any) {
            message.error(e?.response?.data?.message);
            throw e;
        } finally {
            yield put(saveReportDone());
        }
    })),
];

function* reportingSagas() {
    yield all(sagas);
}

export default reportingSagas;