import {
    faPhone,
    faLock,
    faUserCircle,
    faBuilding,
    faCamera,
    faHome,
    faBars,
    faList,
    faFileLines,
    faUserGroup,
    faCalendar,
    faFingerprint,
    faCircleInfo,
    faBell,
    faBellPlus,
    faBellSlash,
    faDoorOpen,
    faDoorClosed,
    faArrowRightFromBracket,
    faPen,
    faUser,
    faStar,
    faTimes,
    faTimesCircle,
    faScrewdriverWrench,
    faEllipsis,
    faBriefcase,
    faSquareUser,
    faCreditCard,
    faCog,
    faCaretRight,
    faNote,
    faEnvelope,
    faCircle,
    faHourglass,
    faMemoCircleCheck,
    faClock,
    faChevronLeft,
    faChevronRight,
    faUsers,
    faCalendarDays,
    faArrowUp,
    faArrowDown,
    faFileSpreadsheet,
    faCalendarRange,
    faFiles,
    faClockRotateLeft,
    faQuoteRight,
    faComment,
    faCheckCircle,
    faBarcode,
    faGlobe,
    faPager,
    faBuildings,
    faPlus,
    faUpload,
    faMobileSignal,
    faMinus,
    faStarSharp,
    faUserLarge,
    faRefresh,
    faBells,
    faGridHorizontal,
    faCircleQuestion,
    faGripHorizontal,
    faMessageLines,
    faLinkSimple,
    faSearch,
    faNotes,
    faTrash,
    faPhoneSlash,
    faMicrophoneSlash,
    faGrid,
    faPhoneArrowUpRight,
    faMicrophone,
    faPaperPlane,
    faPhoneArrowDownLeft,
    faUserPlus,
    faBan,
    faCheck,
    faQuestionCircle,
    faTriangleExclamation,
    faCircleCheck,
    faApartment,
    faChairOffice,
    faCircleEnvelope,
    faCircleEllipsis,
    faCirclePlus,
    faCommentArrowUp,
    faListCheck,
    faTrashCanXmark,
    faTicket,
    faCodeFork,
    faA,
    faFont,
    faAlignLeft,
    faInputNumeric,
    faCalendarDay,
    faCalendarClock,
    faSquareCheck,
    faCircleDot,
    faSquareCaretDown,
    faCloudArrowUp,
    fa00,
    faX,
    faPaperclip,
    faArrowsFromLine,
    faGripDots,
    faGripDotsVertical,
    faLightbulbOn,
    faRotate,
    faGear,
    faXmark,
    faWavePulse,
    faFileUser,
    faAddressBook,
    faPenToSquare,
    faFileChartColumn,
    faChartLine,
    faChevronDown,
    faMagnifyingGlass,
    faChevronUp,
    faChevronsUp,
    faGripLines,
    faEyeSlash,
    faUsersGear,
    faBusinessTime,
    faArrowTurnDownLeft,
    faArrowTurnDownRight,
    faHandPointRight,
    faDotCircle,
    faPeriod,
    faCircleSmall,
    faHorizontalRule,
    faHandFist,
    faRightLeft,
    faArrowsRotate,
    faCircleHalfStroke,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faArrowLeft as farArrowLeft,
    faArrowRight as farArrowRight,
    faPlus as farPlus,
    faPen as farPen,
    faChevronDown as farChevronDown,
    faInfoCircle as farInfoCircle,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faDoorOpen as farDoorOpen,
    faDoorClosed as farDoorClosed,
    faBuilding as farBuilding,
    faArrowLeftLong as farArrowLeftLong,
    faBell as farBell,
    faSearch as farSearch,
    faScrewdriverWrench as farScrewdriverWrench,
    faShare as farShare,
    faRepeat as farRepeat,
    faCircleInfo as farCircleInfo,
    faTrash as farTrash,
    faEdit as farEdit,
    faTrashCan as farTrashCan,
    faClone as farClone,
    faCheck as farCheck,
    faXmark as farXmark,
    faExclamation as farExclamation,
    faInfo as farInfo,
    faBriefcase as farBriefcase,
    faBriefcaseClock as farBriefcaseClock,
    faHourglass as farHourglass,
    faCalendarDays as farCalendarDays,
    faListCheck as farListCheck,
    faArrowUp as farArrowUp,
    faArrowDown as farArrowDown,
    faTimes as farTimes,
    faCheckCircle as farCheckCircle,
    faLocationDot as farLocationDot,
    faCalendarCheck as farCalendarCheck,
    faEllipsisH as farEllipsisH,
    faEye as farEye,
    faClock as farClock,
    faCopy as farCopy,
    faUpload as farUpload,
    faNoteMedical as farNoteMedical,
    faSquarePlus as farSquarePlus,
    faSquareMinus as farSquareMinus,
    faBarsFilter as farBarsFilter,
    faScrollOld as farScrollOld,
    faFolderTree as farFolderTree,
    faCodeFork as farCodeFork,
    faCircleEnvelope as farCircleEnvelope,
    faGridHorizontal as farGridHorizontal,
    faBells as farBells,
    faMagnifyingGlass as farMagnifyingGlass,
    faArrowRightToLine as farArrowRightToLine,
    faCalendar as farCalendar,
    faCodeMerge as farCodeMerge,
    faEyeSlash as farEyeSlash,
    faUsersGear as farUsersGear,
    faUserPlus as farUserPlus,
    faBellSlash as farBellSlash,
    faBellPlus as farBellPlus,
    faBusinessTime as farBusinessTime,
    faArrowTurnDownLeft as farArrowTurnDownLeft,
    faArrowTurnDownRight as farArrowTurnDownRight,
    faCalendarClock as farCalendarClock,
    faHome as farHome,
    faChairOffice as farChairOffice,
    faUserGroup as farUserGroup,
    faCloudArrowUp as farCloudArrowUp,
    faHandPointRight as farHandPointRight,
    faDotCircle as farDotCircle,
    faChartLineUp as farChartLineUp,
    faCommentDollar as farCommentDollar,
    faPrint as farPrint,
} from "@fortawesome/pro-regular-svg-icons";

import {
    faClipboardUser as fatClipboardUser,
    faKey as fatKey,
    faBuildings as fatBuildings,
    faBuilding as fatBuilding,
    faShieldCheck as fatShieldCheck,
    faScreenUsers as fatScreenUsers,
    faMemoCircleCheck as fatMemoCircleCheck,
    faMemoPad as fatMemoPad,
    faTimeline as fatTimeline,
    faWifi as fatWifi,
    faFingerprint as fatFingerprint,
    faCalendar as fatCalendar,
    faFileLines as fatFileLines,
    faClipboard as fatClipboard,
    faClipboardList as fatClipboardList,
    faCalendarDay as fatCalendarDay,
    faCalendarCheck as fatCalendarCheck,
    faFilePen as fatFilePen,
} from "@fortawesome/pro-thin-svg-icons";
import {faCaretDown} from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import {faCaretUp} from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import {faUserGear} from "@fortawesome/pro-solid-svg-icons/faUserGear";
import {faChairOffice as falChairOffice} from "@fortawesome/pro-light-svg-icons/faChairOffice"
const fontAwesomeIcons = {
    // Solid
    faFileChartColumn,
    faChartLine,
    faUserCircle,
    faPhone,
    faLock,
    faBuilding,
    faCamera,
    faHome,
    faBars,
    faList,
    faFileLines,
    faCalendar,
    faFingerprint,
    faCircleInfo,
    faUserGroup,
    faBell,
    faBellPlus,
    faBellSlash,
    faDoorOpen,
    faArrowRightFromBracket,
    faPen,
    faUser,
    faStar,
    faTimes,
    faScrewdriverWrench,
    faEllipsis,
    faBriefcase,
    faSquareUser,
    faCreditCard,
    faCog,
    faCaretRight,
    faNote,
    faEnvelope,
    faDoorClosed,
    faCircle,
    faHourglass,
    faMemoCircleCheck,
    faClock,
    faChevronLeft,
    faChevronRight,
    faUsers,
    faCalendarDays,
    faFileSpreadsheet,
    faCalendarRange,
    faFiles,
    faClockRotateLeft,
    faQuoteRight,
    faComment,
    faCheckCircle,
    faBarcode,
    faGlobe,
    faPager,
    faBuildings,
    faPlus,
    faUpload,
    faTimesCircle,
    faMobileSignal,
    faMinus,
    faStarSharp,
    faUserLarge,
    faRefresh,
    faBells,
    faGridHorizontal,
    faCircleQuestion,
    faGripHorizontal,
    faMessageLines,
    faLinkSimple,
    faSearch,
    faPhoneSlash,
    faMicrophoneSlash,
    faGrid,
    faPhoneArrowUpRight,
    faMicrophone,
    faPaperPlane,
    faPhoneArrowDownLeft,
    faNotes,
    faUserPlus,
    faBan,
    faCheck,
    faQuestionCircle,
    faTriangleExclamation,
    faCircleCheck,
    faApartment,
    faChairOffice,
    faCircleEllipsis,
    faCirclePlus,
    faCaretDown,
    faCaretUp,
    faUserGear,
    faGear,
    faLightbulbOn,
    faRotate,
    faA,
    faFont,
    faAlignLeft,
    faInputNumeric,
    faCalendarDay,
    faCalendarClock,
    faSquareCheck,
    faCircleDot,
    faSquareCaretDown,
    faCloudArrowUp,
    fa00,
    faX,
    faPaperclip,
    faArrowsFromLine,
    faGripDots,
    faGripDotsVertical,
    faWavePulse,
    faXmark,
    faFileUser,
    faAddressBook,
    faCircleHalfStroke,
    // Regular
    farArrowLeft,
    farArrowRight,
    farPlus,
    farPen,
    farChevronDown,
    farInfoCircle,
    farArrowRightFromBracket,
    farDoorOpen,
    farBuilding,
    farArrowLeftLong,
    farBell,
    farSearch,
    farScrewdriverWrench,
    farShare,
    farRepeat,
    farCircleInfo,
    farTrash,
    farEdit,
    farTrashCan,
    farClone,
    farCheck,
    farBriefcase,
    farBriefcaseClock,
    farHourglass,
    farDoorClosed,
    farCalendarDays,
    farListCheck,
    farArrowUp,
    farArrowDown,
    farTimes,
    farCheckCircle,
    farLocationDot,
    farCalendarCheck,
    farEllipsisH,
    farEye,
    farClock,
    farCopy,
    farUpload,
    farNoteMedical,
    farSquarePlus,
    farSquareMinus,
    farCommentDollar,
    // Thin
    fatClipboardUser,
    fatKey,
    fatBuildings,
    fatBuilding,
    fatShieldCheck,
    fatScreenUsers,
    fatMemoCircleCheck,
    fatMemoPad,
    fatWifi,
    fatTimeline,
    fatFingerprint,
    fatCalendar,
    farXmark,
    farExclamation,
    farInfo,
    fatFileLines,
    faArrowUp,
    faArrowDown,
    fatClipboard,
    fatClipboardList,
    fatCalendarDay,
    fatCalendarCheck,
    fatFilePen,
    faTrash,
    faPenToSquare,
    faCircleEnvelope,
    faCommentArrowUp,
    faListCheck,
    faTrashCanXmark,
    faTicket,
    faCodeFork,
    farBarsFilter,
    farScrollOld,
    farFolderTree,
    farCodeFork,
    farCircleEnvelope,
    farGridHorizontal,
    farBells,
    faChevronDown,
    farMagnifyingGlass,
    faMagnifyingGlass,
    faChevronUp,
    faChevronsUp,
    faGripLines,
    farArrowRightToLine,
    farCalendar,
    farCodeMerge,
    faEyeSlash,
    farEyeSlash,
    farUsersGear,
    faUsersGear,
    farUserPlus,
    farBellPlus,
    farBellSlash,
    faBusinessTime,
    farBusinessTime,
    faArrowTurnDownLeft,
    farArrowTurnDownLeft,
    farArrowTurnDownRight,
    faArrowTurnDownRight,
    farCalendarClock,
    farHome,
    farChairOffice,
    farUserGroup,
    farCloudArrowUp,
    faHandPointRight,
    farHandPointRight,
    farDotCircle,
    faDotCircle,
    faPeriod,
    faCircleSmall,
    farChartLineUp,
    faHorizontalRule,
    faHandFist,
    faRightLeft,
    faArrowsRotate,
    // Light
    falChairOffice,
    farPrint,
};

export default fontAwesomeIcons;
