import {atom, useRecoilState, useSetRecoilState} from "recoil";
import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, Popconfirm, Row} from "antd";
import {useTranslation} from "react-i18next";
import React, {memo, useCallback, useEffect} from "react";
import {CaretDownOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useAutoloadForm} from "../../../../common/hooks";
import departmentService from "../service";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDepartmentFormLoading,
    selectDepartmentFormStatus,
    selectDepartmentIsRoot,
} from "../selector";
import DepartmentSelector from "./Selector";
import {departmentActions} from "../reducer";
import {now} from "lodash";

export interface DepartmentFormState {
    id?: number;
    visible: boolean;
}

const formState = atom<DepartmentFormState>({
    key: 'employee/department/form-' + now(),
    default: {
        visible: false
    },
});

export const CreateButton = () => {
    const {t} = useTranslation('employee');
    const setFormState = useSetRecoilState(formState);

    const openFormModal = useCallback(() => {
        setFormState({
            visible: true,
            id: undefined,
        })
    }, [setFormState]);

    return (
        <Button onClick={openFormModal} type={'primary'}>
            {t('create')}
        </Button>
    );
};

export const EditDepartmentButton = memo(({id}: {id: number}) => {
    const setFormState = useSetRecoilState(formState);
    const openFormModal = useCallback(() => {
        setFormState({
            visible: true,
            id
        })
    }, [id, setFormState]);

    return (
        <EditOutlined onClick={openFormModal} className={'mr-4 text-orange'} />
    )
});

export const DeleteDepartmentButton = memo(({id}: {id: number}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onOk = useCallback(() => {
        dispatch(departmentActions.doDelete(id));
    }, [id, dispatch]);
    return (
        <Popconfirm title={t('employee:confirm_delete_department')}
                    className={'mr-4'}
                    okText={t('common:ok')}
                    onConfirm={onOk}>
            <DeleteOutlined className={'text-red'} />
        </Popconfirm>
    )
});

const parentIdToTreeValue = (value: any) => ({
    ...value,
    parent_id: value.parent_id ? `department-${value.parent_id}` : value.parent_id,
})

export const DepartmentForm = () => {
    const dispatch = useDispatch();
    const [{visible, id}, setState] = useRecoilState(formState);
    const {t} = useTranslation('employee');
    const form = useAutoloadForm({
        id,
        loader: departmentService.show,
        pipe: parentIdToTreeValue,
        syncBackendError: true
    });

    const isRoot = useSelector(selectDepartmentIsRoot(id));
    const handleCancel = useCallback(() => {
        setState((old) => ({
            ...old,
            visible: false
        }));
    }, [setState]);

    const onSubmit = useCallback((values: { parent_id: string; }) => {
         dispatch(departmentActions.submit({
             ...values,
             parent_id: values.parent_id?.split('-')?.[1],
             id
         }))
    }, [dispatch, id]);

    const formLoading = useSelector(selectDepartmentFormLoading);
    const formStatus = useSelector(selectDepartmentFormStatus);
    useEffect(() => {
        if (formStatus === 'success') {
            setState({
                visible: false,
                id: undefined
            });
            form.resetFields();
        }
    }, [formStatus, setState, form])

    return (
        <Modal
            title={t(id ? 'edit' : 'create')}
            open={visible}
            forceRender
            onCancel={handleCancel}
            width={600}
            onOk={form.submit}
            okText={t('common:save')}
            cancelText={t('common:close')}
            confirmLoading={formLoading}
        >
            <Form form={form} onFinish={onSubmit} layout={'vertical'}>
                <Form.Item
                    name={'name'}
                    label={t('form.name')}
                    rules={[
                        {required: true},
                        {max: 255},
                    ]}
                >
                    <Input placeholder={t('Nhập tên')} />
                </Form.Item>
                <Form.Item
                    name={'parent_id'}
                    label={t('form.parent_id')}
                    rules={[{required: !isRoot}]}
                >
                    <DepartmentSelector
                        suffixIcon={<CaretDownOutlined className={'text-black font-small-title'} />}
                    />
                </Form.Item>
                <Form.List
                    name="positions"
                    rules={[
                        {
                            validator: async (_, positions) => {
                                if (!positions || positions.length < 1) {
                                    return Promise.reject(new Error(t('form.min_positions')));
                                }
                                if (!positions.some((po: any) => po?.is_manager)) {
                                    return Promise.reject(new Error(t('form.min_managers')));
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <Row key={field.key} gutter={8}>
                                            <Form.Item name={[field.name, 'id']}
                                                       noStyle={true} hidden={true}>
                                                <InputNumber />
                                            </Form.Item>
                                            <Col xs={12}>
                                                <Form.Item
                                                    label={t('form.position_name')}
                                                    name={[field.name, 'name']}
                                                    rules={[{required: true}]}
                                                    className={'hidden-label-form-item'}
                                                >
                                                    <Input placeholder={t('form.position_name')}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Item
                                                    label={t('form.position_code')}
                                                    name={[field.name, 'is_manager']}
                                                    className="text-center hidden-label-form-item"
                                                    valuePropName={'checked'}
                                                >
                                                    <Checkbox>{t('form.is_manager')}</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            {fields.length > 1 ? (
                                                <Col xs={4}>
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button text-red"
                                                        onClick={() => remove(index)}
                                                    />
                                                </Col>
                                            ) : null}
                                        </Row>
                                    )
                                } )}
                                <div>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined/>}
                                    >
                                        {t('form.add_position')}
                                    </Button>
                                    <Form.ErrorList errors={errors}/>
                                </div>
                            </>
                        );
                    }}
                </Form.List>
            </Form>
        </Modal>
    )
}
