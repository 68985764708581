import {useTranslation} from "react-i18next";
import auth from "./auth/auth";
import {useEffect, useState} from "react";
import {AuthEvent} from "../core/auth/auth";
import GlobalLoading from "../common/components/GlobalLoading";

const Forbidden = () => {
    const {t} = useTranslation('common');
    const [employee, setEmployee] = useState();
    useEffect(() => {
        const set = (employee: any) => {
            setEmployee(employee);
        };

        auth.addEventListener(AuthEvent.EMPLOYEE_CHANGED, set);

        return () => auth.removeEventListener(AuthEvent.EMPLOYEE_CHANGED, set);
    }, [setEmployee]);

    return employee
        ? (
            <div style={{height: 'calc(100vh - 90px)'}} className={"flex justify-center align-center items-center"}>
                <div className={"text-center"}>
                    <div className={"font-bold text-red"} style={{fontSize: '100px'}}>403</div>
                    <div className={"text-24 text-red"}>{t('forbidden')}</div>
                </div>
            </div>
        )
        : <GlobalLoading/>
}
export default Forbidden
