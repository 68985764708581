import {NotificationSetterGroup} from "../../models/enums/notification-setter-group";
import {CRUDService} from "../../common/service";
import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";
import {EditNotificationSetting} from "./state";


export class SettingNotificationService extends CRUDService<NotificationSetterGroup[]>{
    getNameSpace(): string {
        return "notification-settings";
    }
    massEdit = (payload: EditNotificationSetting[]) => {
        return axios.put<any, Rest<NotificationSetterGroup[]>>(`${BASE_URL}/${this.getNameSpace()}`, {
            data: payload
        });
    }
}
const settingNotificationService = new SettingNotificationService();

export default settingNotificationService;
