import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentHistoryState} from "./state";
import {Rest} from "../../../../common/models/rest";
import {Payment} from "../../../../models/payment";
import {keyBy} from "lodash";

const slice = createSlice({
    initialState: {...new PaymentHistoryState()},
    name: 'paymentHistory',
    reducers: {
        refreshListPayment(state: PaymentHistoryState, {payload}: PayloadAction<any>) {
            state.status = 'loading'
        },
        listPaymentLoaded(state: PaymentHistoryState, {payload}: PayloadAction<Rest<Payment[]>>) {
            state.meta = payload.meta;
            state.ids = payload.data.map(({id}) => (id));
            state.entities = keyBy(payload.data, 'id');
            state.status = 'idle';
        }
    }
})

export const {
    refreshListPayment,
    listPaymentLoaded
} = slice.actions;

const paymentHistoryReducer = slice.reducer;

export default paymentHistoryReducer;
