import AppState from "../../App.state";
import {createSelector} from "@reduxjs/toolkit";

export const selectCallState = (state: AppState) => state.call;

const toHHMMSS = (secs: string | number) => {
    var sec_num = parseInt('' + secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v, i) => v !== "00" || i > 0)
        .join(":")
}

export const selectPhoneNumber = createSelector(
    selectCallState,
    state => state.phoneNumber
);

export const selectIsCalling = createSelector(
    selectCallState,
    state => state.isCalling
);

export const selectIsCallBarOpen = createSelector(
    selectCallState,
    state => state.isCallBarOpen
);

export const selectCallingTime = createSelector(
    selectCallState,
    state => toHHMMSS(state.callingTime)
);

export const selectFetchingConfig = createSelector(
    selectCallState,
    state => state.fetchingConfig
);

export const selectContactName = createSelector(
    selectCallState,
    state => state.contactName
);
export const selectIsMuted = createSelector(
    selectCallState,
    state => state.isMuted
);
export const selectCallConfig = createSelector(
    selectCallState,
    state => state.callConfig
);
export const selectCanCall = createSelector(
    selectCallState,
    state => !!state.callConfig?.internal_number
);
export const selectCallStarted = createSelector(
    selectCallState,
    state => state.callStarted
);
export const selectIsIncoming = createSelector(
    selectCallState,
    state => state.isIncoming
);
export const selectRinging = createSelector(
    selectCallState,
    state => state.callRinging
);
export const selectNetworkStatus = createSelector(
    selectCallState,
    state => state.networkStatus
);
export const selectMicStatus = createSelector(
    selectCallState,
    state => state.micStatus
);