import emptyData from '../layouts/images/empty-data.png';
import {useTranslation} from "react-i18next";

const EmptyData = ({message}: {message: string | undefined}) => {
    const {t} = useTranslation();
    return (
        <div style={{ textAlign: 'center' }}>
            <img src={emptyData} alt={"empty data"} style={{maxWidth: '100%'}}/>
            <p>{message ? t(message) : message}</p>
        </div>
    )
}


const customizeEmpty = (message: string = 'common:emptyData') => () => <EmptyData message={message}/>

export default customizeEmpty;
