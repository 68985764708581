import {CRUDService} from "../../common/service";
import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";
import {RouterEnum} from "../../config/router.enum";

class MarketInsightService extends CRUDService<any> {
    getNameSpace(): string {
        return RouterEnum.marketInsight;
    }

    getFilters(params: any) {
        return axios.get<any, Rest<any>>(`${BASE_URL}/${RouterEnum.marketInsight}/board-config`, {params});
    }

    getChartConfigs(params: any) {
        const {code, ...data} = params;
        return axios.post<any, Rest<any>>(`${BASE_URL}/${RouterEnum.marketInsight}/board-component-detail/${code}`, data)
    }
}

const marketInsightService = new MarketInsightService();

export default marketInsightService;