import {createSelector} from "@reduxjs/toolkit";
import AppState from "../../../App.state";
import {ReportingInstance} from "../../../models/reporting-instance";

export const selectReportingState = (state: AppState) => state.reporting;

export const selectReportingBuilder = createSelector(
    selectReportingState,
    state => state.reportBuilder
);

export const selectReportingBuilderColumns = createSelector(
    selectReportingState,
    state => state.reportBuilder.columns
);

export const selectReportingBuilderFilters = createSelector(
    selectReportingState,
    state => state.reportBuilder.filters
);

export const selectReportingBuilderFiltersInput = createSelector(
    selectReportingState,
    state => state.reportBuilder.filters?.map(item => {
        const input = item.input;
        return {...input, name: item.name || input.name, code: item.code || input.code};
    })
);

export const selectReportingBuilderLoading = createSelector(
    selectReportingState,
    state => state.reportBuilderLoading
);

export const selectTypeReportingBuilderLoading = createSelector(
    selectReportingState,
    state => state.typeReportingBuilderLoading
);

export const selectTypeReportingBuilder = createSelector(
    selectReportingState,
    state => state.typeReportingBuilder
);

export const selectReportInstanceList = createSelector(
    selectReportingState,
    state => state.reportInstanceListIds.map(id => state.reportInstanceList[id] as ReportingInstance)
)

export const selectReportInstanceListLoading = createSelector(
    selectReportingState,
    state => state.reportInstanceListLoading
)

export const selectReportInstanceListMeta = createSelector(
    selectReportingState,
    state => state.meta,
)

export const selectReportInstanceListMetaTotal = createSelector(
    selectReportingState,
    state => state.meta.total,
)

export const selectRows = createSelector(
    selectReportingState,
    state => state.rows
)
export const selectRowsLoading = createSelector(
    selectReportingState,
    state => state.rowsLoading
)

export const selectSaveLoading = createSelector(
    selectReportingState,
    state => state.saveReportStatus
)