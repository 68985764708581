import {Dictionary} from "@reduxjs/toolkit";
import {Employer} from "../../../models/employer";
import {StateStatus} from "../../../common/enums";
import {Salesman} from "../../../models/salesman";
import {Team} from "../../../models/team";

export class CustomerProfileParams {
    employer_name?: string = undefined;
    salesman?: string = undefined;
    status?: number = undefined;
    create_from?: string = undefined;
    create_to?: string = undefined;
    receive_from?: string = undefined;
    receive_to?: string = undefined;
    interact_from?: string = undefined;
    interact_to?: string = undefined;
    limit: number = 10;
}

export class CustomerProfileSate {
    ids: number[] = [];
    entities: Dictionary<Employer> = {};
    params = new CustomerProfileParams();
    status: StateStatus = 'idle';
    meta: PaginationType = {} as PaginationType;
    listLoaded = false;
    salesman: Salesman[] = [];
    salesmanStatus: StateStatus = 'idle';
    teams: Team[] = [];
    teamsStatus: StateStatus = 'idle';
}
