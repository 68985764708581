import AppState from "../../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {ActivityLog} from "../../../../models/activity-log";

export const selectServiceActivationState = (state: AppState) => state.serviceActivationHistory;

export const selectAllServiceActivation = createSelector(
    selectServiceActivationState,
    state => state.entities
)

export const selectDateMappingServiceActivation = createSelector(
    selectServiceActivationState,
    state => state.dateMapping
)

export const selectTimeline = createSelector(
    selectServiceActivationState,
    state => {
            return Object.keys(state.dateMapping).map(date => ({
                    date,
                    timeline: state.dateMapping[date]?.map(id => state.entities[id] as ActivityLog)
            }))
    }
)
