import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MarketInsightState} from "./state";
import {Rest} from "../../common/models/rest";
import {ReportingBuilderModel} from "../../models/reporting-builder-model";

const slice = createSlice({
    name: 'reporting',
    initialState: {...new MarketInsightState()},
    reducers: {
        refreshFilter(state: MarketInsightState, {payload}: PayloadAction<any>) {
            state.loadFilterStatus = 'loading';
        },
        refreshFilterDone(state: MarketInsightState, {payload}: PayloadAction<Rest<any>>) {
            payload.data?.components?.forEach((com: ReportingBuilderModel) => {
                state.filters[com.code] = com;
            })
            state.loadFilterStatus = 'success';
        },
        refreshChartConfig(state: MarketInsightState, {payload}: PayloadAction<any>) {
            state.loadChartConfigStatus = 'loading';
        },
        refreshChartConfigDone(state: MarketInsightState, {payload}: PayloadAction<Rest<any>>) {
            state.configChart = payload.data;
            state.loadChartConfigStatus = 'success';
        },
        refreshChartConfigFail(state: MarketInsightState) {
            state.loadChartConfigStatus = 'idle';
        },
    }
});

export const {
    refreshFilter,
    refreshFilterDone,
    refreshChartConfig,
    refreshChartConfigDone,
    refreshChartConfigFail,
} = slice.actions;

const marketInsight = slice.reducer;

export default marketInsight;