import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Employee} from "../../models/employee";

export const selectAccountPermissionState = (state: AppState) => state.accountPermission;

export const selectAccountPermissionEntities = createSelector(
    selectAccountPermissionState,
    state => state.ids.map(id => state.entities[id] as Employee)
);
export const selectAccountPermissionIds = createSelector(
    selectAccountPermissionState,
    state => state.ids
);

export const selectAccountPermissionLoading = createSelector(
    selectAccountPermissionState,
    state => state.status === 'loading'
);
