import axios from "../axios";
import {Rest} from "../models/rest";
import {BASE_URL} from "../../config/properties";

export enum JobStatus {
    FINISHED = 'finished',
    EXECUTING = 'executing',
    QUEUED = 'queued'
}

export enum JobType {
    REPORT_JOB = 'report_job',
}

export type JobInfo = {
    id: string
    progress: number,
    status: JobStatus,
    status_str: string,
    type: JobType,
    message: string,
    output: any,
    is_close?: boolean,
}

export class BackendService {
    getJobInfo(id: number): any {
        return axios.get<any, Rest<JobInfo>>(
            `${BASE_URL}/queue-jobs/${id}`,
        );
    };
    getJobs(): any {
        return axios.get<any, Rest<JobInfo[]>>(
            `${BASE_URL}/queue-jobs`,
        );
    };
    readJobs(id: any): any {
        return axios.post<any, Rest<JobInfo[]>>(
            `${BASE_URL}/queue-jobs/${id}/read`,
        );
    };
}

const backendService = new BackendService();

export default backendService;
