import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomerDetailState} from "./state";
import {LeadInfo} from "../../../models/lead-info";

const slice = createSlice({
    name: 'customerDetail',
    initialState: {...new CustomerDetailState()},
    reducers: {
        refreshCustomerInfo(state: CustomerDetailState, {payload}: PayloadAction<number>) {
            state.loading = true;
        },
        customerInfoLoaded(state, {payload}: PayloadAction<LeadInfo>) {
            state.loading = false;
            state.customerInfo = payload;
        }
    }
})

export const {
    refreshCustomerInfo,
    customerInfoLoaded
} = slice.actions;

export default slice.reducer;
