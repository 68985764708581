import firebaseConfig from "../config/firebase";
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {APP_DEBUG} from "../config/properties";
// import {onBackgroundMessage} from 'firebase/messaging/sw';

const firebaseApp = initializeApp(firebaseConfig);
if (APP_DEBUG) {
    console.info("Firebase config:", firebaseConfig);
}

getAuth(firebaseApp).languageCode = "VN";

export class CloudMessaging {
    private readonly messaging;
    private static _instance : CloudMessaging | null = null

    constructor() {
        this.messaging = getMessaging(firebaseApp);
    }

    public static instance()
    {
        if (!CloudMessaging._instance) {
            CloudMessaging._instance = new CloudMessaging()
        }
        return CloudMessaging._instance
    }
    askRequestPermission() {
        // do nothing, the get token already request permission in firebase 9
        // return this.messaging.requestPermission();
    }
    getToken() {
        return getToken(this.messaging, {vapidKey: firebaseConfig.vapidKey});
    }
    onMessageListener() {
        try {
            onMessage(this.messaging, this.handleIncomeNotification);
            // onBackgroundMessage(this.messaging, this.handleIncomeNotification);
        } catch (e) {
            console.error(e);
        }
    }
    handleIncomeNotification(payload: any) {
        const { icon, body, image, click_action, title } = payload.notification;
        const options = {
            image,
            icon,
            body,
        };
        localStorage.setItem("isNewNoti", "true");
        const noti = new Notification(title, options);
        if (click_action) {
            noti.onclick = function () {
                window.open(click_action);
            };
        }
    }
}
