import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {NotificationParams, NotificationState} from "./state";
import {Rest} from "../../common/models/rest";
import {Notification} from "../../models/notification";

const slice = createSlice({
    name: 'notification',
    initialState: {...new NotificationState()},
    reducers: {
        loadAllNotification(state: NotificationState, {payload}: PayloadAction<NotificationParams>) {
            state.allNotificationLoading = true;
        },
        loadNotificationTable(state: NotificationState, {payload}: PayloadAction<NotificationParams>) {
            state.loading = true;
        },
        loadCountNew(state: NotificationState) {
            state.countNewLoading = true;
        },
        allNotificationLoaded(state: NotificationState, {payload}: PayloadAction<Rest<Notification[]>>) {
            const allNotification: Dictionary<Notification> = {};
            const getIds = (notiData: Notification[]) => {
                return notiData.map(notification => {
                    const {...restInfo} = notification;
                    if (restInfo) allNotification[notification.id] = restInfo;
                    return notification.id;
                })
            };
            const allIds = getIds(payload.data);
            return {
                ...state,
                allNotification,
                allIds,
                allNotiMeta: payload.meta,
                allNotificationLoading: false,
            };
        },
        countNewLoaded(state: NotificationState, {payload}: PayloadAction<Rest<any>>) {
            return {
                ...state,
                countNew: payload.data.new
            };
        },
        notificationTableLoaded(state: NotificationState, {payload}: PayloadAction<Rest<Notification[]>>) {
            const tableData: Dictionary<Notification> = {};
            const getIds = (notiData: Notification[]) => {
                return notiData.map(notification => {
                    const {...restInfo} = notification;
                    if (restInfo) tableData[notification.id] = restInfo;
                    return notification.id;
                })
            };
            const ids = getIds(payload.data);
            return {
                ...state,
                tableData,
                ids,
                meta: payload.meta,
                loading: false,
            };
        },
        markNotificationAsRead(state: NotificationState, {payload}: PayloadAction<{id: number, read: boolean}>) {
            let noti = state.tableData[payload.id]
            if (noti) {
                noti.read_at = (payload.read ? Date.now().toString() : undefined)
            }
            noti = state.allNotification[payload.id]
            if (noti) {
                noti.read_at = (payload.read ? Date.now().toString() : undefined)
            }
        },
        markAsReadAll(state: NotificationState, {payload}: PayloadAction<boolean>) {
            state.markAsReadAllLoading = true
        },
        markAsReadAllCompleted(state: NotificationState, {payload}: PayloadAction<Rest<any>>) {
            for (let key in state.tableData) {
                state.tableData[key]!.read_at = (payload.data.read ? Date.now().toString() : undefined)
            }
            for (let key in state.allNotification) {
                state.allNotification[key]!.read_at = (payload.data.read ? Date.now().toString() : undefined)
            }
        }
    }
});

export const {
    loadAllNotification,
    allNotificationLoaded,
    loadNotificationTable,
    notificationTableLoaded,
    countNewLoaded,
    loadCountNew,
    markNotificationAsRead,
    markAsReadAll,
    markAsReadAllCompleted
} = slice.actions;

const notification = slice.reducer;

export default notification;