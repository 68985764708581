import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";
import {Role} from "../../../models/role";
import {CRUDService} from "../../../common/service";


export class RoleListService extends CRUDService<Role>{
    getALlRole() {
        return axios.get<Rest<Role[]>>(
            `${BASE_URL}/employee/roles`
        );
    }

    getNameSpace(): string {
        return "roles";
    }
}

const roleListService = new RoleListService();

export default roleListService;
