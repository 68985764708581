import AppState from "../../../App.state";
import {createSelector} from "@reduxjs/toolkit";
import {Employer} from "../../../models/employer";

export const selectCustomerProfileState = (state: AppState) => state.customerProfile;

export const selectCustomerProfileStatus = createSelector(
    selectCustomerProfileState,
    state => state.status
)

export const selectCustomerProfileParams = createSelector(
    selectCustomerProfileState,
    state => state.params
)

export const selectCustomerProfileDataTable = createSelector(
    selectCustomerProfileState,
    state => state.ids.map(id => state.entities[id] as Employer)
)

export const selectCustomerProfileMeta = createSelector(
    selectCustomerProfileState,
     state => state.meta
)

export const selectAllSalesman = createSelector(
    selectCustomerProfileState,
    state => state.salesman
)

export const selectAllTeams = createSelector(
    selectCustomerProfileState,
    state => state.teams
)

export const selectStatusTeams = createSelector(
    selectCustomerProfileState,
    state => state.teamsStatus === 'loading'
)
