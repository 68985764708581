import {NotificationType} from "./enums/notification-type";
import {RouterEnum} from "../config/router.enum";

export interface Notification {
    id: number;
    type: number;
    title: string;
    content: string,
    data: any,
    created_at: string,
    read_at?: string,
}

export function getLink(notification: Notification) {
    if ([
        NotificationType.REQUEST_FOLLOW_TICKET
    ].includes(notification.type)) {
        return `/workflow/ticket/${notification.data.ticket_id}/ticket-processing?additional_employee=${notification.data.employee_id}`;
    }
    if ([
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_CREATOR,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_CURRENT_PROCESSING,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_FOLLOWING,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_CAN_NOT_ACCESS_USER,
        NotificationType.NEW_TICKET_COMMENT_FOR_CURRENT_PROCESSOR,
        NotificationType.NEW_TICKET_COMMENT_FOR_CREATED_EMPLOYEE,
    ].includes(notification.type)) {
        return `/workflow/${RouterEnum.tickets}/${notification.data.ticket_id}?ticket_comment_id=${notification?.data?.ticket_comment_id}`;
    }
    if ([
        NotificationType.TICKET_CANCELED_FOR_PROCESSING_EMPLOYEE,
        NotificationType.TICKET_CREATED_FOR_FOLLOWING_EMPLOYEE,
        NotificationType.NEW_TICKET_COMMENT_FOR_CURRENT_PROCESSOR,
        NotificationType.NODE_PROCESSED,
        NotificationType.TICKET_CANCELED_FOR_CREATED_EMPLOYEE,
        NotificationType.TICKET_CANCELED_FOR_FOLLOWING_EMPLOYEE,
        NotificationType.TICKET_CREATED_FOR_PROCESSING_EMPLOYEE,
        NotificationType.TICKET_DONE_FOR_CREATED_EMPLOYEE,
        NotificationType.TASK_NEED_PROCESS,
        NotificationType.TASK_OUTDATED_FOR_CREATED_EMPLOYEE,
        NotificationType.TASK_OUTDATED_FOR_FOLLOWING_EMPLOYEE,
        NotificationType.TASK_OUTDATED_FOR_PROCESSING_EMPLOYEE,
        NotificationType.TICKET_DONE_FOR_FOLLOWING_EMPLOYEE,
        NotificationType.TICKET_NODE_HAS_NEW_ASSIGN,
        NotificationType.TICKET_HAS_NEW_ADDITIONAL_FOLLOWING_EMPLOYEE,
        NotificationType.NEW_TICKET_COMMENT_FOR_CREATED_EMPLOYEE,
        NotificationType.TICKET_NODE_HAS_NEW_ASSIGN_FOR_CREATED_EMPLOYEE,
        NotificationType.TICKET_NODE_HAS_NEW_ASSIGN_FOR_FOLLOWING_EMPLOYEE,
        NotificationType.TICKET_NODE_HAS_NEW_ASSIGN_FOR_ASSIGNED_EMPLOYEE,
        NotificationType.TICKET_OUT_DATED_FOR_CREATED_EMPLOYEE,
        NotificationType.TICKET_OUT_DATED_FOR_FOLLOWING_EMPLOYEE,
        NotificationType.TICKET_OUT_DATED_FOR_CURRENT_PROCESSOR,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_CREATOR,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_CURRENT_PROCESSING,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_FOLLOWING,
        NotificationType.TICKET_COMMENT_TAG_NOTIFICATION_OF_CAN_NOT_ACCESS_USER,
    ].includes(notification.type)) {
        return `/workflow/${RouterEnum.tickets}/${notification.data.ticket_id}`;
    }
    return ''
}