import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {safeCall} from "../../common/utils";
import {
    refreshChartConfig,
    refreshChartConfigDone,
    refreshChartConfigFail,
    refreshFilter,
    refreshFilterDone
} from "./reducer";
import {MarketInsightState} from "./state";
import {selectMarketInsightState} from "./selector";
import {Rest} from "../../common/models/rest";
import {FilterItem} from "../../models/reporting-builder-model";
import marketInsightService from "./service";
import {ConfigChart} from "../../models/market-insight";

const sagas = [
    takeLatest(refreshFilter, safeCall(function* ({payload}) {
        const {loadFilterStatus}: MarketInsightState = yield select(selectMarketInsightState);
        if (loadFilterStatus !== 'loading') {
            return;
        }
        const response: Rest<FilterItem[]> = yield call(marketInsightService.getFilters, payload);
        yield put(refreshFilterDone(response));
    })),
    takeLatest(refreshChartConfig, safeCall(function* ({payload}) {
        const {loadChartConfigStatus}: MarketInsightState = yield select(selectMarketInsightState);
        if (loadChartConfigStatus !== 'loading') {
            return;
        }
        try {
            const response: Rest<ConfigChart> = yield call(marketInsightService.getChartConfigs, payload);
            yield put(refreshChartConfigDone(response));
        } catch (e) {
            yield put(refreshChartConfigFail());
        }
    })),
];

function* marketInsightSagas() {
    yield all(sagas);
}

export default marketInsightSagas;