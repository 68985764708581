import {CRUDService} from "../../../common/service";
import {Employer} from "../../../models/employer";
import {RouterEnum} from "../../../common/enums";
import {CustomerProfileParams} from "./state";
import axios from "../../../common/axios";
import {Salesman} from "../../../models/salesman";
import {BASE_URL} from "../../../config/properties";
import {Rest} from "../../../common/models/rest";
import {Team} from "../../../models/team";

export class CustomerProfileService extends CRUDService<Employer, any, CustomerProfileParams> {
    getNameSpace(): string {
        return RouterEnum.leadManagement;
    }

    getAllSalesman() {
        return axios.get<Rest<Salesman[]>>(
            `${BASE_URL}/${RouterEnum.leadManagement}/salesman/all`
        )
    }

    getAllTeams() {
        return axios.get<Rest<Team[]>>(`${BASE_URL}/employee/teams`);
    }
}

const customerProfileService = new CustomerProfileService();

export default customerProfileService;
