import { createSlice, Dictionary, PayloadAction} from '@reduxjs/toolkit';
import { AccountPermissionState } from './state';
import { Rest } from "../../common/models/rest";
import {Employee} from "../../models/employee";

const slice = createSlice({
    name: 'accountPermission',
    initialState: {...new AccountPermissionState()},
    reducers: {
        listAccountPermission(state: AccountPermissionState) {
            if (state.status === 'idle') {
                state.status = 'loading';
            }
        },
        listAccountPermissionLoaded(state: AccountPermissionState, {payload}: PayloadAction<Rest<Employee[]>>) {
            const entities: Dictionary<Employee> = {};
            const getIds = (employees: Employee[]) => {
                return employees.map(employee => {
                    const {...restInfo} = employee;
                    entities[employee.id] = restInfo;
                    return employee.id;
                })
            };
            const ids = getIds(payload.data);
            return {
                ...state,
                status: 'idle',
                entities,
                ids,
            };
        },
    }
});

export const {listAccountPermission, listAccountPermissionLoaded} = slice.actions;

const accountPermissionReducer = slice.reducer;

export default accountPermissionReducer;
