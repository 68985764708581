import {useLocation} from 'react-router-dom';
import {useMemo} from 'react';
import toNumber from 'lodash/toNumber';

function useQuery(name?: string) {
    const location = useLocation();
    return useMemo(() => {
        const params = new URLSearchParams(location.search);
        if (name) {
            const dirtyParams = params.get(name);
            return dirtyParams ? (toNumber(dirtyParams) || dirtyParams) : dirtyParams;
        }
        const result: any = {};
        params.forEach((value, key) => {
            const val = value ? (toNumber(value) || value) : value
            if (key.includes('[') && key.includes(']')) {
                const keys = key.replaceAll(']', '').split('[')
                let obj = result
                for (let i = 0; i < keys.length; i++) {
                    const objKey = keys[i]
                    if (i !== keys.length - 1) {
                        if (!obj[objKey]) {
                            obj[objKey] = {}
                        }
                        obj = obj[objKey]
                    } else {
                        obj[objKey] = val
                    }
                }
            } else {
                result[key] = value ? (toNumber(value) || value) : value;
            }
        });
        return result;
    }, [location, name]);
}

export default useQuery;
