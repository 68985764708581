import {ItemType} from "antd/es/menu/hooks/useItems";
import {MenuData} from "../SiderLayout";
import WithParamsLink from "../../common/components/WithParamsLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Menu} from "antd";
import {useIsAuthorized} from "../../common/components/NeedAuthorize";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {Dictionary} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {selectCountTicketUnread} from "../../common/backend-util/selectors";
import {useLocation} from "react-router-dom";
import {RouterEnum} from "../../config/router.enum";
import {backendRefreshTicketUnread} from "../../common/backend-util/store";
import auth from "../../features/auth/auth";

const MenuRender = ({menuData, absolutePath, collapsed, currentPathname}: {menuData: MenuData[], absolutePath: string, collapsed: boolean, currentPathname: string}) => {
    const isAuthorized = useIsAuthorized();
    const {t} = useTranslation();
    const [dataCount, setDataCount] = useState<Dictionary<any>>({});
    const countUnread = useSelector(selectCountTicketUnread);
    const location = useLocation();
    const dispatch = useDispatch();
    const employeeId = auth.employee?.id;

    useEffect(() => {
        if (location.pathname?.includes(RouterEnum.workflow) && employeeId) {
            dispatch(backendRefreshTicketUnread(employeeId))
        }
    }, [location.pathname, dispatch, employeeId]);

    useEffect(() => {
        menuData.forEach(m => {
            if (m.configShowCount) {
                const count = countUnread;
                setDataCount(old => ({...old, [m.configShowCount?.key ?? '']: count}))
            }
        })
    }, [menuData, setDataCount, countUnread]);

    const menuItems: ItemType[] = useMemo(() => {
        return menuData
            ?.filter(menu => isAuthorized(menu.permissions as any))
            ?.map((menu, idx) => {
                return {
                    className: (collapsed && menu.configShowCount?.isShow) ? 'custom-item-selected' : '',
                    style: {marginBottom: '10px', paddingLeft: '12px', marginTop: idx === 0 ? 15 : undefined},
                    key: `${absolutePath}/${menu.path}`,
                    label: <WithParamsLink
                        to={`${absolutePath}/${menu.path}`}
                        className={'font-small-title flex items-center custom-menu-text-tooltip ' + (currentPathname === `${absolutePath}/${menu.path}` ? 'custom-menu-text' : '')}
                    >
                        {
                            collapsed && menu.configShowCount?.isShow  ?
                                <>
                                    {
                                        dataCount[menu.configShowCount.key] > 0 ?
                                            (
                                                dataCount[menu.configShowCount.key] >= 10000  ?
                                                    <span className={'text-white text-10 absolute h-30 w-fit'} style={{top: -5, right: 5}}>
                                                        <span style={{background: '#F84A4A'}} className={'p-2 rounded-10'}>9999+</span>
                                                    </span> :
                                                    <span className={'text-white text-10 absolute h-30 w-fit'} style={{top: -5, right: 5}}>
                                                        <span style={{background: '#F84A4A'}} className={'p-2 rounded-10'}>{dataCount[menu.configShowCount.key]}</span>
                                                    </span>
                                            )
                                            : undefined
                                    }

                                    <FontAwesomeIcon
                                        className={'w-20 opacity-80 mr-4 p-8 ' + (currentPathname === `${absolutePath}/${menu.path}` ? 'custom-menu-icon rounded-full' : '')}
                                        icon={menu.icon}
                                        fontSize={18}
                                    />
                                </>
                                : <FontAwesomeIcon
                                    className={'w-20 opacity-80 mr-4 p-8 ' + (currentPathname === `${absolutePath}/${menu.path}` ? 'custom-menu-icon rounded-full' : '')}
                                    icon={menu.icon}
                                    fontSize={18}
                                />
                        }
                        <span className={collapsed ? 'ml-20' : ''}>{t(menu.title)}</span>
                        {menu.configShowCount?.isShow && !collapsed ? <Badge className={'ml-10 rounded-full font-semi-bold'} style={{background: '#F84A4A'}} count={dataCount[menu.configShowCount.key]} showZero={false} overflowCount={9999}/> : undefined}
                    </WithParamsLink>
                }
            })
    }, [menuData, absolutePath, currentPathname, dataCount, isAuthorized, t, collapsed]);

    return (
        <Menu mode="inline"
              className={'border-r-0 bg-inherit custom-menu-selected relative'}
              items={menuItems}
        />
    )
}

export default MenuRender;