import {CRUDService} from "../../../common/service";
import {LeadInfo} from "../../../models/lead-info";
import {RouterEnum} from "../../../common/enums";
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";

export class CustomerDetailService extends CRUDService<LeadInfo> {
    getNameSpace(): string {
        return RouterEnum.leadManagement;
    }
    getLeadPhones(id: string | number): any {
        return axios.get<any, Rest<{lead_phones: string[], employer_phone: string}>>(`${BASE_URL}/${this.getNameSpace()}/${id}/lead-phones`);
    }
}

const customerDetailService = new CustomerDetailService();

export default customerDetailService;
