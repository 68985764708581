import {NodeModel} from "../../../models/node-model";
import {Node} from "reactflow";
import {convertMinutesToDay} from "../../../common/utils/time";
import {TicketNodeStatus} from "../../../models/enums/ticket-node-status";

export const setNode = (node: NodeModel): Node => {
    return {
        id: String(node.id),
        type: String(node.type),
        data: {
            name: node.name ?? '',
            processing_departments: node.processing_departments,
            processing_employees: node.processing_employees,
            processing_department_positions: node.processing_department_positions,
            processing_roles: node.processing_roles,
            processing_employee_special_types: node.processing_employee_special_types,
            life_time: node?.life_time ? convertMinutesToDay(node?.life_time) : '',
            description: node.description ?? '',
            deadline: node.deadline,
            status: node.status ?? TicketNodeStatus.INITIAL,
            processing_note: node.processing_note ?? '',
            processing_files: node.processing_files ?? [],
            is_employee_can_assign: node.is_employee_can_assign,
            is_employee_can_next: node.is_employee_can_next,
            is_employee_can_update_result: node.is_employee_can_update_result,
            next: node?.next,
            is_employee_created_ticket_can_assign: node.is_employee_created_ticket_can_assign,
            is_previous_step_can_assign: node.is_previous_step_can_assign,
            is_previous_step_assigned: node.is_previous_step_assigned,
            trigger_employee_id: node.trigger_employee_id,
            processed_number: node.processed_number,
        },
        position: node?.node_meta?.position
    };
}