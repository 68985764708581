import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomerProfileParams, CustomerProfileSate} from "./state";
import {Rest} from "../../../common/models/rest";
import {Employer} from "../../../models/employer";
import {keyBy} from "lodash";
import {Salesman} from "../../../models/salesman";
import {Team} from "../../../models/team";

const slice = createSlice({
    name: 'customerProfile',
    initialState: {...new CustomerProfileSate()},
    reducers: {
        refreshCustomerProfileList(state: CustomerProfileSate, {payload}: PayloadAction<CustomerProfileParams>) {
            state.status = 'loading'
        },
        listCustomerProfileLoaded(state: CustomerProfileSate, {payload}: PayloadAction<Rest<Employer[]>>) {
            state.meta = payload.meta;
            state.ids = payload.data.map(({id}) => (id));
            state.entities = keyBy(payload.data, 'id');
            state.listLoaded = true;
            state.status = 'idle';
        },
        refreshSalesman(state: CustomerProfileSate) {
            state.salesmanStatus = 'loading'
        },
        salesmanLoaded(state: CustomerProfileSate, {payload}: PayloadAction<Rest<Salesman[]>>) {
            state.salesman = payload.data;
            state.salesmanStatus = 'idle';
        },
        refreshTeams(state: CustomerProfileSate) {
            state.teamsStatus = 'loading';
        },
        teamsLoaded(state: CustomerProfileSate, {payload}: PayloadAction<Rest<Team[]>>) {
            state.teams = payload.data;
            state.teamsStatus = 'idle';
        }
    }
})

export const {
    refreshCustomerProfileList,
    listCustomerProfileLoaded,
    refreshSalesman,
    salesmanLoaded,
    refreshTeams,
    teamsLoaded
} = slice.actions;

const customerProfileReducer = slice.reducer;

export default customerProfileReducer;
