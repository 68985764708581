import {Department} from "../../../models/department";
import {Dictionary} from "@reduxjs/toolkit";
import {Position} from "../../../models/position";
import {StateStatus} from "../../../common/enums";

export class DepartmentState {
    rootId?: number = undefined;
    entities: Dictionary<Department> = {};
    childMapping: Dictionary<number[]> = {};
    posMapping: Dictionary<number[]> = {};
    positions: Dictionary<Position> = {};
    listStatus: StateStatus = 'idle';

    formStatus: StateStatus = 'idle';
    interactingIds: number[] = [];
    errors?: any;
}
