import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {safeCall} from "../../../../common/utils";
import {listPaymentLoaded, refreshListPayment} from "./reducer";
import {PaymentHistoryState} from "./state";
import {selectPaymentState} from "./selector";
import {Payment} from "../../../../models/payment";
import {Rest} from "../../../../common/models/rest";
import paymentHistoryService from "./service";

const sagas = [
    takeEvery(refreshListPayment, safeCall(function* ({payload}) {
        const {status}: PaymentHistoryState = yield select(selectPaymentState);
        if (status !== 'loading') {
            return;
        }

        const response: Rest<Payment[]> = yield call(paymentHistoryService.getAllPayment, payload);
        yield put(listPaymentLoaded(response));
    }))
];

function* paymentHistorySagas() {
    yield all(sagas);
}

export default paymentHistorySagas;
