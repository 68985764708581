import AppState from '../../../App.state';
import {createSelector} from '@reduxjs/toolkit';

export const selectCustomerDashboardState = (state: AppState) => state.customerDashboard;

export const selectCustomerOnlineBarParams = createSelector(
    selectCustomerDashboardState,
    state => state.onlineBarParams
);

export const selectCountCustomersOnline = createSelector(
    selectCustomerDashboardState,
    state => state.onlineBarMeta.total ?? 0
);

export const selectCustomersOnlineBar = createSelector(
    selectCustomerDashboardState,
    state => Object.values(state.onlineBarEntities)
);
export const selectCustomerDataOnlineBar = createSelector(
    selectCustomerDashboardState,
    state => state.dataOnlineBar
);
