import {all, select, put, takeLeading, call} from 'redux-saga/effects';
import {CustomerDashboardState, CustomerOnlineBarParams} from './state';
import {selectCustomerDashboardState, selectCustomerOnlineBarParams} from './selectors';
import customerDashboardService from '../RealtimeOnline/service';
import {
    refreshListOnlineBarCustomers,
    listOnlineBarCustomersLoaded,
} from './reducer';
import {safeCall} from '../../../common/utils';
import {Rest} from '../../../common/models/rest';
import {CustomerConnect} from "../../../models/customer-connect";

const sagas = [
    takeLeading(refreshListOnlineBarCustomers, safeCall(function* () {
        const { onlineBarStatus }: CustomerDashboardState = yield select(selectCustomerDashboardState);
        const  onlineBarParams: CustomerOnlineBarParams = yield select(selectCustomerOnlineBarParams);

        if (onlineBarStatus !== 'loading') {
            return;
        }
        const response: Rest<CustomerConnect[]> = yield call(customerDashboardService.list, onlineBarParams);
        yield put(listOnlineBarCustomersLoaded(response));
    })),
];

function* customerDashboardSagas() {
    yield all(sagas);
}

export default customerDashboardSagas;
