import {all, call, put, select, takeEvery, takeLeading} from "redux-saga/effects";
import {
    listCustomerProfileLoaded,
    refreshCustomerProfileList,
    refreshSalesman,
    refreshTeams,
    salesmanLoaded, teamsLoaded
} from "./reducer";
import {safeCall} from "../../../common/utils";
import {CustomerProfileSate} from "./state";
import {selectCustomerProfileState} from "./selector";
import {Employer} from "../../../models/employer";
import {Rest} from "../../../common/models/rest";
import customerProfileService from "./service";
import {Salesman} from "../../../models/salesman";
import {Team} from "../../../models/team";

const sagas = [
    takeEvery(refreshCustomerProfileList, safeCall(function* ({payload}) {
        const {status}: CustomerProfileSate = yield select(selectCustomerProfileState);
        if (status !== 'loading') {
            return;
        }

        const response: Rest<Employer[]> = yield call(customerProfileService.list, payload);
        yield put(listCustomerProfileLoaded(response));
    })),
    takeLeading(refreshSalesman, safeCall(function* () {
        const {salesmanStatus}: CustomerProfileSate = yield select(selectCustomerProfileState);

        if (salesmanStatus !== 'loading') {
            return;
        }
        const response: Rest<Salesman[]> = yield call(customerProfileService.getAllSalesman);
        yield put(salesmanLoaded(response));
    })),
    takeLeading(refreshTeams, safeCall(function* () {
        const {teamsStatus}: CustomerProfileSate = yield select(selectCustomerProfileState);

        if (teamsStatus !== 'loading') {
            return;
        }
        const response: Rest<Team[]> = yield call(customerProfileService.getAllTeams);
        yield put(teamsLoaded(response));
    }))
]

function* customerProfileSagas() {
    yield all(sagas);
}

export default customerProfileSagas;
