import {createSelector} from "@reduxjs/toolkit";
import AppState from "../../App.state";

const selectBackendState = (state: AppState) => state.backendErrors;

export const selectProcessingJobs = createSelector(
    selectBackendState,
    state => state.jobs,
);

export const selectCountTicketUnread = createSelector(
    selectBackendState,
    state => state.countTicketUnread,
);
