import {LeadInfo} from "../../../models/lead-info";

export class PageViewHistoryParams {

}



export class CustomerDetailState {
    loading: boolean = false;
    customerInfo?: LeadInfo = undefined;
}
