import {RouterEnum} from "../../../config/router.enum";
import {Ticket} from "../../../models/ticket";
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";
import {Workflow} from "../../../models/workflow";
import {CRUDService} from "../../../common/service";
import {Node} from "reactflow";
import {Comment} from "../../../models/comment";
import {HasIdModel} from "../../../common/models/has-id";

interface TicketDetailParams {
    id: number;
}

interface CreateTicketParams {
    name: string;
    workflow_id: number;
    description: string;
}

export class TicketDetailService extends CRUDService<Ticket, CreateTicketParams>{
    getNameSpace(): string {
        return RouterEnum.tickets;
    }
    getComments(params: TicketDetailParams) {
        return axios.get<Rest<Ticket>>(
            `${BASE_URL}/${RouterEnum.tickets}/${params.id}/comments`
        );
    }
    getWorkflow(params: any) {
        return axios.get<Rest<Workflow>>(
            `${BASE_URL}/${RouterEnum.workflows}/${params.id}`
        );
    }
    getNodeDetail(params: any) {
        return axios.get<Rest<Workflow>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${params.id}`
        );
    }
    nextAction(params: any) {
        return  axios.put<Rest<Node>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${params.id}/next`
        );
    }
    nextGetProcessingNode(params: HasIdModel) {
        return  axios.put<Rest<Node>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${params.id}/next-get-processing-node`
        );
    }
    updateTask(params: any) {
        return  axios.put<Rest<Node>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${params.id}/update-result`,
            {
                processing_note: params.processing_note,
                processing_files: params.processing_files,
                next_to: params.nextTo,
                form_data: params.form_data,
                assign_next: params.assign_next
            }
        );
    }
    updateAndNextTask(params: any) {
        return  axios.put<Rest<Node>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${params.id}/update-result-and-next`,
            {
                processing_note: params.processing_note,
                processing_files: params.processing_files,
                next_to: params.nextTo,
                form_data: params.form_data,
                assign_next: params.assign_next
            }
        );
    }
    updateTaskAndNextGetProcessingNode(params: any) {
        return  axios.put<Rest<Node>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${params.id}/update-result-and-next-get-processing-node`,
            {
                processing_note: params.processing_note,
                processing_files: params.processing_files,
                next_to: params.nextTo,
                form_data: params.form_data,
                assign_next: params.assign_next
            }
        );
    }
    createComment(data: any) {
        const id = data.ticketId;
        return axios.post<Rest<Comment>>(
            `${BASE_URL}/${RouterEnum.tickets}/${id}/comments`,
            data
        );
    }
    assignTicketNode(data: any) {
        return axios.put<Rest<any>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${data.id}/assign`,
            data
        );
    }
    cancelTicket({id, ...data}: any) {
        return axios.delete<Rest<any>>(
            `${BASE_URL}/${RouterEnum.tickets}/${id}`,
            {data}
        );
    }
    setReceiveNotification(data: any) {
        return axios.put<Rest<any>>(
            `${BASE_URL}/${RouterEnum.tickets}/${data.id}/set-received-notification`,
            {receive: data.receive}
        );
    }
    updateFollowing(data: any) {
        return axios.put<Rest<any>>(
            `${BASE_URL}/${RouterEnum.tickets}/${data.id}/additional-following-employees`,
            {employee_ids: data.employee_ids}
        );
    }
    requestFollow({id}: TicketDetailParams) {
        return axios.post<Rest<Comment>>(
            `${BASE_URL}/${RouterEnum.tickets}/${id}/request-follow`
        );
    }
    acceptRequestFollow(data: any) {
        return axios.post<Rest<any>>(
            `${BASE_URL}/${RouterEnum.tickets}/${data.id}/additional-following-employees`,
            {employee_id: data.employee_id}
        );
    }
    assignMultipleTicketNode(params: any) {
        return  axios.put<Rest<Node>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/bulk-assign`,
            params
        )
    }
    getTicketDetailLite(id: number) {
        return axios.get<Rest<Workflow>>(
            `${BASE_URL}/${RouterEnum.tickets}/${id}/lite`
        );
    }
    getCurrentProcessingNode(id: number) {
        return axios.get<Rest<Workflow>>(
            `${BASE_URL}/${RouterEnum.tickets}/${id}/first-processing-nodes`
        );
    }
    getFormResultNode(id: number) {
        return axios.get<Rest<Workflow>>(
            `${BASE_URL}/${RouterEnum.ticket_node}/${id}/form-result`
        );
    }
}
const ticketDetailService = new TicketDetailService();

export default ticketDetailService;
