import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {customerInfoLoaded, refreshCustomerInfo} from "./reducer";
import {safeCall} from "../../../common/utils";
import {CustomerDetailState} from "./state";
import {selectCustomerDetailState} from "./selector";
import {Rest} from "../../../common/models/rest";
import {LeadInfo} from "../../../models/lead-info";
import customerDetailService from "./service";

const sagas = [
    takeLeading(refreshCustomerInfo, safeCall(function* ({payload}) {
        const { loading }: CustomerDetailState = yield select(selectCustomerDetailState)
        if (!loading) return

        const response: Rest<LeadInfo> = yield call(customerDetailService.single, payload)
        yield put(customerInfoLoaded(response.data))
    }))
]
function* customerDetailSagas() {
    yield all(sagas)
}

export default customerDetailSagas;
