import {ReportingBuilderModel, TypeReporting} from "../../../models/reporting-builder-model";
import {ReportingInstance} from "../../../models/reporting-instance";
import {Dictionary} from "@reduxjs/toolkit";
import {StateStatus} from "../../../common/enums";


export class ReportingState {
    reportBuilder: ReportingBuilderModel = {
        code: '',
        name: '',
        columns: [],
        filters: [],
    };
    reportBuilderLoading: boolean = false;
    typeReportingBuilder: TypeReporting[] = [];
    typeReportingBuilderLoading: boolean = false;
    reportInstanceListLoading: boolean = false;
    reportInstanceList: Dictionary<ReportingInstance> = {};
    reportInstanceListIds: number[] = [];
    meta: PaginationType = {} as PaginationType;
    saveReportStatus: StateStatus = 'idle';
    rows: any[] = [];
    rowsLoading: boolean = false;
}