import {CRUDService} from "../../../../common/service";
import {Purchase} from "../../../../models/purchase";
import axios from "../../../../common/axios";
import {Rest} from "../../../../common/models/rest";
import {BASE_URL} from "../../../../config/properties";

class PurchaseHistoryService extends CRUDService<Purchase> {
    getNameSpace(): string {
        return "";
    }

    getAllPurchase(params: any) {
        return axios.get<Rest<Purchase[]>>(
            `${BASE_URL}/sales/invoices`,
            {params}
        );
    }
}

const purchaseHistoryService = new PurchaseHistoryService();

export default purchaseHistoryService;
