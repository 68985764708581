import { createSlice, Dictionary, PayloadAction} from '@reduxjs/toolkit';
import { ApplicationState } from './state';
import { Application } from "../../models/application";
import { Rest } from "../../common/models/rest";

const slice = createSlice({
    name: 'application',
    initialState: {...new ApplicationState()},
    reducers: {
        listApplications(state: ApplicationState) {
            if (state.status === 'idle') {
                state.status = 'loading';
            }
        },
        listApplicationsLoaded(state: ApplicationState, {payload}: PayloadAction<Rest<Application[]>>) {
            const entities: Dictionary<Application> = {};
            const getIds = (applications: Application[]) => {
                return applications.map(application => {
                    const {...restInfo} = application;
                    entities[application.slug] = restInfo;
                    return application.id;
                })
            };
            const ids = getIds(payload.data);
            const slugs = Object.keys(entities);
            return {
                ...state,
                status: 'idle',
                entities,
                ids,
                slugs
            };
        },
    }
});

export const {listApplications, listApplicationsLoaded} = slice.actions;

const applicationReducer = slice.reducer;

export default applicationReducer;
