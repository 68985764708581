import {ConfigProvider, message} from 'antd';
import vi from "antd/es/locale/vi_VN";
import customizeEmpty from "../utils/customizeEmpty";
import {PropsWithChildren, useMemo} from "react";
import {useTranslation} from "react-i18next";

message.config({
    top: '15%' as any
});

const AntdConfigProvider = ({children}: PropsWithChildren<any>) => {
    const {i18n} = useTranslation();

    const validateMessages = useMemo(() => {
        return i18n.getResourceBundle(i18n.language, 'validation');
    }, [i18n]);

    return (
        <ConfigProvider locale={vi}
                        renderEmpty={customizeEmpty('common:emptyData')}
                        form={{validateMessages, requiredMark: true}}
                        >
            {children}
        </ConfigProvider>
    )
};

export default AntdConfigProvider;
