import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { Rest } from "../../../common/models/rest";
import {ReportingState} from "./state";
import {ReportingBuilderModel, TypeReporting} from "../../../models/reporting-builder-model";
import {ReportingInstance} from "../../../models/reporting-instance";
import {keyBy} from "lodash";

const slice = createSlice({
    name: 'reporting',
    initialState: {...new ReportingState()},
    reducers: {
        loadReportingBuilder(state: ReportingState, {payload}: PayloadAction<string>) {
           state.reportBuilderLoading = true;
        },
        loadReportingBuilderDone(state: ReportingState, {payload}: PayloadAction<Rest<ReportingBuilderModel>>) {
            state.reportBuilder = payload.data;
            state.reportBuilderLoading = false;
        },
        loadTypeReportingBuilder(state: ReportingState) {
            state.typeReportingBuilderLoading = true;
        },
        loadTypeReportingBuilderDone(state: ReportingState, {payload}: PayloadAction<Rest<TypeReporting[]>>) {
            state.typeReportingBuilderLoading = false;
            state.typeReportingBuilder = payload.data;
        },
        loadingReportInstanceList(state: ReportingState, {payload}: PayloadAction<any>) {
            state.reportInstanceListLoading = true;
        },
        loadedReportInstanceList(state: ReportingState, {payload}: PayloadAction<Rest<ReportingInstance[]>>) {
            state.reportInstanceListLoading = false;
            state.meta = payload.meta;
            state.reportInstanceList = keyBy(payload.data, 'id');
            state.reportInstanceListIds = payload.data.map(({id}) => (id));
        },
        loadPreviewReport(state: ReportingState, {payload}: PayloadAction<any>) {
            state.rowsLoading = true;
        },
        loadPreviewReportDone(state: ReportingState, {payload}: PayloadAction<Rest<any>>) {
            state.rowsLoading = false;
            const dataRows: any[] = [];
            const columns = payload.data?.template?.columns ?? [];
            const rows = payload.data?.rows ?? [];

            rows.forEach((row: any[]) => {
                const result: any = {};
                row.forEach((item, index) => {
                    if (columns.at(index)?.code) {
                        result[(columns.at(index)?.code) as string] = item?.formatted_value ?? '';
                    }
                })
                dataRows.push(result);
            })

            state.rows = dataRows;
        },
        loadPreviewReportFail(state: ReportingState) {
          state.rowsLoading = false;
        },
        saveReport(state: ReportingState, {payload}: PayloadAction<any>) {
            state.saveReportStatus = 'loading';
        },
        saveReportDone(state: ReportingState) {
            state.saveReportStatus = 'success';
        },

    }
});

export const {
    loadReportingBuilder,
    loadReportingBuilderDone,
    loadTypeReportingBuilder,
    loadTypeReportingBuilderDone,
    loadingReportInstanceList,
    loadedReportInstanceList,
    loadPreviewReport,
    loadPreviewReportDone,
    saveReport,
    saveReportDone,
    loadPreviewReportFail,
} = slice.actions;

const reporting = slice.reducer;

export default reporting;