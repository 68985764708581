import axios from "../../../common/axios";
import { Rest } from "../../../common/models/rest";
import { BASE_URL } from "../../../config/properties";
import {CRUDService} from "../../../common/service";

const REPORT_TEMPLATE_BUILDERS = 'report-template-builders';

class ReportingService extends CRUDService<any> {
    getNameSpace(): string {
        return "report-instances";
    }

    getReportingBuilder = (type: string) => {
        return axios.get<Rest<any>>(`${BASE_URL}/${REPORT_TEMPLATE_BUILDERS}/${type}`);
    }

    getTypeReportingBuilder = () => {
        return axios.get<Rest<any>>(`${BASE_URL}/${REPORT_TEMPLATE_BUILDERS}`);
    }

    loadPreviewReport = (data: any) => {
        return axios.post<Rest<any>>(`${BASE_URL}/${this.getNameSpace()}/preview`, data);
    }
}

const reportingService = new ReportingService();

export default reportingService;