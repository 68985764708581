import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fontAwesomeIcons from "../../utils/fontAwesomeIcons";
import {ChangeLog} from "../../models/change-log";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectAllChangeLogs, selectLastChangeLogs} from "../../features/change-logs/selectors";
import startOfToday from "date-fns/startOfToday";
import startOfDay from "date-fns/startOfDay";
import {gt} from "semver";
import {loadAllChangeLogs} from "../../features/change-logs/reducer";
import {LOCAL_STORAGE_VERSION_KEY} from "../../config/properties";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import CustomShowDescription from "../../common/components/CustomShowDescription";
import {atom, useRecoilState} from "recoil";

interface InfoAppProps {
    hidden: boolean;
}

const ChangeLogItem = (cl: ChangeLog) => {
    return (
        <div className={'min-h-100 mt-10'}>
            <div className={'flex items-center justify-between'}>
                <div className={'text-main font-body-semi'}>
                    <FontAwesomeIcon icon={fontAwesomeIcons.farCodeMerge}/>
                    <span className={'ml-5'}>Ver {cl.version} - Phiên bản update tháng {format(new Date(cl.created_at), 'MM')}</span>
                </div>
                <div className={'text-[#959595]'}>
                    {format(new Date(cl.created_at), 'dd/MM/yyy HH:mm')}
                </div>
            </div>
            <div className={'whitespace-pre-wrap px-15 overflow-y-auto h-full mt-3'}>
                {
                    cl.content ? <CustomShowDescription description={cl.content} showChar={120}/> : undefined
                }
            </div>
        </div>
    )
}

const ModalCommon = ({open, handleClose, content, title, height = 350}: {open: boolean, handleClose: () => void, content: ReactElement, title: string, height?: number}) => {
    return (
        <Modal
            open={open}
            closeIcon={<></>}
            closable={false}
            footer={<></>}
            bodyStyle={{padding: 20}}
            width={600}
            forceRender={true}
        >
            <div className={'flex items-center justify-between'}>
                <span className={'text-16 font-body-semi'}>{title}</span>
                <span className={'py-2 px-8 bg-[#EBEBEB] rounded-full cursor-pointer text-[#B8B8B8]'} onClick={handleClose} style={{marginRight: -5}}>
                    <FontAwesomeIcon icon={fontAwesomeIcons.faXmark}/>
                </span>
            </div>
            <div className={`overflow-y-auto pr-8 mt-15`} style={{height: height}}>
                {content}
            </div>
        </Modal>
    )
}

const renderContent = (cl: ChangeLog) => {
    return (
        <div className={'h-300 mt-10'}>
            <div className={'flex items-center justify-between'}>
                <div className={'text-main font-body-semi'}>
                    <FontAwesomeIcon icon={fontAwesomeIcons.farCodeMerge}/>
                    <span className={'ml-5'}>Ver {cl.version} - Phiên bản update tháng {format(new Date(cl.created_at), 'MM')}</span>
                </div>
                <div className={'text-12 text-[#959595]'}>
                    {format(new Date(cl.created_at), 'dd/MM/yyy HH:mm')}
                </div>
            </div>
            <div className={'whitespace-pre-wrap px-15 overflow-y-auto h-full mt-3'}>{cl.content}</div>
        </div>
    )
}

const EmptyChangeLogs = () => {
    const {t} = useTranslation('common');
    return (
        <div className={'bg-[#F8F8F8] py-15 px-15 text-[#858A8E]'}>
            <FontAwesomeIcon icon={fontAwesomeIcons.farEyeSlash}/>
            <span className={'ml-3'}>{t('empty_version')}</span>
        </div>
    )
}

const versionState = atom({
    key: LOCAL_STORAGE_VERSION_KEY,
    default: localStorage.getItem(LOCAL_STORAGE_VERSION_KEY),
})


const InfoApp = ({hidden}: InfoAppProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const lastChangeLogs = useSelector(selectLastChangeLogs);
    const [currentVersion, setCurrentVersion] = useRecoilState(versionState);
    const [isShow, setShow] = useState(false);
    const [isShowLast, setShowLast] = useState(false);
    const changeLogs = useSelector(selectAllChangeLogs);

    const isNewVersion = useMemo(() => {
        if (!lastChangeLogs) return false;
        const currentDate = startOfToday().getTime();
        const versionCreatedAt = startOfDay(new Date(lastChangeLogs.created_at)).getTime();

        const compareDate = versionCreatedAt <= currentDate;
        const compareVersion = gt(lastChangeLogs?.version ?? '0.0.0', currentVersion ?? '0.0.0');
        return  compareVersion && compareDate;
    }, [lastChangeLogs, currentVersion]);

    const setNewVersion = useCallback(() => {
        localStorage.setItem(LOCAL_STORAGE_VERSION_KEY, lastChangeLogs.version);
        setCurrentVersion(lastChangeLogs.version);
    }, [lastChangeLogs, setCurrentVersion])

    useEffect(() => {
        setShowLast(isNewVersion);
    }, [setShowLast, isNewVersion]);

    useEffect(() => {
        dispatch(loadAllChangeLogs())
    }, [dispatch]);

    const showChangeLogs = useCallback(() => {
        setShow(true);
    }, [setShow]);

    const hiddenChangeLogs = useCallback(() => {
        setShow(false);
    }, [setShow]);

    const handleCloseLastChangeLog = useCallback(() => {
        setShowLast(false);
        setNewVersion();
    }, [setShowLast, setNewVersion]);

    return (
        <div className={'fixed bottom-10 ml-15 text-12 text-[#A9A9A9] ' + (hidden ? 'hidden' : '')}>
            <div>Version {currentVersion}</div>
            <div>© {new Date().getFullYear()} TopCV. All rights reserved.</div>
            <div className={'text-main flex items-center cursor-pointer mt-3'} onClick={showChangeLogs}>
                Lịch sử cập nhật
                <FontAwesomeIcon icon={fontAwesomeIcons.faChevronRight} className={'ml-3'} />
            </div>
            <ModalCommon
                open={isShow}
                handleClose={hiddenChangeLogs}
                content={
                    <>
                        {changeLogs.length ?
                            changeLogs.map((cl, index) => <ChangeLogItem {...cl} key={index}/>)
                            : <EmptyChangeLogs/>
                        }
                    </>
                }
                title={t('history_version')}
                height={changeLogs.length ? 350 : 80}
            />
            {
                lastChangeLogs ?
                    <ModalCommon
                        open={isShowLast}
                        handleClose={handleCloseLastChangeLog}
                        content={renderContent(lastChangeLogs)}
                        title={t('notify_last_version')}
                    /> : undefined
            }
        </div>
    )
}

export default InfoApp;